import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'showGender',
    standalone: true,
})
export class ShowGenderPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: string, ...args: unknown[]): string | null {
        if (!value) return null;

        // Normalize the input to lowercase and remove any potential extra spaces
        const normalizedValue = value.toLowerCase().trim();

        // Translate the gender using the current language translation file
        switch (normalizedValue) {
            case 'female':
                return this.translate.instant('GENERAL.female');
            case 'male':
                return this.translate.instant('GENERAL.male');
            case 'diverse':
                return this.translate.instant('GENERAL.diverse');
            default:
                return null;
        }
    }
}
