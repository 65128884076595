import {Component, Input, OnInit, SimpleChanges, ViewChild,} from "@angular/core";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {CommonModule, DatePipe} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {Router} from "@angular/router";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {CalAgePipe} from "@lohmann-birkner/luic";
import {PatientListItem} from "../../../models/patient.model";
import {HomePatientBasicInfoComponent} from "../../home-page/home-patient-basic-info/home-patient-basic-info.component";
import {HomePatientVisitComponent} from "../../home-page/home-patient-visit/home-patient-visit.component";
import {HomePatientTasksComponent} from "../../home-page/home-patient-tasks/home-patient-tasks.component";
import {FormModalComponent} from "../../../modals/form-modal/form-modal.component";
import {firstValueFrom} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {SettingsFacadeService} from '../../../services/facades/settings-facade.service';
import {Settings} from '../../../models/settings.model';

export interface MedicalOrderList {
    id: string;
    table_header_basic_info: PatientListItem;
    firstName: string;
    lastName: string;
    fullname: string;
    dob: string;
    description: string;
    receiver: string;
    status?: string;
    createdDate: string;
    ward: string;
    gender: string;
}

@Component({
    selector: "app-orga-tabs-medical-order",
    templateUrl: "./orga-tabs-medical-order.component.html",
    styleUrls: ["./orga-tabs-medical-order.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        TranslateModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        CalAgePipe,
        MatButtonModule,
        MatButtonToggleModule,
        MatSortModule,
        HomePatientBasicInfoComponent,
        HomePatientVisitComponent,
        HomePatientTasksComponent,
    ],
})
export class OrgaTabsMedicalOrderComponent implements OnInit {
    @Input() orgaPatients: PatientListItem[] = [];
    dataSource = new MatTableDataSource<MedicalOrderList>([]);
    totalRecords: number = 0;
    @Input() filterValue: string = "";
    @Input() isWorkflowRoute: boolean = false;
    currentLang: string = '';

    @ViewChild(MatSort) sort: MatSort | null = null;

    displayedColumns: string[] = [
        'table_header_basic_info',
        'description',
        'receiver',
        'createdDate',
        'status',
    ];

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private settings: SettingsFacadeService,
        private translate: TranslateService,
        private datePipe: DatePipe
    ) {
    }

    public transformDataForMedicalOrders() {
        let medicalOrders: MedicalOrderList[] = [];

        this.orgaPatients.forEach((patient) => {
            if (patient.medicalOrders) {
                patient.medicalOrders.forEach((medicalOrder) => {
                    this.settings.settings$.subscribe((settings: Settings) => {
                        this.currentLang = settings.language;
                    });
                    let formatedDob;
                    const translatedGender = this.translate.instant(
                        'GENERAL.' + patient.gender
                    );

                    if (this.currentLang === 'en') {
                        formatedDob = this.datePipe.transform(
                            patient.dob,
                            'MM/dd/yyyy'
                        );
                    } else {
                        formatedDob = this.datePipe.transform(
                            patient.dob,
                            'dd.MM.yyyy'
                        );
                    }
                    medicalOrders.push({
                        id: patient.id,
                        table_header_basic_info: patient,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                        fullname: `${patient.lastName}, ${patient.firstName}`,
                        dob: patient.dob,
                        ward: patient.ward,
                        gender: patient.gender,
                        description: medicalOrder.description,
                        receiver: medicalOrder.receiver,
                        status: medicalOrder.status,
                        createdDate: medicalOrder.createdDate,
                    });
                });
            }
        });
        this.dataSource.data = medicalOrders;
        return this.dataSource;
    }

    ngOnInit() {
        this.dataSource = this.transformDataForMedicalOrders();
        this.totalRecords = this.dataSource.data.length;
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item: any, property: string) => {
            switch (property) {
                case "createdDate": {
                    let newDate = new Date(item.createdDate);
                    return newDate;
                }
                case "dob": {
                    let newDate = new Date(item.dob);
                    return newDate;
                }
                default: {
                    return item[property];
                }
            }
        };
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["orgaPatient"] || changes["filterValue"]) {
            this.applyFilter();
        }
    }

    applyFilter(): void {
        const filterValue = this.filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    onNameClick(id: string) {
        this.router
            .navigateByUrl(`patient-details/${id}/3/medical_orders`)
            .then();
    }

    public async onClickOnElement(
        formFileName: string,
        data: any
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                form_data: {data},
            },
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }
}
