import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatListModule} from "@angular/material/list";
import {Discharge, getDummyPatientListItem, PatientListItem,} from "../../../models/patient.model";
import {CommonModule} from "@angular/common";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {CalAgePipe} from "../../../pipes/cal-age.pipe";
import {DayAgoPipe} from "../../../pipes/day-ago.pipe";
import {FormModalComponent} from "../../../modals/form-modal/form-modal.component";
import {firstValueFrom} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ShowGenderPipe} from "../../../pipes/show-gender.pipe";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {TranslateModule} from "@ngx-translate/core";

dayjs.extend(utc);

@Component({
    selector: "app-home-patient-basic-info",
    templateUrl: "./home-patient-basic-info.component.html",
    styleUrls: ["./home-patient-basic-info.component.scss"],
    standalone: true,
    imports: [
        MatListModule,
        CommonModule,
        MatIcon,
        CalAgePipe,
        DayAgoPipe,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatFormFieldModule,
        MatListModule,
        ShowGenderPipe,
        TranslateModule,
    ],
})
export class HomePatientBasicInfoComponent implements OnInit {
    @Input() patient: PatientListItem = getDummyPatientListItem();
    @Output() selectedPatient: EventEmitter<PatientListItem> =
        new EventEmitter<PatientListItem>();

    @Input() disableDiagnosis = false;

    constructor(private dialog: MatDialog, private router: Router) {
    }

    ngOnInit() {
        this.patient = this.processPatientsData(this.patient);
    }

    public async onClickOnElement(
        data: any,
        formFileName: string
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {form_file_name: formFileName, form_data: {data}},
        });

        const res = await firstValueFrom(dialogRef.afterClosed());
        /* res: the recieved data from child components */
    }

    public onPatientSelected() {
        this.router.navigateByUrl(`patient-details/${this.patient.id}`).then();
    }

    getFormattedDate(admissionDate: string): string {
        if (!admissionDate) return "";

        // Convert the ISO date string to a formatted string
        return dayjs(admissionDate).utc().format("YYYY-MM-DDTHH:mm:ss");
    }

    private processPatientsData(patient: PatientListItem): PatientListItem {
        return {
            ...patient,
            admissionDate: this.getFormattedDate(patient.admissionDate),
            discharge: this.getLatestDischargeData(patient.discharge),
        };
    }

    /**get the latest the discharge info: Discharge[0] */
    private getLatestDischargeData(
        discharges: Discharge[] | undefined
    ): Discharge[] {
        // Check if the array is empty first to return null immediately.
        if (!discharges?.length) return [];

        return discharges.sort((a, b) => {
            const timeA = a.modifiedAt ? new Date(a.modifiedAt).getTime() : 0;
            const timeB = b.modifiedAt ? new Date(b.modifiedAt).getTime() : 0;
            return timeB - timeA;
        });
    }
}
