import { MatIconModule } from '@angular/material/icon';
import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { PatientNameAndDob } from '../../pages/patient-details/patient-details.component';
import {
    FormModalComponent,
    FormModalResult,
} from '../form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';
import { Task } from '../../models/patient.model';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { ChangingHistoryComponent } from '../../components/data-interaction/changing-history/changing-history.component';
import { MatBadgeModule } from '@angular/material/badge';

interface GroupedTasks {
    latest: Task;
    history: Task[];
    historyVisible?: boolean;
}
@Component({
    selector: 'app-start-page-task-popup',
    templateUrl: './start-page-task-popup.component.html',
    styleUrls: ['./start-page-task-popup.component.scss'],
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatCardModule,
        MatExpansionModule,
        CalAgePipe,
        DayAgoPipe,
        TranslateModule,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatDialogClose,
        MatBadgeModule,
        ChangingHistoryComponent,
    ],
    standalone: true,
})
export class StartPageTaskPopupComponent implements OnInit {
    groupedTasks: { [referenceId: string]: GroupedTasks } = {};
    showHistory: boolean = false;
    allData: any;
    viewContentI18n: FormioRendererI18n;
    numberOfHistories: number | undefined;
    toggleHistory() {
        this.showHistory = !this.showHistory;
    }
    constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            data: Task[];
            patient_info: PatientNameAndDob;
            viewContentI18n: FormioRendererI18n;
        },
        public dialog: MatDialog
    ) {
        this.allData = data;
        this.viewContentI18n = data.viewContentI18n;
    }

    ngOnInit() {
        this.processTasks(this.data.data);
    }
    public onClose(): void {
        this.dialogRef.close(); // Optionally pass a result here if needed
    }

    public async onClickOnElement(
        formFileName: string,
        data: any,
        patientInfo?: PatientNameAndDob,
        allData?: any
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                form_data: { data },
                patient_info: patientInfo,
                allData,
                viewContentI18n: this.viewContentI18n,
            },
            height:'auto',
            panelClass:"task-formular-handsetLandscape"

        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    //according to the refId, group the task
    private processTasks(dataArray: any[]): void {
        dataArray.forEach((visitRecord: Task) => {
            const refId = visitRecord.referenceId;
            if (!this.groupedTasks[refId]) {
                this.groupedTasks[refId] = {
                    latest: visitRecord,
                    history: [],
                    historyVisible: false,
                };
            }
            this.groupedTasks[refId].history.push(visitRecord);
            this.groupedTasks[refId].history.sort(
                (a, b) =>
                    new Date(b.modifiedAt).getTime() -
                    new Date(a.modifiedAt).getTime()
            );
        }); console.log(dataArray)
    }

}
