import {
    DatePipe,
    JsonPipe,
    KeyValuePipe,
    NgClass,
    NgForOf,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    SlicePipe
} from "@angular/common";
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {v4 as uuidv4} from 'uuid';
import * as pdfjsLib from "pdfjs-dist";
import {firstValueFrom} from "rxjs";
import {MatChip, MatChipListbox, MatChipOption} from "@angular/material/chips";
import {FormsModule} from "@angular/forms";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {
    FormioRendererComponent,
    FormioRendererData,
    FormioRendererForm,
    FormioRendererI18n
} from "../../data-interaction/formio-renderer/formio-renderer.component";
import {MediaToolbarComponent} from "../../media-toolbar/media-toolbar.component";
import {PdfViewerComponent} from "../../utility/pdf-viewer/pdf-viewer.component";
import {fadeInFromLeft, fadeInFromRight, fadeInFromTop} from "../../../shared/animations";
import {AddPatientFileComponent} from "../../../modals/add-patient-file/add-patient-file.component";
import {MatDialog} from "@angular/material/dialog";

// Konzern Interface
export interface Corporation {
    id: string;
    name: string;
    validFrom: string;
    validUntil: string;
}

// Krankenhaus Interface
export interface Hospital {
    id: string;
    corporationId: string; // Konzern ID
    name: string;
    validFrom: string;
    validUntil: string;
}

// Klinik Interface
export interface Clinic {
    id: string;
    hospitalId: string; // Krankenhaus ID
    name: string;
    validFrom: string;
    validUntil: string;
}

// Akte Interface
export interface Record {
    id: string;
    clinicId: string; // Klinik ID
    name: string;
    version: string;
    validFrom: string;
    validUntil: string;
}

// Bereich Interface
export interface Area {
    id: string;
    recordId: string; // Akte ID
    name: string;
    version: string;
    validFrom: string;
    validUntil: string;
    order: number;
    subAreas: SubArea[]; // Unterbereiche
}

// Unterbereich Interface
export interface SubArea {
    id: string;
    areaId: string; // Bereich ID
    name: string;
    version: string;
    validFrom: string;
    validUntil: string;
    order: number;
}

// Dokument Interface
export interface Findings {
    id: string;
    createdBy: string;
    title: string;
    order: number;
    area: Area;
    subArea: SubArea;
    examinationDateTime: string;
    dateTimeOfRecord: string;
    validFrom: string;
    validUntil: string;
    formioRendererI18n?: FormioRendererI18n;
    formioRendererData?: FormioRendererData[];
    formioRendererForm?: FormioRendererForm;
    documentType: 'form' | 'pdf' | 'image' | 'dicom' | undefined;
    documentPath?: string; // URL zu dem Dokument
    pdfBlob?: Blob;
}

export interface FindingsByRecords<Findings> {
    [record: string]: {
        [area: string]: {
            [subArea: string]: Findings[];
        };
    };
}


/**
 * Represents the structure of a data object.
 */
export interface PaintingToolItem {
    /**
     * The type of the data. Optional.
     */
    type?: string;

    /**
     * URL or path to an image associated with the data. Optional.
     */
    image?: string;

    /**
     * A JSON-formatted string field. Can be null but is required.
     */
    json_field: string | null;

    /**
     * A Blob object containing binary data. Optional.
     */
    blob?: Blob;
}

/**
 * Component for displaying patient files.
 */
@Component({
    selector: "patient-files",
    templateUrl: "./patient-files.component.html",
    styleUrls: ["./patient-files.component.scss"],
    standalone: true,
    animations: [fadeInFromRight, fadeInFromLeft, fadeInFromTop],
    imports: [
        NgIf,
        MatChipListbox,
        FormsModule,
        MatChipOption,
        NgClass,
        MatChip,
        MatMenuTrigger,
        MatMenu,
        MatIcon,
        MatMenuItem,
        NgForOf,
        DatePipe,
        NgSwitch,
        NgSwitchCase,
        FormioRendererComponent,
        MediaToolbarComponent,
        PdfViewerComponent,
        JsonPipe,
        KeyValuePipe,
        SlicePipe

    ],
})
export class PatientFilesComponent implements OnChanges {
    @Input() isFullscreenForImageEnabled!: { data: any, fullScreen: boolean };
    @Input() isPaintingToolOpened!: { data: any, isPaintingToolDialogOpened: boolean };
    @Input() isMetaDataViewOpened!: boolean;
    @Input() findings: Findings[] = [];
    @Input() areas: Area[] = [];
    @Input() subAreas: SubArea[] = [];
    @Input() records: Record[] = [];
    @Input() selectedRecord!: Record;

    @Output() isFullscreenForImageEnabledChanged = new EventEmitter<{ data: any, fullScreen: boolean }>();
    @Output() isPaintingToolOpenedChanged = new EventEmitter<{ data: any, isPaintingToolDialogOpened: boolean }>();
    @Output() isMetaDataViewChanged = new EventEmitter<boolean>();

    public selectedFinding: Findings = {} as Findings;
    public findingsByRecords: FindingsByRecords<Findings> = {} as FindingsByRecords<Findings>;
    public currentArea: string = "All";
    public isDropdownOpen = false;
    protected readonly Object = Object;

    constructor(private dialog: MatDialog) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = "pdf.worker.js";
    }

    /**
     * Lifecycle hook that is called when any data-bound property of a directive changes.
     * @param changes - SimpleChanges object that contains current and previous property values.
     */
    async ngOnChanges(changes: SimpleChanges) {
        if (changes["findings"] && changes["findings"].currentValue.length > 0) {
            await this.buildFindingList();
            this.selectInitialPatientRecord();
            await this.handlePatientRecordViewer(this.selectedFinding);
        }
    }

    /**
     * Toggles the state of the dropdown menu.
     */
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    /**
     * Emits an event when the fullscreen mode for the image is changed.
     * @param ev - The new fullscreen state.
     */
    public onFullscreenForImageChanged(ev: { data: any, fullScreen: boolean }) {
        if (this.selectedFinding.documentType === 'image') {
            ev.data = {
                documentPath: this.selectedFinding.documentPath,
                documentType: this.selectedFinding.documentType
            };
        } else if (this.selectedFinding.documentType === 'pdf') {
            console.log(this.selectedFinding);
            ev.data = {pdfBlob: this.selectedFinding.pdfBlob, documentType: this.selectedFinding.documentType};
        }
        this.isFullscreenForImageEnabledChanged.emit(ev);
    }

    /**
     * Emits an event when the painting tool state is changed.
     * @param ev - The new painting tool state.
     */
    public onPaintingToolToggleChanged(ev: { data: any, isPaintingToolDialogOpened: boolean }) {
        if (this.selectedFinding.documentType === 'image') {
            ev.data = {image: this.selectedFinding.documentPath, documentType: this.selectedFinding.documentType};
        } else if (this.selectedFinding.documentType === 'pdf') {
            console.log(this.selectedFinding);
            ev.data = {pdfBlob: this.selectedFinding.pdfBlob, documentType: this.selectedFinding.documentType};
        }
        this.isPaintingToolOpenedChanged.emit(ev);
    }

    /**
     * Emits an event when the metadata view state is changed.
     * @param ev - The new metadata view state.
     */
    public onMetaDataViewToggleChanged(ev: boolean) {
        this.isMetaDataViewChanged.emit(ev);
    }

    /**
     * Changes the current area and updates the selected patient record.
     * @param areaName - The name of the new area.
     */
    public async changeArea(areaName: string) {
        this.currentArea = areaName;
        this.selectInitialPatientRecord();
        await this.handlePatientRecordViewer(this.selectedFinding);
    }

    /**
     * Selects a patient record by click event.
     * @param event - The event object containing the selected finding.
     */
    public async selectPatientRecordByClick(event: any): Promise<void> {
        this.selectedFinding = event;
        await this.handlePatientRecordViewer(this.selectedFinding);
    }

    /**
     * Opens a dialog to add a new patient record.
     */
    public async addNewPatientRecord(): Promise<void> {
        const dialogRef = this.dialog.open(AddPatientFileComponent, {
            restoreFocus: false,
            data: this.areas
        });

        const res = await firstValueFrom(dialogRef.afterClosed());
        if (res.role === 'save') {
            const newPatientRecord: Findings = {
                ...res.newPatientRecord,
                id: uuidv4()
            };

            this.findings.push(newPatientRecord);
            await this.buildFindingList();
        }
    }

    /**
     * Selects the initial patient record based on the current area.
     */
    public selectInitialPatientRecord() {
        const selectedRecordName = this.selectedRecord.name;

        if (this.currentArea !== "All") {
            const areas = this.findingsByRecords[selectedRecordName];
            if (areas) {
                const subAreas = areas[this.currentArea];
                if (subAreas) {
                    const firstSubArea = Object.keys(subAreas)[0];
                    if (firstSubArea) {
                        this.selectedFinding = subAreas[firstSubArea][0];
                    }
                }
            }
        } else {
            const allRecords = this.findingsByRecords[selectedRecordName];
            if (allRecords) {
                const firstArea = Object.keys(allRecords)[0];
                if (firstArea) {
                    const subAreas = allRecords[firstArea];
                    const firstSubArea = Object.keys(subAreas)[0];
                    if (firstSubArea) {
                        this.selectedFinding = subAreas[firstSubArea][0];
                    }
                }
            }
        }
    }

    public getFilteredAreasBySelectedRecord(): Area[] {
        return this.areas.filter(area => area.recordId === this.selectedRecord.id);
    }

    /**
     * Builds a new list or update of patient records.
     */
    private async buildFindingList() {
        const result: FindingsByRecords<Findings> = {};

        const areaMap = new Map(this.areas.map(area => [area.id, area]));
        const subAreaMap = new Map(this.subAreas.map(subArea => [subArea.id, subArea]));
        const findingsByRecord = this.groupFindingsByRecordId();

        for (const record of this.records) {
            result[record.name] = this.processRecord(record, findingsByRecord, areaMap, subAreaMap);
        }

        this.sortAreasAndSubAreas(result);

        console.log(result);
        this.findingsByRecords = result;
    }

    private groupFindingsByRecordId(): Map<string, Findings[]> {
        return this.findings.reduce((map, finding) => {
            const recordId = finding.area.recordId;
            if (!map.has(recordId)) {
                map.set(recordId, []);
            }
            map.get(recordId)!.push(finding);
            return map;
        }, new Map<string, Findings[]>());
    }

    private processRecord(
        record: Record,
        findingsByRecord: Map<string, Findings[]>,
        areaMap: Map<string, Area>,
        subAreaMap: Map<string, SubArea>
    ): { [areaName: string]: { [subAreaName: string]: Findings[] } } {
        const recordResult: { [areaName: string]: { [subAreaName: string]: Findings[] } } = {};
        const recordFindings = findingsByRecord.get(record.id) || [];
        const areasByRecord = this.areas.filter(area => area.recordId === record.id)
            .sort((a, b) => a.order - b.order);

        for (const area of areasByRecord) {
            recordResult[area.name] = this.processArea(area, recordFindings, subAreaMap);
        }

        this.handleSonstigesCases(recordResult, recordFindings, areaMap, subAreaMap);

        return recordResult;
    }

    private processArea(
        area: Area,
        recordFindings: Findings[],
        subAreaMap: Map<string, SubArea>
    ): { [subAreaName: string]: Findings[] } {
        const areaResult: { [subAreaName: string]: Findings[] } = {};
        const subAreasByArea = this.subAreas.filter(subArea => subArea.areaId === area.id)
            .sort((a, b) => a.order - b.order);

        for (const subArea of subAreasByArea) {
            const subAreaFindings = recordFindings.filter(finding =>
                finding.subArea.id === subArea.id && finding.area.id === area.id
            ).sort((a, b) => a.order - b.order);

            areaResult[subArea.name] = subAreaFindings;
        }

        return areaResult;
    }

    private handleSonstigesCases(
        recordResult: { [area: string]: { [subArea: string]: Findings[] } },
        recordFindings: Findings[],
        areaMap: Map<string, Area>,
        subAreaMap: Map<string, SubArea>
    ) {
        for (const finding of recordFindings) {
            const area = areaMap.get(finding.area.id);
            const subArea = subAreaMap.get(finding.subArea.id);

            if (!area) {
                this.addToSonstiges(recordResult, "Sonstiges", subArea?.name || "Sonstiges", finding);
            } else if (!subArea) {
                this.addToSonstiges(recordResult, area.name, "Sonstiges", finding);
            }
        }

        if (recordResult["Sonstiges"]) {
            Object.values(recordResult["Sonstiges"]).forEach(findings =>
                findings.sort((a, b) => a.order - b.order)
            );
        }
    }

    private addToSonstiges(
        recordResult: { [area: string]: { [subArea: string]: Findings[] } },
        areaName: string,
        subAreaName: string,
        finding: Findings
    ) {
        if (!recordResult[areaName]) recordResult[areaName] = {};
        if (!recordResult[areaName][subAreaName]) recordResult[areaName][subAreaName] = [];

        recordResult[areaName][subAreaName].push({
            ...finding,
            area: {...finding.area, order: 9999},
            subArea: {...finding.subArea, order: 9999}
        });
    }

    private sortAreasAndSubAreas(result: FindingsByRecords<Findings>) {
        for (const recordName in result) {
            const sortedAreas = Object.keys(result[recordName]).sort(this.compareAreas.bind(this));

            result[recordName] = sortedAreas.reduce((acc, areaName) => {
                const sortedSubAreas = Object.keys(result[recordName][areaName]).sort(this.compareSubAreas.bind(this));

                acc[areaName] = sortedSubAreas.reduce((subAcc, subAreaName) => {
                    subAcc[subAreaName] = result[recordName][areaName][subAreaName];
                    return subAcc;
                }, {} as { [key: string]: Findings[] });

                return acc;
            }, {} as { [key: string]: { [key: string]: Findings[] } });
        }
    }

    private compareAreas(a: string, b: string): number {
        if (a === "Sonstiges") return 1;
        if (b === "Sonstiges") return -1;
        const areaA = this.areas.find(area => area.name === a);
        const areaB = this.areas.find(area => area.name === b);
        return (areaA?.order ?? Infinity) - (areaB?.order ?? Infinity);
    }

    private compareSubAreas(a: string, b: string): number {
        if (a === "Sonstiges") return 1;
        if (b === "Sonstiges") return -1;
        const subAreaA = this.subAreas.find(subArea => subArea.name === a);
        const subAreaB = this.subAreas.find(subArea => subArea.name === b);
        return (subAreaA?.order ?? Infinity) - (subAreaB?.order ?? Infinity);
    }

    /**
     * Fetches a PDF as a Blob.
     * @param pdfUrl - The URL of the PDF to fetch.
     * @returns A promise that resolves to a Blob.
     */
    private async fetchPdfAsBlob(pdfUrl: string): Promise<Blob> {
        try {
            const response = await fetch(pdfUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch PDF: ${response.statusText}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            return new Blob([arrayBuffer], {type: 'application/pdf'});
        } catch (error) {
            console.error('Error fetching or converting PDF:', error);
            throw error;
        }
    }

    /**
     * Handles the patient record viewer by fetching the necessary document.
     * @param finding - The finding to handle.
     */
    private async handlePatientRecordViewer(finding: Findings) {
        if (finding?.documentPath) {
            finding.pdfBlob = await this.fetchPdfAsBlob(finding.documentPath);
        }
    }
}
