<div class="flex flex-col h-full w-full">
    <div>
        <p *ngIf="patient" class="text-gray-600 font-extralight">
            {{ patient.location }}> {{ patient.ward }} >
            {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
            {{ patient.room.split(" ")[1] }}{{ patient.bed.split(" ")[1] }}
        </p>
        <div *ngIf="patient" class="flex flex-col">
            <div class="flex flex-col">
                <p>
                    <span class="font-bold"
                    >{{ patient.lastName }}, {{ patient.firstName }},
                    </span>
                    <span>{{ "GENERAL." + patient.gender | translate }}, </span>
                    <span
                    >{{
                            patient.dob
                                | date : ("GENERAL.date_format" | translate)
                        }}
                    </span>
                    <span>({{ patient.dob | calAge }}), </span>
                    <span
                    >A:
                        {{
                            patient.admissionDate
                                | date : ("GENERAL.date_format" | translate)
                        }},
                    </span>
                    <span
                    >{{
                            "COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date"
                                | translate
                        }}:
                    </span>
                    <span
                    >{{
                            patient.discharge[0].dismissalExpectedOn
                                | date : ("GENERAL.date_format" | translate)
                        }}
                    </span>
                    <span
                    >({{
                            patient.discharge[0].dismissalExpectedOn | dayAgo
                        }})</span
                    >
                </p>
                <p class="font-extralight">
                    {{ patient.diagnosis.join(", ") }}
                </p>
            </div>
        </div>
    </div>

    <div class="flex-grow flex flex-col bg-white shadow-md overflow-hidden">
        <mat-tab-group
            (selectedTabChange)="changeTab($event)"
            [selectedIndex]="currentTab"
            animationDuration="0ms"
            class="h-full flex flex-col"
        >
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 0">done</mat-icon>
                        <span>{{
                                "COMPONENT.PATIENT_DETAILS.overview" | translate
                            }}</span>
                    </div>
                </ng-template>
                <div class="h-full overflow-auto">
                    <app-patient-overview
                        *ngIf="currentTab === 0 && patientDetails"
                        [patientDetailsI18n]="viewContentI18n"
                        [patientDetails]="patientDetails"
                        [patientNameAndDob]="patientNameAndDob"
                    ></app-patient-overview>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 1">done</mat-icon>
                        <span>{{
                                "COMPONENT.PATIENT_DETAILS.curve" | translate
                            }}</span>
                    </div>
                </ng-template>
                <div class="h-full flex justify-center items-center">
                    <img
                        *ngIf="currentTab === 1"
                        alt=""
                        class="max-w-full max-h-full"
                        src="../../../assets/img/demo_images/dummy_patient_curve.png"
                    />
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 2">done</mat-icon>
                        <span>{{
                                "COMPONENT.PATIENT_DETAILS.file" | translate
                            }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <patient-files (isFullscreenForImageEnabledChanged)="onFullscreenForImageChanged($event)"
                                   (isMetaDataViewChanged)="onMetaDataViewOpenedChanged($event)"
                                   (isPaintingToolOpenedChanged)="onPaintingToolOpenedChanged($event)"
                                   *ngIf="currentTab === 2 && findings.length > 0"
                                   [areas]="areas"
                                   [findings]="findings"
                                   [isFullscreenForImageEnabled]="isFullscreenForImageEnabled()"
                                   [isMetaDataViewOpened]="isMetaDataViewOpened()"
                                   [isPaintingToolOpened]="isPaintingToolOpened()"
                                   [records]="records"
                                   [selectedRecord]="selectedRecord"
                                   [subAreas]="subAreas"
                    ></patient-files>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 3">done</mat-icon>
                        <span>{{
                                "COMPONENT.PATIENT_DETAILS.workflow" | translate
                            }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <app-workflow-tabs
                        [filterValue]="filterValue"
                        [workflowDataPatients]="patientWorkflow!"
                    ></app-workflow-tabs>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 4">done</mat-icon>
                        <span>{{
                                "COMPONENT.PATIENT_DETAILS.communication"
                                    | translate
                            }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <app-upcomming-page></app-upcomming-page>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
