import {Injectable} from "@angular/core";
import CryptoJS from "crypto-js";
import {DataRepositoryService} from "./datarepository.service";
import {CLIENT_ID} from "../shared/constants";
import {HttpCapacitorOptions, SmartSettings,} from "../models/auth.model";
import {AccessFacadeService} from "./facades/access-facade.service";
import {CapacitorHttp} from "@capacitor/core";
import {Platform} from "@ionic/angular";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

/**
 * Here lives all related to Authorization and Authentication, specially around API communication, OAuth and so on
 * (not so much about storage, that belongs to state management - via facades- )
 */
@Injectable({
    providedIn: "root",
})
export class AuthService {
    public popUpWindow: Window | null = null;

    constructor(private router: Router, private dataRepositoryService: DataRepositoryService, private accessService: AccessFacadeService, private platform: Platform, private snackBar: MatSnackBar) {
    }

    /**
     * Initializes the OAuth configuration and initiates authentication.
     */
    public async initAuth(): Promise<void> {
        try {
            // Generate code verifier
            const codeVerifier = this.createRandomString(128);
            if (!codeVerifier) {
                this.handleError("Failed to generate code verifier");
                return;
            }
            await this.dataRepositoryService.saveCodeVerifier(codeVerifier);

            // Generate code challenge
            const codeChallenge = await this.generateCodeChallenge(codeVerifier);
            if (!codeChallenge) {
                this.handleError("Failed to generate code challenge");
                return;
            }

            // Fetch OAuth settings
            const smartSettings = await this.fetchSmartSettings();
            if (!smartSettings || !smartSettings.authorization_endpoint) {
                this.handleError("Failed to fetch smart settings or missing authorization endpoint");
                return;
            }

            // Construct authorization URL
            const authParams = {
                response_type: "code",
                client_id: CLIENT_ID,
                scope: "openid profile email",
                redirect_uri: `${environment.cpad2Server}login/redirect`,
                code_challenge: codeChallenge,
                code_challenge_method: "S256",
            };
            const queryString = new URLSearchParams(authParams).toString();
            const authorizationUrl = `${smartSettings.authorization_endpoint}${queryString}`;

            // Open authorization URL in popup window or redirect
            if (this.platform.is("android") || this.platform.is("ios") || this.platform.is("electron")) {
                this.openPopupWindow(authorizationUrl);
            } else {
                window.location.href = authorizationUrl;
            }
        } catch (error) {
            this.handleError("Authentication initialization failed", error);
        }
    }

    /**
     * Handles login callback and retrieves access token.
     * @param customURL - Custom URL for fetching query parameters.
     */
    public async initializeLoginCallback(customURL?: string): Promise<void> {
        const queryParams = this.getQueryParameters(customURL);
        const code = queryParams.code;
// Fetch OAuth settings
        const smartSettings = await this.fetchSmartSettings();
        if (!smartSettings) {
            this.handleError("Smart settings are undefined");
            return;
        }
        try {
            if (customURL) {
                // Create a URL object
                const parsedUrl = new URL(customURL);

                // Get the search parameters
                const searchParams = parsedUrl.searchParams;

                // Extract individual parameters
                const sessionState = searchParams.get("session_state");
                const iss = searchParams.get("iss");
                const code = searchParams.get("code");

                // Fetch query parameters
                let queryParams;

                queryParams = {
                    code: code
                };

                // Check for authorization code
                if (!queryParams.code) {
                    this.openSnackBar("Authorization code is missing");
                    this.navigateTo("/");
                    return;
                }

                // Fetch code verifier
                const codeVerifier = await this.dataRepositoryService.getCodeVerifier();
                if (!codeVerifier) {
                    this.openSnackBar("Code verifier is missing");
                    this.navigateTo("/");
                    return;
                }

                // Exchange authorization code for access token
                const tokenResponse = await this.getToken(smartSettings.token_endpoint, queryParams.code, codeVerifier);
                if (!tokenResponse || !tokenResponse.access_token) {
                    this.openSnackBar("Failed to obtain access token");
                    this.navigateTo("/");
                    return;
                }

                // Save access token
                this.accessService.setUserToken(tokenResponse);
                console.info("Token was saved successfully1");
            } else {
                const codeVerifier = await this.dataRepositoryService.getCodeVerifier();
                if (!codeVerifier) {
                    this.handleError("Code verifier is missing");
                    return;
                }

                const tokenResponse = await this.getToken(smartSettings.token_endpoint, code, codeVerifier!);
                // Save access token
                this.accessService.setUserToken(tokenResponse);
                console.info("Token was saved successfully2");
            }
        } catch (error) {
            this.handleError("Login callback failed", error);
        } finally {
            if (this.platform.is("android") || this.platform.is("ios") || this.platform.is("electron")) {
                await this.router.navigateByUrl("/start");
            } else {
                window.location.href = `${environment.cpad2Server}start`;
            }
            await this.dataRepositoryService.removeCodeVerifier();
        }
    }

    /**
     * Refreshes the authentication token using a refresh token.
     * @param refreshToken The refresh token used to obtain a new access token.
     * @returns A promise containing the new access token.
     */
    public async refreshAuth(refreshToken: string): Promise<string> {
        try {
            // Ensure the refresh token is valid before attempting to refresh
            if (!refreshToken) throw new Error("Refresh token is missing or invalid");

            const response = await CapacitorHttp.post({
                url: `${environment.apiServer}/refresh_token`,
                headers: {
                    "Content-Type": "application/json",
                    // Optionally, include other security headers like Authorization if needed
                },
                data: {refresh_token: refreshToken},
            });

            if (!response.data || !response.data["access_token"]) {
                throw new Error("Invalid response from refresh token endpoint");
            }

            return response.data["access_token"];
        } catch (error) {
            console.error("Failed to refresh authentication token:", error);
            // Depending on your error handling strategy, re-throw, handle the error, or return a default/fallback value
            throw error; // For example, to allow the caller to handle the error appropriately
        }
    }

    /**
     * Handles errors by displaying a snackbar and navigating to the root path.
     * @param message - Error message to display.
     * @param error - Optional error object for logging.
     */
    private handleError(message: string, error?: any): void {
        console.error(message, error);
        this.openSnackBar(message);
        this.navigateTo("/");
    }

    /**
     * Parses the current URL and extracts query parameters into a key-value object.
     * Handles potential errors in URL parsing and logging for debugging.
     * @returns An object containing the parsed query parameters.
     */
    private getQueryParameters(customURL?: string): any {
        try {
            let currentUrl: URL;
            let queryParams: URLSearchParams;
            if (!customURL) {
                currentUrl = new URL(window.location.href);
                queryParams = new URLSearchParams(currentUrl.search);
            } else {
                currentUrl = new URL(environment.cpad2Server + customURL);
                queryParams = new URLSearchParams(currentUrl.search);
            }

            const paramsObject: any = {};
            queryParams.forEach((value, key) => {
                paramsObject[key] = value;
            });

            return paramsObject;
        } catch (e) {
            console.error("Error parsing query parameters:", e);
            return {};
        }
    }

    /**
     * Retrieves an authorization token using the provided authorization code and code verifier.
     * Includes error handling to manage and log issues during the token retrieval process.
     * @param tokenEndpoint The endpoint to request the token from.
     * @param authorizationCode The authorization code received from the authorization server.
     * @param codeVerifier The code verifier used for PKCE.
     * @returns A promise containing the token response data or an error object.
     */
    private async getToken(
        tokenEndpoint: string,
        authorizationCode: string,
        codeVerifier: string,
    ): Promise<any> {
        try {
            const redirectUri = `${environment.cpad2Server}login/redirect`;

            const httpOptions: HttpCapacitorOptions = {
                url: tokenEndpoint,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                },
                data: {
                    client_id: CLIENT_ID,
                    grant_type: "authorization_code",
                    code: authorizationCode,
                    redirect_uri: redirectUri,
                    code_verifier: codeVerifier,
                },
            };

            return this.sendTokenRequest(httpOptions);
        } catch (e) {
            console.error("Error retrieving authorization token:", e);
            throw e; // Rethrow to allow calling function to handle
        }
    }

    /**
     * Sends a token request to the specified endpoint using provided HTTP options.
     * This method abstracts the HTTP POST request and includes error handling.
     * @param httpOptions The HTTP options for the request including URL, headers, and data.
     * @returns A promise containing the token response data or an error object.
     */
    private async sendTokenRequest(
        httpOptions: HttpCapacitorOptions
    ): Promise<any> {
        try {
            const response = await CapacitorHttp.post(httpOptions);
            if (!response.data) throw new Error("Response data is empty");
            return response.data;
        } catch (e) {
            console.error("Error sending token request:", e);
            throw e; // Rethrow to allow more specific error handling by the caller
        }
    }

    /**
     * Displays a snackbar with the given message.
     * @param message - The message to display.
     */
    private openSnackBar(message: string): void {
        this.snackBar.open(message, "Close", {
            duration: 5000, // Adjust duration as needed
            panelClass: ["snackbar-danger"]
        });
    }

    /**
     * Opens a popup window with the given URL.
     * @param url - The URL to open.
     */
    private openPopupWindow(url: string): void {
        this.popUpWindow = window.open(url, "_blank", "width=800,height=600");
        if (!this.popUpWindow || this.popUpWindow.closed) {
            throw new Error("Popup window blocked by browser");
        }
    }

    /**
     * Navigates to the specified URL.
     * @param url - The URL to navigate to.
     */
    private async navigateTo(url: string): Promise<void> {
        await this.router.navigate([url]);
    }

    /**
     * Loads and retrieves the FHIR configuration from a specified URL.
     * Attempts to handle errors gracefully and logs them for debugging.
     * @returns A promise containing the loaded configuration or undefined if an error occurs.
     */
    private async fetchSmartSettings(): Promise<SmartSettings | undefined> {
        try {
            const response = await CapacitorHttp.get({url: environment.smartSettingsServer});
            // const response = await CapacitorHttp.get({url: "assets/smartSettings.json"});
            if (!response.data) throw new Error("Response data is empty");
            return response.data;
        } catch (e) {
            console.error("Error loading SMART configuration:", e);
            return undefined;
        }
    }

    /**
     * Generates a cryptographically secure random string of the specified length.
     * Uses the Web Crypto API to ensure randomness.
     * @param length The desired length of the random string.
     * @returns A string consisting of randomly selected characters.
     */
    private createRandomString(length: number): string {
        const validChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._";
        let randomString = "";
        const array = new Uint8Array(length);
        window.crypto.getRandomValues(array);

        for (let i = 0; i < length; i++) {
            randomString += validChars.charAt(array[i] % validChars.length);
        }

        return randomString;
    }

    /**
     * Generates a code challenge from the provided code verifier.
     * @param codeVerifier The code verifier.
     * @returns The generated code challenge.
     */
    private async generateCodeChallenge(codeVerifier: string): Promise<string> {
        try {
            // Check if the codeVerifier is valid
            if (!codeVerifier) {
                throw new Error("Code verifier cannot be empty.");
            }

            // Creating a SHA-256 hash of the codeVerifier with CryptoJS
            const hash = CryptoJS.SHA256(codeVerifier);

            // Converting the hash into a base64url encoded string without padding
            // Remove padding at the end
            // Return the transformed string
            return hash.toString(CryptoJS.enc.Base64)
                .replace(/\+/g, "-") // Replace '+' with '-'
                .replace(/\//g, "_") // Replace '/' with '_'
                .replace(/=+$/, "");
        } catch (error) {
            console.error("Error generating code challenge:", error);
            // Depending on your architecture, insert error handling or return value here
            throw error; // or return undefined; for softer error handling
        }
    }
}
