Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<div class="ui pointing red basic label ' +
((__t = (ctx.level)) == null ? '' : __t) +
'">\n  ' +
((__t = (ctx.message)) == null ? '' : __t) +
'\n</div>\n';
return __p
}