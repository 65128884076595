import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import {
    MatDatepickerInputEvent,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import {
    MAT_DATE_LOCALE,
    provideNativeDateAdapter,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { inOutExpandY } from '../../../shared/animations';
import { LuicModule } from '@lohmann-birkner/luic';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

dayjs.extend(utc);

export interface FormioRendererForm {
    components: any[];

    [key: string]: any;
}

export interface FormioRendererData {
    key: string;
    value: string | number | boolean | undefined;
}

/**
 * An object for the translations of the form.
 * Example:
 * ```json
 * {
 *      "de": {
 *          "Birth date": "Geburtsdatum"
 *      },
 *      "en": {
 *          "Birth date": "Birth date"
 *      }
 * }
 * ```
 */
export interface FormioRendererI18n {
    [key: string]: { [key: string]: string };
}

@Component({
    selector: 'app-formio-renderer',
    standalone: true,
    templateUrl: './formio-renderer.component.html',
    styleUrls: ['./formio-renderer.component.scss'],
    providers: [
        provideNativeDateAdapter(),
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        LuicModule,
        ReactiveFormsModule,
        TextFieldModule,
    ],
    animations: [inOutExpandY],
})
export class FormioRendererComponent implements OnChanges {
    @Input() form: FormioRendererForm | undefined;
    /** Data to populate the form's fields */
    @Input() data: FormioRendererData[] = [];
    @Input() i18n: FormioRendererI18n | undefined;
    @Input() readonly = false;

    @Output() formButtonClick = new EventEmitter<string>();
    @Output() formSubmitted = new EventEmitter<FormioRendererData[]>();
    @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;
    displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
    ]);
    currentHandyOrientation: string | false = '';
    public components: any[] = [];
    public labels: { key: string; value: string }[] = [];

    public constructor(
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver
    ) {}
    ngOnInit() {
        this.breakpointObserver
            .observe([
                Breakpoints.HandsetPortrait,
                Breakpoints.HandsetLandscape,
                Breakpoints.Web,
                Breakpoints.Tablet,
            ])
            .subscribe((result) => {
                console.log(result);
                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        this.currentHandyOrientation =
                            this.displayNameMap.get(query) ?? '';
                        console.log(this.currentHandyOrientation);
                    }
                }
            });
    }
    public ngOnChanges(): void {
        this.refresh();
    }

    //#region Listeners
    /**
     * Applies the value extracted from the event (event.target.value) to the corresponding element in this.data
     */
    public onComponentDataChange($event: Event, component: any) {
        const key = component.key;
        const value = $event.target ? ($event.target as any)['value'] : null;
        this.applyValue(key, value);

        this.refresh();
    }

    public onCheckboxChange($event: MatCheckboxChange, component: any) {
        const key = component.key;
        const value = $event.checked;
        this.applyValue(key, value);

        this.refresh();
    }

    public onRadioChange($event: MatRadioChange, component: any) {
        const key = component.key;
        const value = $event.value;
        this.applyValue(key, value);

        this.refresh();
    }

    public onSelectChange($event: MatSelectChange, component: any) {
        const key = component.key;
        const value = $event.value;
        this.applyValue(key, value);

        this.refresh();
    }

    public onDateChange($event: MatDatepickerInputEvent<Date>, component: any) {
        const key = component.key;
        const value = ($event as any).target.value;
        this.applyValue(key, value);

        this.refresh();
    }

    public onDateAndTimeChange(inputValue: string | undefined, component: any) {
        const key = component.key;
        const value = inputValue;
        this.applyValue(key, value);
        this.refresh();
    }

    public onClickOnButton(key: string) {
        this.formButtonClick.emit(key);
    }

    public onSubmitButton(e?: Event) {
        this.formSubmitted.emit(this.data);
    }

    //#endregion

    public isComponentDisabled(component: any): boolean {
        // Check if the component's label is 'admissionDate'
        return component.label === 'Admission Date';
    }

    public getComponentLabel(key: string) {
        return this.labels.find((e) => e.key === key)?.value;
    }

    private refresh() {
        this.components = this.form?.components ?? [];
        for (const c of this.components) {
            // Set up the component x-show property (to be displayed or not)
            const d = this.data.find((e) => e.key === c.key);
            if (!d) {
                this.data.push({ key: c.key, value: undefined });
                c['x-data'] = undefined;
            } else {
                c['x-data'] = d.value;
            }
            c['x-show'] = this.showComponent(c);
            // Setup i18n on components label
            const currentLanguage = this.translate.currentLang;
            if (this.i18n && this.i18n[currentLanguage]) {
                const value = this.i18n[currentLanguage][c.label] ?? c.label;
                this.labels.push({
                    key: c.key,
                    value: this.i18n[currentLanguage][c.label],
                });
            }
            // Translate the values of selectboxes and select component
            if (c.type === 'selectboxes' && c.values) {
                for (const v of c.values) {
                    let value = // Safely find the right text
                        this.i18n && // If this.i18n exists
                        this.i18n[currentLanguage] && // And the current language
                        this.i18n[currentLanguage][v.label] // And the label for the language
                            ? this.i18n[currentLanguage][v.label] // Then assign it
                            : v.label; // Otherwise use the original label (without i18n)

                    this.labels.push({ key: v.value, value });
                }
            }
            if (c.type === 'select' && c.data?.values) {
                for (const v of c.data.values) {
                    let value =
                        this.i18n &&
                        this.i18n[currentLanguage] &&
                        this.i18n[currentLanguage][v.label]
                            ? this.i18n[currentLanguage][v.label]
                            : v.label;

                    this.labels.push({ key: v.value, value });
                }
            }
        }
    }

    private showComponent(component: any): boolean {
        if (component.conditional) {
            for (const condition of component.conditional.conditions) {
                const conditionData = this.data.find(
                    (e) => e.key === condition.component
                )?.value;
                return conditionData === condition.value;
            }

            return false;
        }

        return true;
    }

    private applyValue(key: string, value: any) {
        const d = this.data.find((e) => e.key === key);
        if (!d) {
            this.data.push({ key, value });
        } else {
            d.value = value;
        }
    }
}
