<mat-dialog-content class="p-0 h-full w-full">
    <div class="flex items-center gap-4 p-4">
        <button (click)="closeViewer()" class="p-3" mat-icon-button
                matTooltip='{{"COMPONENT.PATIENT_FILES.btn_back" | translate}}'>
            <mat-icon>chevron_left</mat-icon>
        </button>
        <div class="flex flex-col">
            <p class="text-lg font-medium">{{ data.patient.firstName }} {{ data.patient.lastName }}</p>
            <p class="text-sm text-gray-500">
                ({{ data.patient.dob }}
                , {{ data.patient.dob | calAge }} {{ "COMPONENT.PATIENT_DETAILS.years" | translate }})
            </p>
        </div>
    </div>
    <luic-painting-tool (canvasStateSave)="painting($event)" *ngIf="Object.keys(data.img).length > 0"
                        [imageFormat]="data.img"></luic-painting-tool>
</mat-dialog-content>


<!--<div class="lbFlex lbCol">
    <div class="lbFlex lbRow lbGapMD lbFlexAlignCenter paintingToolHeader">
        <button (click)="closeViewer()" class="back_btn" mat-icon-button
                matTooltip='{{"COMPONENT.PATIENT_FILES.btn_back" | translate}}'>
            <mat-icon>chevron_left</mat-icon>
        </button>
        <div *ngIf="data.patient" class="patient-details-container">
            &lt;!&ndash; Avatar &ndash;&gt;
            <div class="patient-details-avatar">
                {{ getInitials(data.patient.firstName, data.patient.lastName) }}
            </div>
            &lt;!&ndash; Patient information &ndash;&gt;
            <div class="patient-details-info">
                <div>
                    {{ data.patient.firstName }} {{ data.patient.lastName }} ({{ data.patient.dob }},
                    {{ data.patient.dob | calAge }}
                    {{ "COMPONENT.PATIENT_DETAILS.years" | translate }})
                </div>

                <p>{{ data.patient.diagnosis }}</p>
            </div>
        </div>
    </div>
    <div class="paintingToolWrapper">
        <luic-painting-tool (canvasStateSave)="painting($event)" *ngIf="Object.keys(data.img).length > 0"
                            [imageFormat]="data.img"></luic-painting-tool>
    </div>
</div>-->
