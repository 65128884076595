<mat-dialog-content>
    <form *ngIf="patientRecordForm" [formGroup]="patientRecordForm" class="flex flex-col gap-4">
        <div class="flex gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Befunddatum und Uhrzeit</mat-label>
                <input formControlName="dateTimeOfRecord" matInput type="datetime-local">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Untersuchungsdatum und Uhrzeit</mat-label>
                <input formControlName="examinationDateTime" matInput type="datetime-local">
            </mat-form-field>
        </div>
        <div class="flex gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Autor</mat-label>
                <input formControlName="createdBy" matInput>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Titel</mat-label>
                <input formControlName="title" matInput>
            </mat-form-field>
        </div>
        <div class="flex gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Typ</mat-label>
                <mat-select (selectionChange)="onTypeChange($event)"
                            formControlName="area">
                    <mat-option *ngFor="let area of areas" [value]="area">
                        {{ area.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Kategorie</mat-label>
                <mat-select [disabled]="!selectedArea" formControlName="subArea">
                    <mat-option *ngFor="let subarea of selectedArea?.subAreas" [value]="subarea">
                        {{ subarea.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Vorlage</mat-label>
                <mat-select formControlName="patientRecordTemplate">
                    <mat-option *ngFor="let template of templateFiles" [value]="template">
                        {{ template.templateLabel }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button (click)="closeModal()" mat-button>Cancel</button>
    <button (click)="handleNewPatientRecord()" mat-button>Save</button>
</mat-dialog-actions>


<!--
<mat-card>
    <mat-card-title class="modal-title">{{ "COMPONENT.PATIENT_FILES.title_add_pat_file" | translate }}
    </mat-card-title>
    <mat-card-content class="modal-container">
        &lt;!&ndash; Date Time Picker Field &ndash;&gt;
        <mat-form-field>
            <input
                (ngModelChange)="onChangeDate($event)"
                [(ngModel)]="patientRecordDateOfRecord"
                [matDatepicker]="picker"
                [placeholder]=" 'z.B. ' + (patientRecordDateOfRecord | date : ('GENERAL.date_format' | translate))"
                matInput
            />
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        &lt;!&ndash; Regular Input Field &ndash;&gt;
        <mat-form-field>
            <mat-label>{{ "COMPONENT.PATIENT_FILES.MODAL.title" | translate }}</mat-label>
            <input
                (ngModelChange)="onChangeInput($event)"
                [(ngModel)]="inputValue"
                matInput
                placeholder="z.B. Ambulanter Brief"
            />
        </mat-form-field>

        &lt;!&ndash; Dropdown Field &ndash;&gt;
        <mat-form-field>
            <mat-label>{{ "COMPONENT.PATIENT_FILES.MODAL.documentation_type" | translate }}</mat-label>
            <mat-select (selectionChange)="onChangeDropdown($event)" [(value)]="patientRecordDocumentType">
                <mat-option value="blank">{{ "COMPONENT.PATIENT_FILES.MODAL.white_page" | translate }}</mat-option>
                <mat-option value="masterdata">{{ "COMPONENT.PATIENT_FILES.MODAL.master_data_form" | translate }}
                </mat-option>
                <mat-option value="anamnese">{{ "COMPONENT.PATIENT_FILES.MODAL.medical_history" | translate }}
                </mat-option>
                <mat-option value="corona">{{ "COMPONENT.PATIENT_FILES.MODAL.corona" | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>

    <mat-dialog-actions class="dialog-patient-actions">
        <button (click)="closeModal()" mat-flat-button>{{ "GENERAL.button_text_save" | translate }}</button>
    </mat-dialog-actions>
</mat-card>
-->
