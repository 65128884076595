import {Component, Inject, OnInit} from "@angular/core";
import {MatCardModule} from "@angular/material/card";
import {LuicModule} from "@lohmann-birkner/luic";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle,} from "@angular/material/dialog";
import {DialogData} from "../../components/patient-info/patient-overview/patient-overview.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: "app-dialog-patient-overview",
    templateUrl: "./dialog-patient-overview.component.html",
    styleUrls: ["./dialog-patient-overview.component.scss"],
    standalone: true,
    imports: [
        MatCardModule,
        LuicModule,
        MatFormFieldModule,
        FormsModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatDialogModule,
        MatInputModule,
        MatIconModule,
        TranslateModule
    ],
})
export class DialogPatientOverviewComponent implements OnInit {
    dateAndTime: string = "";
    catalog: string = "";
    content: string = "";

    constructor(
        public dialogRef: MatDialogRef<DialogPatientOverviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
    }

    onConfirmCilck(): void {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
