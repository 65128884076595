import {AfterViewInit, Component, ElementRef, Input, SimpleChanges, ViewChild,} from "@angular/core";
import {getDocument} from "pdfjs-dist";
import {DocumentInitParameters, TypedArray,} from "pdfjs-dist/types/src/display/api";

@Component({
    selector: 'app-pdf-viewer',
    template: '<div #container></div>',
    standalone: true,
    styleUrl: './pdf-viewer.component.scss',
})
export class PdfViewerComponent implements AfterViewInit {
    @ViewChild('container') container!: ElementRef;

    @Input({required: true}) src!:
        | string
        | URL
        | Blob
        | TypedArray
        | ArrayBuffer
        | DocumentInitParameters;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['src'] && !changes['src'].isFirstChange()) {
            this.renderPdf();
        }
    }

    ngAfterViewInit() {
        this.renderPdf();
    }

    private renderPdf() {
        this.container.nativeElement.innerHTML = '';
        if (this.src instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
                const typedArray = new Uint8Array(reader.result as ArrayBuffer);
                this.loadDocument(typedArray);
            };
            reader.readAsArrayBuffer(this.src);
        } else {
            this.loadDocument(this.src);
        }
    }

    private loadDocument(src: TypedArray | ArrayBuffer | DocumentInitParameters) {
        // Laden Sie das PDF-Dokument
        const loadingTask = getDocument(src);

        loadingTask.promise.then((pdf) => {
            const numPages = pdf.numPages;

            // Durchlaufen Sie jede Seite
            for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                // Laden Sie die Seite
                pdf.getPage(pageNum).then((page) => {
                    // Holen Sie sich die Breite des Containers mit der Klasse "fileRightCol"
                    const containerWidth = document.querySelector(".pdfViewerContainer")!.clientWidth;

                    // Holen Sie sich die Viewport-Abmessungen der Seite
                    const scale = containerWidth / page.getViewport({scale: 1}).width;
                    const viewport = page.getViewport({scale});

                    // Erstellen Sie einen Canvas zum Rendern der Seite
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Fügen Sie den Canvas zum HTML-Dokument hinzu
                    this.container.nativeElement.appendChild(canvas);

                    if (context) {
                        // Rendern Sie die Seite
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };
                        page.render(renderContext);
                    }
                });
            }
        });
    }
}
