export class MigrationUpgradeStatements {
    public upgradeMigrations = [
        {
            toVersion: 1,
            statements: [
                `CREATE TABLE IF NOT EXISTS view_content(
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            locator TEXT NOT NULL,
            content TEXT NOT NULL,
            scopes TEXT
            );`,
            ],
        },
    ];
}
