<!-- <mat-icon
    [color]="(networkStatus$ | async) ? 'primary' : 'warn'"
    [matTooltip]="(networkTooltipText$ | async) ?? 'Lädt...'"
    aria-hidden="false"
    aria-label="Netzwerkstatus"
    class="icon-size"
    fontIcon="wifi"
    >network_wifi
</mat-icon>
<mat-icon
    [color]="(serverConnectionStatus$ | async) ? 'primary' : 'warn'"
    [matTooltip]="(serverConnectionTooltipText$ | async) ?? 'Lädt...'"
    aria-hidden="false"
    aria-label="Serververbindung"
    class="icon-size"
    fontIcon="cloud_queue"
    >cloud_queue
</mat-icon>
<mat-icon
    [color]="(serverPingStatus$ | async) ? 'primary' : 'warn'"
    [matTooltip]="(serverPingTooltipText$ | async) ?? 'Lädt...'"
    aria-hidden="false"
    aria-label="Server Ping"
    class="icon-size"
    fontIcon="check_circle"
    >check_circle
</mat-icon> -->

<!-- <div *ngIf="viewContent !== undefined" class="dashboard-info-container">
   <luic-server-state *ngIf="viewContent?.['serverStatus']" [viewContent]="viewContent['serverStatus']"></luic-server-state>


    <luic-device-information
    cdkDrag
        class="dashboard-device-item"
        [viewContent]="viewContent['deviceInformations']">
    </luic-device-information>
    <luic-meta-information
        class="dashboard-device-item"
        [viewContent]="viewContent['releaseInformations']">
    </luic-meta-information>
    <luic-release-state
        class="dashboard-device-item"
        [viewContent]="viewContent['buildInformations']">
    </luic-release-state>
    <luic-token-information
        class="dashboard-device-item"
        [viewContent]="viewContent['tokenInformations']">
    </luic-token-information>
</div> -->
<div class="lbDashboard">
    <!-- Iterate over the columns -->
    <div
        (cdkDropListDropped)="onItemDrop($event, columnIndex)"
        *ngFor="let col of connectedColumns; let columnIndex = index"
        [cdkDropListConnectedTo]="connectedColumns"
        [cdkDropListData]="mappedDashboard[col]"
        [id]="col"
        cdkDropList
        class="lbDashboardColumn"
    >
        <!-- Iterate over the items in each column -->
        <div
            *ngFor="let item of mappedDashboard[col]"
            cdkDrag
            class="lbDashboardItem"
        >
            <ng-container *ngIf="viewContent !== undefined">
                <luic-server-state
                    *ngIf="item.component === 'LuicServerStateComponent'"
                    [viewContent]="viewContent['serverStatus']"
                ></luic-server-state>
                <luic-device-information
                    *ngIf="item.component === 'LuicDeviceInformationComponent'"
                    [viewContent]="viewContent['deviceInformations']"
                ></luic-device-information>
                <luic-meta-information
                    *ngIf="item.component === 'LuicMetaInformationComponent'"
                    [viewContent]="viewContent['releaseInformations']"
                ></luic-meta-information>
                <luic-release-state
                    *ngIf="item.component === 'LuicReleaseStateComponent'"
                    [viewContent]="viewContent['buildInformations']"
                ></luic-release-state>
                <luic-token-information
                    *ngIf="item.component === 'LuicTokenInformationComponent'"
                    [viewContent]="viewContent['tokenInformations']"
                ></luic-token-information>
                <div
                    *cdkDragPlaceholder
                    class="example-custom-placeholder"
                ></div>
            </ng-container>
        </div>
    </div>
</div>


