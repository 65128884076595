<mat-card class="dialog-container">
    <div class="form-name-patient-info">
        <div>
            <div class="h5">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails." +
                        formTitle +
                        ".title" | translate
                }}
            </div>
            <!-- following: to show the patient name and dob in the patient overview page -->
            <div class="patient-info p">
                <div *ngIf="patientInfo">
                    <strong>
                        {{ patientInfo.lastName }},
                        {{ patientInfo.firstName }}</strong
                    >, {{ "GENERAL." + patientInfo.gender | translate }},
                    {{
                        patientInfo.dob
                            | date : ("GENERAL.date_format" | translate)
                    }}
                    ({{ patientInfo.dob | calAge }}),&nbsp;{{
                        data.patient_info.ward
                    }},
                    {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
                    {{ data.patient_info.room.split(" ")[1]
                    }}{{ data.patient_info.bed.split(" ")[1] }}
                </div>

                <!-- to show the patient name and dob in orga und workflow -->
                <div *ngIf="!patientInfo">
                    {{ formData.data[0].lastName }},
                    {{ formData.data[0].firstName }},
                    {{ "GENERAL." + formData.data[0].gender | translate }},
                    {{
                        formData.data[0].dob
                            | date : ("GENERAL.date_format" | translate)
                    }}({{ formData.data[0].dob | calAge }}),&nbsp;{{
                        formData.data[0].ward
                    }},&nbsp;{{
                        "COMPONENT.PAGE_PATIENT_LIST.room" | translate
                    }}&nbsp;{{ formData.data[0].room.split(" ")[1]
                    }}{{ formData.data[0].bed.split(" ")[1] }}
                </div>
            </div>
        </div>
        <div class="track-close-buttons">
            <!-- here the ngif to control the track_change button, this button
                will not show when open a new formular
                -->
            <button
                mat-mini-fab
                (click)="toggleHistory()"
                *ngIf="viewContentI18n"
            >
                <mat-icon>track_changes</mat-icon>
            </button>
            <button mat-mini-fab (click)="onClose()">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <mat-card-content>
        <app-formio-renderer
            [form]="form"
            [i18n]="i18n"
            [data]="formioRendererData"
            (formButtonClick)="onFormButtonClick($event)"
            (formSubmitted)="onClickOnSubmit($event)"
        ></app-formio-renderer>

        <div *ngIf="showHistory" class="change-history-container">
            <div
                [matBadge]="numberOfHistories"
                matBadgeOverlap="false"
                class="w-fit h6"
            >
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.changing_history"
                        | translate
                }}
            </div>

            @for( history of groupedElementsHistory; track
            groupedElementsHistory;let i=$index;let last=$last){

            <app-changing-history
                [form_file_name]="form_file_name"
                [index]="i"
                [i18n]="viewContentI18n"
                [data]="groupedElementsHistory?.slice(i, i + 2)"
                [last]="last"
            ></app-changing-history
            >}
        </div>
    </mat-card-content>
</mat-card>
