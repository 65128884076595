<div class="workflow-sort-search">

    <!-- Sort select -->
    <app-sort-select [options]="sortOptions" (directionChange)="onSortChange($event)" class="patient-list-sort" />

    <mat-form-field class="workflow-search">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>{{
            "COMPONENT.PAGE_PATIENT_LIST.placeholder_search_in_list" | translate
        }}</mat-label>
        <input
            matInput
            [(ngModel)]="searchQuery"
            (ngModelChange)="updateFilteredPatients()"
            type="search"
        />
    </mat-form-field>
</div>
<mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
    class="workflow-container"
    [(selectedIndex)]="selectedTabIndex"
>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon *ngIf="selectedTabIndex === 0">done</mat-icon>
            {{ "COMPONENT.PAGE_WORKFLOW.all_patients" | translate }}
        </ng-template>
        <app-workflow-tabs
            [workflowDataPatients]="filteredOrgaPatients"
            [viewContentI18n]="viewContentI18n"
        >
        </app-workflow-tabs>
    </mat-tab>
    <mat-tab
        *ngFor="let station of filteredWorkflowData; let i = index"
        [label]="station.stationName"
        >
        <ng-template mat-tab-label
            ><mat-icon *ngIf="selectedTabIndex === i + 1">done</mat-icon>
            {{ station.stationName }}
        </ng-template>
        <app-workflow-tabs
            [workflowDataPatients]="station.patients"
            [viewContentI18n]="viewContentI18n"
        ></app-workflow-tabs>
    </mat-tab>

</mat-tab-group>
