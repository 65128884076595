import {createReducer, on} from "@ngrx/store";
import {UserToken} from "../../models/auth.model";
import * as AccessActions from "../actions/access.action";

export interface AccessState {
    token: UserToken | null;
    loading: boolean;
    error: any;
}

export const initialState: AccessState = {
    token: null,
    loading: false,
    error: null,
};

export const accessReducer = createReducer(
    initialState,
    on(AccessActions.loadUserToken, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(AccessActions.loadUserTokenSuccess, (state, {token}) => ({
        ...state,
        token,
        loading: false,
    })),
    on(AccessActions.loadUserTokenFail, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    })),
    on(AccessActions.setUserToken, (state, {token}) => {
        return {
            ...state,
            token,
            loading: false,
            error: null,
        };
    }),
    on(AccessActions.removeUserToken, (state) => ({
        ...state,
        token: null,
        loading: true,
    })),
    on(AccessActions.setAccessToken, (state, {accessToken}) => {
        const token = state.token
            ? {...state.token, access_token: accessToken}
            : null;
        return {
            ...state,
            token,
            loading: false,
            error: null,
        };
    })
);
