<mat-nav-list
    class="sm:relative"
    [ngClass]="{
        'nav-menu-handsetLandscape':
            currentHandyOrientation === 'handsetLandscape',
        'nav-menu':
            currentHandyOrientation !== 'handsetPortrait' &&
            currentHandyOrientation !== 'handsetLandscape'
    }"
    id="cp2-navigation"
>
    <div class="logo-back-button-container">
        <!-- Logo-->
        <div class="nav-menu-logo">
            <img src="../../assets/img/logo-checkpad-triosys.png"/>
        </div>
    </div>

    <!-- Back and forward buttons and main buttons -->
    <div class="nav-menu-buttons">
        <div
            class="navigation-buttons"
            *ngIf="
                currentHandyOrientation !== 'handsetPortrait' &&
                currentHandyOrientation !== 'handsetLandscape'
            "
        >
            <!-- navigation buttons -->
            <button
                (click)="onClickOnBack()"
                [disabled]="!canNavigateBack()"
                class="arrow-button"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_left</mat-icon>
            </button>

            <button
                (click)="onClickOnForward()"
                [disabled]="!canNavigateForward()"
                class="arrow-button"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_right</mat-icon>
            </button>
        </div>
        <!-- start, workflow and orga buttons -->
        <div
            class="navigation-buttons"
            [ngClass]="{
                'navigation-buttons-handsetLandscape':
                    currentHandyOrientation === 'handsetLandscape'
            }"
        >
            <ng-container *ngFor="let button of buttons">
                <button
                    (click)="onClickOnButton(button)"
                    *ngIf="isButtonActive(button); else strokedButton"
                    class="lbShadowSmall"
                    color="primary"
                    mat-flat-button
                >
                    <mat-icon>done</mat-icon>
                    <!-- Hier fügst du das Mat-Icon hinzu -->
                    {{ button.label | translate }}
                </button>
                <ng-template #strokedButton>
                    <button
                        (click)="onClickOnButton(button)"
                        color="primary"
                        mat-stroked-button
                    >
                        {{ button.label | translate }}
                    </button>
                </ng-template>
            </ng-container>
        </div>

        <div>
            <!-- <mat-form-field>
                <mat-label>Theme</mat-label>
                <mat-select (valueChange)="changeTheme($event)" value="light">
                    <mat-option value="light">Light</mat-option>
                    <mat-option value="dark">Dark</mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>
    </div>

    <!-- <span class="spacer-xy">&nbsp;</span> -->

    <app-network-quality></app-network-quality>
</mat-nav-list>
