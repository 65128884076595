<div class="change-history-container" [ngClass]="{ 'border-b-2': !last }" >
    <div>
        <div class="h6">
            {{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.change" | translate }}
            {{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.from" | translate }}
            {{ modifiedAt.split("T")[0] }}
            <!-- {{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.at" | translate }} -->
            {{ modifiedAt.split("T")[1] }},
            <!-- {{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.by" | translate }} -->
            {{ modifiedBy }}
        </div>

        <table>
            <tr *ngFor="let diff of differences" >
                <td class="align-top">
                    <strong>{{ diff.key }}: </strong>
                </td>
                <td>
                    <span
                        *ngIf="
                            diff.newValue !== undefined &&
                            form_file_name !== 'form_anamnesis.json' &&
                            form_file_name !== 'form_diagnoses.json' &&
                            form_file_name !== 'form_visit.json'
                        "
                        class="table-text changed-content"
                    >
                        {{ diff.newValue }} </span
                    ><span class="table-text history">{{ diff.oldValue }}</span>
                </td>
            </tr>
        </table>
    </div>

    <mat-icon

        *ngIf="index !== 0"
        mat-mini-fab
        (click)="openDialog()"
        class="restore-history-button hover:cursor-pointer"
    >
        settings_backup_restore
    </mat-icon>
</div>
