<button class="!absolute right-4 top-4" mat-dialog-close mat-mini-fab>
    <mat-icon>cancel</mat-icon>
</button>
<mat-dialog-content>
    <app-pdf-viewer *ngIf="data.data.pdfBlob && data.data.documentType === 'pdf'" [src]="data.data?.pdfBlob"
                    class="w-full h-full"></app-pdf-viewer>

    <img *ngIf="data.data.documentPath && data.data.documentType === 'image'" [src]="data.data?.documentPath"
         alt="" class="h-full w-auto mx-auto">
</mat-dialog-content>

