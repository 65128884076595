<div class="mat-elevation-z8">
    <table
        #table1
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
        #t1Sort="matSort"
        class="mat-elevation-z8"
    >
        @for (column of displayedColumns; track column) {
        <ng-container matColumnDef="{{ column }}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "COMPONENT.PAGE_ORGA." + column | translate }}
            </th>

            <td mat-cell *matCellDef="let element" class="table-text">
                <ng-container [ngSwitch]="column">
                    <ng-container *ngSwitchCase="'table_header_basic_info'">
                        <!-- <span
                            (click)="onNameClick(element.id)"
                            [ngClass]="{
                                'column-fullname-pointer ': isWorkflowRoute
                            }"
                        >
                            <strong>
                                {{ element[column].split(",")[0] }},
                                {{ element[column].split(",")[1] }}</strong
                            >, {{ element[column].split(",")[2] }},{{
                                element[column].split(",")[3]
                            }}</span
                        > -->
                        <app-home-patient-basic-info
                            class="patient-list-basic"
                            [patient]="element[column]"
                            [disableDiagnosis]="true"
                        ></app-home-patient-basic-info>
                    </ng-container>
                    <ng-container *ngSwitchCase="'diagnostic_findings'">
                        <span
                            (click)="
                                onClickOnElement(
                                    'form_diag_finding.json',
                                    [element]
                                )
                            "
                            class="column-diag_finding"
                        >
                            {{ element[column] }}</span
                        >
                    </ng-container>
                    <ng-container *ngSwitchCase="'dob'">
                        {{
                            element[column]
                                | date : ("GENERAL.date_format" | translate)
                        }}{{
                            column == "dob"
                                ? "(" + (element[column] | calAge) + ")"
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'examination_date'">
                        {{
                            element[column]
                                | date : ("GENERAL.date_format" | translate)
                        }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'diagnostic_date'">
                        {{
                            element[column]
                                | date : ("GENERAL.date_format" | translate)
                        }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'gender'">
                        {{ "GENERAL." + element[column] | translate }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ element[column] }}
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
