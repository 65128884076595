<mat-form-field>
    <mat-label>{{ "COMPONENT.PAGE_PATIENT_LIST.sort" | translate }} </mat-label>

    <mat-select
        panelClass="sort-selection"
        [hideSingleSelectionIndicator]="true"
        name="sort"
        (selectionChange)="selectionChange($event)"
    >
        <mat-select-trigger>
            {{ displayText | translate }}
        </mat-select-trigger>

        @for (option of options(); track option.value) {
        <mat-option
        class="option-list"
            [value]="option.value"
            (onSelectionChange)="onSelectionChange($event)"
        >
            <div class="option">
                <div> {{ option.translateKey | translate }}</div>

                @if (sortSelection().value === option.value) {
                <app-sort-indicator
                    [sortDirection]="sortSelection().sortOrder"
                />
                }
            </div>
        </mat-option>
        }
    </mat-select>
</mat-form-field>
