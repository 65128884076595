import {createReducer, on} from "@ngrx/store";
import * as MercureActions from "../actions/mercure.action";
import {MercureStatus} from "../../models/mercure.model";

export interface MercureState {
    mercure: MercureStatus;
    loading: boolean;
    error: any;
}

export const initialState: MercureState = {
    mercure: {
        serverPingStatus: false,
        _serverPingDate: new Date(),
        serverConnectionStatus: false,
    },
    loading: false,
    error: null,
};

export const mercureReducer = createReducer(
    initialState,
    on(MercureActions.setServerPingStatus, (state, {serverPingStatus}) => ({
        ...state,
        serverPingStatus,
        loading: true
    })),
    on(MercureActions.setServerPingStatusSuccess, (state, {serverPingStatus}) => ({
        ...state,
        mercure: serverPingStatus,
        loading: false,
        error: null,
    })),
    on(MercureActions.setServerPingStatusFail, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    })),
    on(MercureActions.loadServerPingStatusSuccess, (state, mercureState) => ({
        ...state,
        mercure: mercureState.serverPingStatus,
        loading: false,
        error: null
    })),
);
