import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dayAgo',
    standalone: true,
})
export class DayAgoPipe implements PipeTransform {
    transform(value: string | number | null | undefined): string {
        if (!value) {
            return '?'; // Return '?' if value is null, undefined, or falsey
        }
        const date = dayjs(value);

        if (date.isValid()) {
            const now = dayjs();
            const diff = now.diff(date, 'day');
            return diff.toString(); // Convert number to string to keep return type consistent
        }

        // If the date is not valid, return 'Invalid date'
        return 'Invalid date';
    }
}
