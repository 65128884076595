import {Component, OnInit} from "@angular/core";
import {AuthService} from "../../../services/auth.service";
import {OAuthService} from "angular-oauth2-oidc";
import {Platform} from "@ionic/angular";

@Component({
    standalone: true,
    imports: [],
    templateUrl: "./login-callback.component.html",
    styleUrl: "./login-callback.component.scss",
})
export class LoginCallbackComponent implements OnInit {

    constructor(private platform: Platform, private authService: AuthService, private oauthService: OAuthService) {
    }

    async ngOnInit(): Promise<void> {
        if (!this.platform.is("electron")) {
            await this.authService.initializeLoginCallback();
        }
        /*if (!this.platform.is("electron")) {
            // Deklaration der Suchmuster für die Authentifizierungsantworten
            const checks: RegExp[] = [/[\?|&|#]code=/, /[\?|&|#]error=/, /[\?|&|#]token=/, /[\?|&|#]id_token=/];

            // Funktion zur Überprüfung, ob die URL eine Authentifizierungsantwort enthält
            function isResponse(str: string): boolean {
                if (!str) return false;
                for (let i = 0; i < checks.length; i++) {
                    if (str.match(checks[i])) return true;
                }
                return false;
            }

            // Erstellen der Nachricht basierend auf der URL
            const message: string = isResponse(window.location.hash) ? window.location.hash : "#" + window.location.search;
            // PostMessage für verschiedene Szenarien
            if (window.parent && window.parent !== window) {
                // Wenn als iFrame während eines Silent Refresh geladen
                window.parent.postMessage(message, window.location.origin);
            } else if (window.opener && window.opener !== window) {
                // Wenn als Popup während des initialen Logins geladen
                window.opener.postMessage(message, window.location.origin);
            } else {
                // Letzte Möglichkeit für ein Popup, das durch Redirects gegangen ist und window.opener nicht verwenden kann
                localStorage.setItem("auth_hash", message);
                localStorage.removeItem("auth_hash");
            }
        }*/

        if (this.platform.is("electron")) {
            // Beim Schließen des Popups
            window.close();

            // Senden Sie die URL des Popups an das Hauptfenster über window.opener
            if (window.opener) {
                window.opener.postMessage({type: "popupClosed", url: window.location.href}, "*");
            }
        }
    }
}
