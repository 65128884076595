Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<div\n  class="alert alert-info no-drag"\n  style="text-align:center; margin-bottom: 5px;"\n  role="alert"\n  data-noattach="true"\n  data-position="' +
((__t = (ctx.position)) == null ? '' : __t) +
'"\n>\n  ' +
((__t = (ctx.t('Drag and Drop a form component'))) == null ? '' : __t) +
'\n</div>\n';
return __p
}