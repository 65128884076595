import {AuthorizationToken, Device, UserToken} from "../models/auth.model";
import {Injectable} from "@angular/core";
import {DEFAULT_SETTINGS, Settings} from "../models/settings.model";
import {
    LS_ACCESS_TOKEN,
    LS_AUTHZ_TOKENS,
    LS_CODE_VERIFIER,
    LS_DEVICE,
    LS_PATIENT_RECORDS,
    LS_PATIENT_RECORDS_AREAS,
    LS_PATIENT_RECORDS_SUBAREAS,
    LS_PATIENT_SELECTED_RECORD,
    LS_SETTINGS,
} from "../shared/constants";
import {Preferences} from "@capacitor/preferences";
import {DbService} from "./database/db.service";
import {ViewContent} from "../models/view-content.model";
import {MercureStatus} from "../models/mercure.model";
import {Area, Record, SubArea} from "../components/patient-info/patient-files/patient-files.component";
//
// TODO: BIG TODO!!! Check the use of Access and Authz with care
//

@Injectable({
    providedIn: 'root',
})
export class DataRepositoryService {
    private static readonly TAG = 'DataRepositoryService';

    public constructor(private dbService: DbService) {
    }

    //#region Authz
    /**
     * Safely gets all authorization tokens. Will return all saved authz tokens or an empty array in case of error or none
     */
    public async getAllAuthzTokens(): Promise<AuthorizationToken[]> {
        try {
            const v = (await Preferences.get({key: LS_AUTHZ_TOKENS})).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving authz tokens',
                e
            );
        }

        return [];
    }

    /**
     * Save the given array to the preferences' authorization tokens. Will clean up the expired tokens before save.
     * Will overwrite whatever was in preferences before.
     */
    public async saveAuthzTokens(tokens: AuthorizationToken[]): Promise<void> {
        // Remove tokens that are older than 300 seconds
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        tokens = tokens.filter((token) => {
            const expiresIn = token.exp;
            return expiresIn >= 0 && expiresIn <= 300 + currentTimeInSeconds;
        });

        await Preferences.set({
            key: LS_AUTHZ_TOKENS,
            value: JSON.stringify(tokens),
        });
    }

    //#endregion

    //#region Access Token
    public async getAccessToken(): Promise<UserToken | null> {
        try {
            const value = (await Preferences.get({key: LS_ACCESS_TOKEN}))
                .value;
            return value ? JSON.parse(value) : null;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving access token`,
                e
            );
            return null;
        }
    }

    public async setAccessToken(token: UserToken | null): Promise<void> {
        if (!token) {
            await Preferences.remove({key: LS_ACCESS_TOKEN});
            return;
        }

        await Preferences.set({
            key: LS_ACCESS_TOKEN,
            value: JSON.stringify(token),
        });
    }

    public async removeAccessToken(): Promise<void> {
        return await this.setAccessToken(null);
    }

    public async saveCodeVerifier(codeVerifier: string | null): Promise<void> {
        if (!codeVerifier) {
            await Preferences.remove({key: LS_CODE_VERIFIER});
            return;
        }

        await Preferences.set({
            key: LS_CODE_VERIFIER,
            value: codeVerifier,
        });
    }

    public async getCodeVerifier(): Promise<string | null> {
        try {
            return (await Preferences.get({key: LS_CODE_VERIFIER})).value;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving code verifier`,
                e
            );
            return null;
        }
    }

    public async removeCodeVerifier(): Promise<void> {
        try {
            await Preferences.remove({key: LS_CODE_VERIFIER});
        } catch (e) {
            console.error(
                `${DataRepositoryService.TAG}, error removing code verifier`,
                e
            );
        }
    }

    //#endregion

    //#region settings
    public async saveSettings(settings: Settings): Promise<void> {
        await Preferences.set({
            key: LS_SETTINGS,
            value: JSON.stringify(settings),
        });
    }

    public async getSettings(): Promise<Settings> {
        try {
            const value = (await Preferences.get({key: LS_SETTINGS})).value;
            return value ? JSON.parse(value) : DEFAULT_SETTINGS;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving settings`,
                e
            );
            return DEFAULT_SETTINGS;
        }
    }

    //#endregion

    //#region settings
    public async saveMercure(mercure: MercureStatus): Promise<void> {
        /*  await Preferences.set({
         key: LS_SETTINGS,
         value: JSON.stringify(settings),
         });*/
    }

    public async getMercure(): Promise<any> {
        /* try {
         const value = (await Preferences.get({ key: LS_SETTINGS })).value;
         return value ? JSON.parse(value) : DEFAULT_SETTINGS;
         } catch (e) {
         console.warn(
         `${DataRepositoryService.TAG}, error retrieving settings`,
         e
         );
         return DEFAULT_SETTINGS;
         }*/
    }

    //#endregion

    //#region Device
    public async getDevice(): Promise<Device> {
        try {
            const v = (await Preferences.get({key: LS_DEVICE})).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving device id',
                e
            );
        }

        return {deviceId: ''};
    }

    public async saveDevice(device: Device): Promise<void> {
        await Preferences.set({
            key: LS_DEVICE,
            value: JSON.stringify(device),
        });
    }

    //#endregion

    //#region ViewContent
    async createViewContent(viewContent: ViewContent): Promise<any> {
        return this.dbService.createViewContent(
            viewContent.locator,
            viewContent.content,
            viewContent.scopes ?? ''
        );
    }

    async getViewContentById(id: number): Promise<ViewContent> {
        const result = await this.dbService.getViewContentById(id);
        return result; // Convert to ViewContent if necessary
    }

    async getAllViewContent(): Promise<ViewContent[]> {
        const results = await this.dbService.getAllViewContent();
        return results.values;
    }

    async updateViewContent(viewContent: ViewContent): Promise<any> {
        if (!viewContent.id || viewContent.id < 1)
            throw Error(
                'view content must have a positive id property for update'
            );

        return this.dbService.updateViewContent(
            viewContent.id,
            viewContent.locator,
            viewContent.content,
            viewContent.scopes ?? ''
        );
    }

    async deleteViewContent(id: number): Promise<any> {
        return this.dbService.deleteViewContent(id);
    }

    //#endregion

    //#region patientRecords

    async getRecords(): Promise<Record[]> {
        const {value} = await Preferences.get({key: LS_PATIENT_RECORDS});
        return value ? JSON.parse(value) : [];
    }

    async setRecords(records: Record[]): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_RECORDS,
            value: JSON.stringify(records),
        });
    }

    async getSelectedRecord(): Promise<Record | null> {
        const {value} = await Preferences.get({key: LS_PATIENT_SELECTED_RECORD});
        return value ? JSON.parse(value) : null;
    }

    async setSelectedRecord(record: Record): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_SELECTED_RECORD,
            value: JSON.stringify(record),
        });
    }

    async removeSelectedRecord(): Promise<void> {
        await Preferences.remove({key: LS_PATIENT_SELECTED_RECORD});
    }

    async getAreas(): Promise<Area[]> {
        const {value} = await Preferences.get({key: LS_PATIENT_RECORDS_AREAS});
        return value ? JSON.parse(value) : [];
    }

    async getSubAreas(): Promise<SubArea[]> {
        const {value} = await Preferences.get({key: LS_PATIENT_RECORDS_SUBAREAS});
        return value ? JSON.parse(value) : [];
    }

    async addArea(area: Area): Promise<Area> {
        const areas = await this.getAreas();
        areas.push(area);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas)
        });
        return area;
    }

    async updateArea(updatedArea: Area): Promise<Area> {
        let areas = await this.getAreas();
        areas = areas.map(area => area.id === updatedArea.id ? updatedArea : area);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas)
        });
        return updatedArea;
    }

    async removeArea(areaId: string): Promise<void> {
        let areas = await this.getAreas();
        areas = areas.filter(area => area.id !== areaId);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas)
        });
    }

    async addSubArea(subArea: SubArea): Promise<SubArea> {
        const subAreas = await this.getSubAreas();
        subAreas.push(subArea);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas)
        });
        return subArea;
    }

    async updateSubArea(updatedSubArea: SubArea): Promise<SubArea> {
        let subAreas = await this.getSubAreas();
        subAreas = subAreas.map(subArea => subArea.id === updatedSubArea.id ? updatedSubArea : subArea);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas)
        });
        return updatedSubArea;
    }

    async removeSubArea(subAreaId: string): Promise<void> {
        let subAreas = await this.getSubAreas();
        subAreas = subAreas.filter(subArea => subArea.id !== subAreaId);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas)
        });
    }

    //#endregion
}
