import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogClose,
    MatDialogRef,
    MatDialogActions,
} from '@angular/material/dialog';
import { VisitRecord } from '../../models/patient.model';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { PatientNameAndDob } from '../../pages/patient-details/patient-details.component';
import {
    FormModalComponent,
    FormModalResult,
} from '../form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
    FormioRendererData,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { ChangingHistoryComponent } from '../../components/data-interaction/changing-history/changing-history.component';
import { ConfirmationDialogComponent } from '../../components/utility/confirmation-dialog/confirmation-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';

//history contains all the info incluing the latest one
interface GroupedVisits {
    latest: VisitRecord;
    history: VisitRecord[];
    historyVisible?: boolean;
}
@Component({
    selector: 'app-start-page-popup-list',
    templateUrl: './start-page-popup-list.component.html',
    styleUrls: ['./start-page-popup-list.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatCardModule,
        MatExpansionModule,
        CalAgePipe,
        DayAgoPipe,
        TranslateModule,
        CommonModule,
        MatListModule,
        MatIcon,
        MatButtonModule,
        MatDialogClose,
        ChangingHistoryComponent,MatBadgeModule
    ],
})
export class StartPagePopupListComponent implements OnInit {
    panelOpenState = false;
    groupedVisits: { [referenceId: string]: GroupedVisits } = {};
    showHistory: boolean = false;
    allData: any;
    viewContentI18n: FormioRendererI18n;
    public formioRendererData: FormioRendererData[] = [];
    toggleHistory() {
        this.showHistory = !this.showHistory;
    }

    constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            data: VisitRecord[];
            patient_info: PatientNameAndDob;
            viewContentI18n: FormioRendererI18n;
        },
        public dialog: MatDialog
    ) {
        console.log(data.data);
        this.allData = data;
        this.viewContentI18n = data.viewContentI18n;
    }
    ngOnInit(): void {
        this.processVisits(this.data.data);
    }

    //according to the modified date, sort all the incoming data
    private processVisits(dataArray: any[]): void {
        dataArray.forEach((visitRecord: VisitRecord) => {
            const refId = visitRecord.referenceId;
            if (!this.groupedVisits[refId]) {
                this.groupedVisits[refId] = {
                    latest: visitRecord,
                    history: [],
                    historyVisible: false,
                };
            }
            this.groupedVisits[refId].history.push(visitRecord);
            this.groupedVisits[refId].history.sort(
                (a, b) =>
                    new Date(b.modifiedAt).getTime() -
                    new Date(a.modifiedAt).getTime()
            );
        });
    }

    public onClose(): void {
        this.dialogRef.close(); // Optionally pass a result here if needed
    }
    public async onClickOnElement(
        formFileName: string,
        data: VisitRecord,
        patientInfo?: PatientNameAndDob,
        allData?: any
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                form_data: { data },
                patient_info: patientInfo,
                allData,
                viewContentI18n: this.viewContentI18n,
            },
            height:'auto'
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log('User confirmed the action');
                // Put your restore logic here
            } else {
                console.log('User dismissed the action');
            }
        });
    }
}


