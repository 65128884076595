import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {NgClass, NgIf} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
    selector: 'media-toolbar',
    templateUrl: './media-toolbar.component.html',
    styleUrls: ['./media-toolbar.component.scss'],
    imports: [
        MatIconButton,
        MatIcon,
        NgClass,
        MatTooltip,
        NgIf
    ],
    standalone: true
})
export class MediaToolbarComponent implements OnInit {
    @Input() isFullscreenForImageEnabled!: { data: any, fullScreen: boolean };
    @Input() isPaintingToolOpened!: { data: any, isPaintingToolDialogOpened: boolean };
    @Input() isMetadataViewOpened!: boolean;
    @Output() isFullscreenForImageEnabledChanged = new EventEmitter<{ data: any, fullScreen: boolean }>();
    @Output() isPaintingToolOpenedChanged = new EventEmitter<{ data: any, isPaintingToolDialogOpened: boolean }>();
    @Output() isMetaDataViewOpenedChanged = new EventEmitter<boolean>();

    toggleFullscreen() {
        this.isFullscreenForImageEnabledChanged.emit({
            data: {},
            fullScreen: !this.isFullscreenForImageEnabled.fullScreen
        });
    }

    togglePaintingTool() {
        this.isPaintingToolOpenedChanged.emit({
            data: {},
            isPaintingToolDialogOpened: !this.isPaintingToolOpened.isPaintingToolDialogOpened
        });
    }

    toggleMetaDataView() {
        console.log(this.isMetadataViewOpened)
        this.isMetaDataViewOpenedChanged.emit(!this.isMetadataViewOpened);
    }

    ngOnInit() {
    }
}
