import {createReducer, on} from '@ngrx/store';
import * as RecordsActions from '../actions/records.action';
import {Record} from "../../components/patient-info/patient-files/patient-files.component";

export interface RecordsState {
    records: Record[];
    selectedRecord: Record | null;
    error: any;
    loading: boolean;
}

export const initialState: RecordsState = {
    records: [],
    selectedRecord: null,
    error: null,
    loading: false,
};

export const recordsReducer = createReducer(
    initialState,
    on(RecordsActions.loadRecords, state => ({
        ...state,
        loading: true,
        error: null
    })),
    on(RecordsActions.loadRecordsSuccess, (state, {records}) => ({
        ...state,
        records,
        loading: false
    })),
    on(RecordsActions.loadRecordsFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(RecordsActions.loadSelectedRecord, state => ({
        ...state,
        loading: true,
        error: null
    })),
    on(RecordsActions.loadSelectedRecordSuccess, (state, {record}) => ({
        ...state,
        selectedRecord: record,
        loading: false
    })),
    on(RecordsActions.loadSelectedRecordFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(RecordsActions.setSelectedRecordSuccess, (state, {record}) => ({
        ...state,
        selectedRecord: record,
        loading: false
    })),
    on(RecordsActions.setSelectedRecordFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(RecordsActions.removeSelectedRecord, state => ({
        ...state,
        loading: true,
        error: null
    })),
    on(RecordsActions.removeSelectedRecordSuccess, state => ({
        ...state,
        selectedRecord: {} as Record,
        loading: false
    })),
    on(RecordsActions.setSelectedRecordFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    }))
);
