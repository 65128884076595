<div style="padding: 1em;">
    <h1 mat-dialog-title>{{ 'GENERAL.are_you_sure' | translate }}</h1>
    <div mat-dialog-content>
        {{ 'GENERAL.restore_this_info' | translate }}
    </div>
    <div mat-dialog-actions class="confirmation-buttons">
        <button mat-button (click)="onConfirm()">
            {{ 'GENERAL.yes' | translate }}
        </button>
        <button mat-button (click)="onDismiss()">
            {{ 'GENERAL.button_text_cancel' | translate }}
        </button>
    </div>
</div>
