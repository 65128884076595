import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicListComponent } from '../../data-interaction/dynamic-list/dynamic-list.component';
import { CommonModule } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
    getDummyPatientListItem,
    PatientListItem,
    Task,
} from '../../../models/patient.model';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { PatientNameAndDob } from '../../../pages/patient-details/patient-details.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { StartPageTaskPopupComponent } from '../../../modals/start-page-task-popup/start-page-task-popup.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';

@Component({
    selector: 'app-home-patient-tasks',
    templateUrl: './home-patient-tasks.component.html',
    styleUrls: ['./home-patient-tasks.component.scss'],
    standalone: true,
    imports: [
        DynamicListComponent,
        CommonModule,
        MatIcon,
        TranslateModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class HomePatientTasksComponent implements OnInit {
    @Input() patient: PatientListItem = getDummyPatientListItem();
    @Input() viewContentI18n: FormioRendererI18n | undefined;
    @Output() columnClickEvent = new EventEmitter<Promise<void>>();
    //#region control dynamic list
    //show the button for the dynamic list,
    showButton: boolean = false;
    /**
     * A flag indicating whether the list is currently clipped (i.e., showing a reduced number of items).
     */
    isClipped = true;
    //#end region

    latestTaskofTasks: Task[] = [];

    constructor(public dialog: MatDialog) {}

    ngOnInit() {
        this.processTasks();
        if (this.latestTaskofTasks.length > 3) {
            this.showButton = true;
        }
    }

    public getPatientNameAndDob(): PatientNameAndDob {
        return {
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
            dob: this.patient.dob,
            gender: this.patient.gender,
            ward: this.patient.ward,
            bed: this.patient.bed,
            room: this.patient.room,
        };
    }
//open the list of the tasks
    public async onClickOnElement(
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogRef = this.dialog.open(StartPageTaskPopupComponent, {
            data: {
                data: this.patient.tasks,
                patient_info: patientInfo,
                viewContentI18n: this.viewContentI18n,
            },
             height:'auto',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

//open a new formular
    public async openFormDialog(
        formFileName: string,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo,
            },height:'auto'
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
        // TODO: Do something with the results
    }

    /**
     * Toggles the clipped state of the list, showing more or less items accordingly.
     */
    public toggleClip(): void {
        this.isClipped = !this.isClipped;
    }

    private processTasks(): void {
        this.latestTaskofTasks = Object.values(
            this.patient.tasks
                .sort(
                    (a, b) =>
                        new Date(b.modifiedAt).getTime() -
                        new Date(a.modifiedAt).getTime()
                )
                .reduce((acc, record) => {
                    if (!acc[record.referenceId]) {
                        acc[record.referenceId] = record;
                    }
                    return acc;
                }, {} as { [key: string]: Task })
        );
        this.sortTasksByModifiedAt(this.latestTaskofTasks);
    }

    private sortTasksByModifiedAt(visitRecords: Task[]): void {
        if (visitRecords) {
            visitRecords.sort(
                (a, b) =>
                    new Date(b.modifiedAt).getTime() -
                    new Date(a.modifiedAt).getTime()
            );
        }
    }
}
