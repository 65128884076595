import {BrowserModule} from "@angular/platform-browser";
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule,} from "@angular/core";
import {CommonModule, DatePipe, LocationStrategy} from "@angular/common";
import {RouterModule, RouterOutlet} from "@angular/router";
import {AppComponent} from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {routes} from "./app.routes";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {metaReducers, reducers} from "./store/reducers";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {AuthzEffects} from "./store/effects/authz.effect";
import {IonicModule} from "@ionic/angular";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule, TranslateService,} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatCardModule} from "@angular/material/card";
import {OrganizationComponent} from "./pages/organization/organization.component";
import {WorkflowComponent} from "./pages/workflow/workflow.component";
import {DeviceEffects} from "./store/effects/device.effect";
import {SQLiteService} from "./services/database/sqlite.service";
import {InitializeAppDbService} from "./services/database/initialize-app-db.service";
import {DbService} from "./services/database/db.service";
import {DbnameVersionService} from "./services/database/dbname-version.service";
import {Capacitor} from "@capacitor/core";
// Import PWA elements and SQLite custom elements loaders.
import {defineCustomElements as pwaElements} from "@ionic/pwa-elements/loader";
import {defineCustomElements as jeepSqlite} from "jeep-sqlite/loader";
import {AccessEffects} from "./store/effects/access.effect";
import {SettingsComponent} from "./pages/settings/settings.component";
import {SettingEffects} from "./store/effects/settings.effect";
import {PatientDetailsComponent} from "./pages/patient-details/patient-details.component";
import {PatientOverviewComponent} from "./components/patient-info/patient-overview/patient-overview.component";
import {ParameterPathLocationStrategy} from "./shared/ParameterHashLocationStrategy";
import {DialogPatientOverviewComponent} from "./modals/dialog-patient-overview/dialog-patient-overview.component";
import {MercureEffects} from "./store/effects/mercure.effect";
import {FormsModule} from "@angular/forms";
import {AppNavigationComponent} from "./components/navigation/app-navigation/app-navigation.component";
import {SidebarComponent} from "./components/navigation/sidebar/sidebar.component";
import {HomeComponent} from "./pages/home/home.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {OAuthModule, OAuthService, OAuthStorage} from "angular-oauth2-oidc";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RecordsEffects} from "./store/effects/records.effect";
import {AreaEffects} from "./store/effects/areas.effect";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./../assets/i18n/", ".json");
}

/**
 * Factory function for initializing the application.
 * @param {InitializeAppDbService} init - The initialization service.
 * @returns A function that initializes the application.
 */
export function initializeFactory(init: InitializeAppDbService) {
    return () => init.initializeAppDb();
}

export function setupTranslateFactory(service: TranslateService): Function {
    return () => service.use("de");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppNavigationComponent,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        DragDropModule, MatToolbarModule, MatButtonModule, MatIconModule,
        EffectsModule.forRoot([
            AuthzEffects,
            AccessEffects,
            SettingEffects,
            MercureEffects,
            DeviceEffects,
            RecordsEffects,
            AreaEffects,
        ]),
        HttpClientModule,
        IonicModule.forRoot({}),
        SettingsComponent,
        MatCardModule,
        RouterModule.forRoot(routes),
        RouterOutlet,
        SidebarComponent,
        OrganizationComponent,
        WorkflowComponent,
        HomeComponent,
        PatientDetailsComponent,
        PatientOverviewComponent,
        DialogPatientOverviewComponent,
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
            },
        }), // Initialisiert das OAuth-Modul
    ],
    providers: [
        {provide: LocationStrategy, useClass: ParameterPathLocationStrategy},
        SQLiteService,
        InitializeAppDbService,
        DbService,
        DbnameVersionService, DatePipe,
        {
            provide: APP_INITIALIZER, // Use APP_INITIALIZER to run code when the application is initialized.
            useFactory: initializeFactory, // Specify the factory function to use.
            deps: [InitializeAppDbService], // Declare dependencies required by the factory function.
            multi: true, // Allow multiple initializers to be registered.
        },
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TranslateService],
            multi: true,
        },
        {provide: OAuthStorage, useValue: localStorage},
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    public constructor(private oauthService: OAuthService) {
        // Check if the platform is web to initialize PWA elements and SQLite for web usage.
        // Only required if you want to use a web platform
        if (Capacitor.getPlatform() === "web") {
            pwaElements(window); // Load PWA elements, required for toast component in Browser
            jeepSqlite(window); // Load SQLite custom elements for web, required for jeep-sqlite Stencil component to use a SQLite database in Browser

            // Listen for the DOMContentLoaded event to append the jeep-sqlite element to the body
            // This is necessary for utilizing SQLite in a web environment.
            window.addEventListener("DOMContentLoaded", async () => {
                const jeepEl = document.createElement("jeep-sqlite");
                document.body.appendChild(jeepEl);
                jeepEl.autoSave = true;
            });
        }
    }
}
