<ng-container *ngFor="let item of listToShow">
    <div *ngTemplateOutlet="template; context: { item: item }"></div>
</ng-container>

<div [@inOutExpandY] *ngIf="!isClipped" style="overflow: hidden">
    <ng-container *ngFor="let item of list.slice(clipToWhenSmall, list.length)">
        <ng-container
            *ngTemplateOutlet="template; context: { item: item }"
        ></ng-container>
    </ng-container>
</div>
<div
    *ngIf="list && list.length > clipToWhenSmall && showButton"
    (click)="toggleClip()"
    class="show-more"
>
{{
    (isClipped
        ? "COMPONENT.DYNAMIC_LIST.button_text_show_more"
        : "COMPONENT.DYNAMIC_LIST.button_text_show_less"
    ) | translate
}}
</div>
<!-- {{
        (isClipped
            ? "COMPONENT.DYNAMIC_LIST.button_text_show_more"
            : "COMPONENT.DYNAMIC_LIST.button_text_show_less"
        ) | translate
    }} -->
