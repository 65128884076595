<form (submit)="onSubmitButton($event)" class="example-form">
    <ng-container *ngFor="let component of components">
        <ng-container [ngSwitch]="component.type">
            <!-- Label. will render a label for the current component -->
            <ng-container *ngSwitchCase="'label'">
                <div
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <mat-label
                        >{{ getComponentLabel(component.key) }}
                    </mat-label>
                </div>
            </ng-container>

            <!-- Textfield -->
            <ng-container *ngSwitchCase="'textfield'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                    appearance="outline"
                >
                    <mat-label
                        >{{ getComponentLabel(component.key) }}
                    </mat-label>
                    <textarea
                        (input)="onComponentDataChange($event, component)"
                        [readonly]="readonly"
                        [value]="component['x-data'] ?? ''"
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        [cdkAutosizeMinRows]="
                            currentHandyOrientation === 'handsetLandscape'
                                ? 1
                                : 2
                        "
                        cdkAutosizeMaxRows="3"
                    ></textarea>
                </mat-form-field>
            </ng-container>

            <!-- Textarea -->
            <ng-container *ngSwitchCase="'textarea'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    appearance="outline"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <mat-label
                        >{{ getComponentLabel(component.key) }}
                    </mat-label>
                    <textarea
                        (input)="onComponentDataChange($event, component)"
                        [readonly]="readonly"
                        [value]="component['x-data'] ?? ''"
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        [cdkAutosizeMinRows]="
                            currentHandyOrientation === 'handsetLandscape'
                                ? 1
                                : 5
                        "
                        cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>
            </ng-container>

            <!-- Checkbox -->
            <ng-container *ngSwitchCase="'checkbox'">
                <section
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <mat-checkbox
                        (change)="onCheckboxChange($event, component)"
                        [checked]="component['x-data']"
                        [disabled]="readonly"
                    >
                        {{ getComponentLabel(component.key) }}
                    </mat-checkbox>
                </section>
            </ng-container>

            <!-- Select -->
            <ng-container *ngSwitchCase="'select'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    appearance="outline"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <mat-label
                        >{{ getComponentLabel(component.key) }}
                    </mat-label>
                    <mat-select
                        (selectionChange)="onSelectChange($event, component)"
                        [disabled]="readonly"
                        [value]="component['x-data']"
                        matNativeControl
                    >
                        <mat-option
                            *ngFor="let s of component.data?.values"
                            [value]="s.value"
                        >
                            {{ getComponentLabel(s.value) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <!-- Select boxes -->
            <ng-container *ngSwitchCase="'selectboxes'">
                <section
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <h4>{{ getComponentLabel(component.key) }}</h4>
                    <p>
                        <mat-checkbox
                            (change)="onCheckboxChange($event, component)"
                            *ngFor="let s of component['values']"
                            [checked]="s.value === component['x-data'][s.value]"
                            [disabled]="readonly"
                        >
                            {{ getComponentLabel(s.value) }}
                        </mat-checkbox>
                    </p>
                </section>
            </ng-container>

            <!-- Radio -->
            <ng-container *ngSwitchCase="'radio'">
                <div
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <label>{{ getComponentLabel(component.key) }}</label>
                    <mat-radio-group
                        (change)="onRadioChange($event, component)"
                        [value]="component['x-data']"
                    >
                        <mat-radio-button
                            *ngFor="let s of component.values"
                            [disabled]="readonly"
                            [value]="s.value"
                        >
                            {{ s.label }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

            <!-- Date picker -->
            <ng-container *ngSwitchCase="'datetime'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    appearance="outline"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <mat-label
                        >{{ getComponentLabel(component.key) }}
                    </mat-label>
                    <input
                        (dateChange)="onDateChange($event, component)"
                        [disabled]="readonly"
                        [matDatepicker]="picker"
                        [value]="component['x-data']"
                        matInput
                    />
                    <!-- <mat-hint>{{ component.format }}</mat-hint> -->
                    <mat-datepicker-toggle
                        *ngIf="!readonly"
                        [for]="picker"
                        matIconSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </ng-container>

            <!-- Date and time pciker -->
            <ng-container *ngSwitchCase="'dateandtime'">
                <luic-datetimepicker-input
                    (inputFieldValueChanged)="
                        onDateAndTimeChange($event, component)
                    "
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    [lbDisable]="isComponentDisabled(component)"
                    [lbLabel]="getComponentLabel(component.key) ?? ''"
                    [lbPlaceholder]="'Date and time'"
                    [lbValue]="component['x-data']"
                ></luic-datetimepicker-input>
            </ng-container>

            <!-- Button -->
            <ng-container *ngSwitchCase="'button'">
                <button
                    (click)="onClickOnButton(component.key)"
                    *ngIf="component['x-show'] && !readonly"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                    mat-flat-button
                >
                    {{ getComponentLabel(component.key) }}
                </button>
            </ng-container>

            <!-- Fieldset -->
            <ng-container *ngSwitchCase="'fieldset'">
                <app-formio-renderer
                    (formButtonClick)="onClickOnButton($event)"
                    (formSubmitted)="onSubmitButton()"
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    [data]="data"
                    [form]="component"
                    [i18n]="i18n"
                    [readonly]="readonly"
                    class="form-renderer-fieldset {{ component.customClass }}"
                ></app-formio-renderer>
            </ng-container>

            <!-- Datagrid -->
            <ng-container *ngSwitchCase="'datagrid'">
                <div
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                >
                    <app-formio-renderer
                        (formButtonClick)="onClickOnButton($event)"
                        (formSubmitted)="onSubmitButton()"
                        [data]="data"
                        [form]="component"
                        [i18n]="i18n"
                        [readonly]="readonly"
                    ></app-formio-renderer>
                </div>
            </ng-container>

            <!-- Default fallback -->
            <!-- <ng-container *ngSwitchDefault>
                {{ component | json }}
            </ng-container> -->
        </ng-container>
    </ng-container>
</form>
