import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as RecordsActions from '../actions/records.action';
import {DataRepositoryService} from '../../services/datarepository.service';

@Injectable()
export class RecordsEffects {
    loadRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RecordsActions.loadRecords),
            switchMap(() =>
                from(this.repo.getRecords()).pipe(
                    map(records => RecordsActions.loadRecordsSuccess({records})),
                    catchError(error => of(RecordsActions.loadRecordsFailure({error})))
                )
            )
        )
    );

    loadSelectedRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RecordsActions.loadSelectedRecord),
            switchMap(() =>
                from(this.repo.getSelectedRecord()).pipe(
                    map(record => RecordsActions.loadSelectedRecordSuccess({record})),
                    catchError(error => of(RecordsActions.loadSelectedRecordFailure({error})))
                )
            )
        )
    );

    setSelectedRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RecordsActions.setSelectedRecord),
            switchMap(action =>
                from(this.repo.setSelectedRecord(action.record)).pipe(
                    map(() => RecordsActions.setSelectedRecordSuccess({record: action.record})),
                    catchError(error => of(RecordsActions.setSelectedRecordFailure({error})))
                )
            )
        )
    );

    removeSelectedRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RecordsActions.removeSelectedRecord),
            switchMap(() =>
                from(this.repo.removeSelectedRecord()).pipe(
                    map(() => RecordsActions.removeSelectedRecordSuccess()),
                    catchError(error => of(RecordsActions.setSelectedRecordFailure({error})))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private repo: DataRepositoryService
    ) {
    }
}
