import {SafeResourceUrl} from '@angular/platform-browser';
import {FormioRendererData} from "../components/data-interaction/formio-renderer/formio-renderer.component";

export interface PatientProfile {
    displayName: string;
    profileImg: string;
}

export interface DiagnosticFindings {
    diagnosticFindingsName: string;
    catalog: string;
    examinationDate: string;
    diagnosticFindingsDate: string;
}

export interface PatientListItem {
    id: string;
    location?: string; //TODO:this is maybe not optional
    ward: string;
    room: string;
    bed: string;
    firstName: string;
    lastName: string;
    fullname?: string;
    gender: string;
    dob: string;
    admissionDate: string;
    diagnosis: string[];
    profile?: PatientProfile;
    medicalOrders?: MedicalOrders[];
    diagnosticFindings?: DiagnosticFindings[];
    tasks: Task[];
    comments?: Comments[];
    visitRecords: VisitRecord[];
    discharge: Discharge[];
    treatment?: Treatment[];
}

export interface Treatment {
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    treatmentName: string;
    plannedDate: string;
}

export interface VisitRecord {
    modifiedAt: string;
    modifiedBy: string;
    referenceId: string;
    note: string;
    modificationAction?: VisitRecordModificationAction;
}

export interface FormattedPatientListItem {
    id: string;
    location?: string; //TODO:this is maybe not optional
    ward: string;
    room: string;
    bed: string;
    firstName: string;
    lastName: string;
    gender: string;
    dob: string;
    admissionDate: string;
    diagnosis: string[];
    profile?: PatientProfile;
    diagnosticFindings?: DiagnosticFindings[];
    tasks: Task[];
    dischargeDate?: string | null;
    disChargeComment?: string | null;
    setInCheckpad?: string | null;
    age?: string;
    stayingDays?: string;
    daysToDischarge?: string;
    visitRecords: VisitRecord[];
}

export const getDummyPatientListItem = (): PatientListItem => ({
    id: '',
    ward: '',
    room: '',
    bed: '',
    firstName: '',
    lastName: '',
    gender: '',
    dob: '',
    admissionDate: '',
    diagnosis: [''],
    profile: {displayName: '', profileImg: ''},
    tasks: [],
    visitRecords: [],
    discharge: [],
    treatment: [],
});

//TODO: modiefiedAt should be added to all the necessary interface, to show the changing history
interface Anamnesis {
    referenceId?: string; //TODO:this one is not optional
    anamnesis: string;
    date?: string; //TODO:later need to be deleted
    modifiedAt?: string;
    modifiedBy?: string;
    showThisAnamnesis?: boolean;
}

interface Diagnostics {
    referenceId?: string; //TODO:this one is not optional
    title: string;
    details: string;
    task: boolean;
    teams: { [key: string]: string | boolean }; //TODO:boolen must be deleted later
    registered: boolean;
    conducted: boolean;
    goalDate?: 'yesterday' | 'today' | 'tomorrow' | 'on'; //TODO:it is not a optional
    goalDateOn: string;
    showDayCountForGoalDateAs: 'day0' | 'day1';
    dayCountDeactivate: string;
    showJustInCurve: boolean;
}

interface Therapy {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    title: string;
    details: string;
    task: boolean;
    editor: string;
    registered: boolean;
    conducted: boolean;
    goalDate: string;
    goalDateOn: string;
    showDayCountForGoalDateAs: string;
    dayCountDeactivateOn: any;
    showOnlyInCurve: boolean;
    created_at: string;
}

interface Pathogen {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    name: string;
    type: string;
    date: string;
}

interface Antibiotics {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    name: string;
    dosage: string;
    frequency: string;
    date?: string;
}

interface Infectiology {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    specialist: string;
    notes: string;
    date?: string;
}

interface Anticoagulation {
    referenceId?: string; //TODO:this one is not optional

    medication: string;
    dosage: string;
    route: string;
    frequency: string;
    date?: string;
}

interface PostTreatment {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    postTreatment: string;
}

interface Consultations {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    title: string;
    details: string;
    task: boolean;
    editor: { [key: string]: string };
    registered: boolean;
    performed: boolean;
    goalDateOn: string;
    dayCountForGoalDateShowAs: 'day0' | 'day1';
    dayCountDeactivate: string;
    dayCountDeactivateOn: string;
    showOnlyInCurve: boolean;
    goal_date?: string;
}

export interface Task {
    referenceId: string;
    task_name: string;
    details: string;
    priority: 'veryHigh' | 'high' | 'normal' | 'low' | 'veryLow';
    editor: string;
    goalDate?: 'yesterday' | 'today' | 'tomorrow' | 'on'; //TODO:it is not a optional
    goalDateOn: string;
    done: boolean;
    contractor: string;
    modifiedAt: string;
    modifiedBy: string;
    modificationAction?: TaskModificationAction;
}

export interface Discharge {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    setInCheckpad: boolean;
    dismissalExpectedOn: string | null;
    comment: string;
}

interface DRG {
    referenceId?: string; //TODO:this one is not optional

    targetDischarge: string;
    plannedDischarge: string;
    actualDischarge: string;
}

export interface PatientData {
    patientID: string;
    anamnesis: Anamnesis[];
    diagnoses: Diagnose[];
    interactionModule?: any[]; //what is this? TODO
    therapy: Therapy[];
    diagnostics: Diagnostics[];
    pathogen: Pathogen[];
    antibiotics: Antibiotics[];
    infectiology: Infectiology[];
    anticoagulation: Anticoagulation[];
    postTreatment: PostTreatment[];
    consultations: Consultations[];
    tasks: Task[];
    DRGs: DRG[];
    visitRecords:VisitRecord[];
    discharge: Discharge[];
}

export interface Diagnose {
    referenceId?: string; //TODO:this one is not optional
    modifiedAt?: string; //TODO:this one is not optional
    modifiedBy?: string; //TODO:this one is not optional
    diagnose: string;
}

export const getDummyPatientData = (): PatientData => ({
    patientID: '',
    anamnesis: [],
    diagnoses: [],
    therapy: [],
    diagnostics: [],
    pathogen: [],
    antibiotics: [],
    infectiology: [],
    anticoagulation: [],
    postTreatment: [],
    consultations: [],
    tasks: [],
    DRGs: [],
    visitRecords:[],
    discharge: [],
});

export enum FilterType {
    MedicalReport = 'medical_report',
    Laboratory = 'laboratory',
    Radiology = 'radiology',
    Histology = 'histology',
    OP = 'op',
    Consultations = 'consultations',
    All = 'all',
}

export enum PatientFileType {
    DICOM = 'dicom',
    PDF = 'pdf',
    IMAGE = 'img',
    FORM = 'form',
    BLANK = 'blank',
}

export enum CategoryType {
    General = 'Allgemein',
    Reports = 'Berichte',
    Prescriptions = 'Verordnungen',
    Barthel = 'Barthel',
    ImageDocumentation = 'Bilddokumentation',
    FootSheet = 'Fußbogen',
    Forms = 'Formulare',
    Microbiology = 'Mikrobiologie',
    CT = 'CT',
    MRT = 'MRT',
    ConventionalXRay = 'Konventionelles Röntgen',
    Aneasthesie = 'Anesthesia',
    Konsile = 'Konsile',
}

export enum OrderType {
    SortByDateASC = 'Datum aufsteigend',
    SortByDateDESC = 'Datum absteigend',
    SortByMainText = 'Haupttext',
    // Add more sorting options if needed
}

export enum VisitRecordModificationAction {
    Add = 'Hinzufügen',
    Edit = 'Bearbeiten',
    Delete = 'Löschen',
}

export enum TaskModificationAction {
    Add = 'Hinzufügen',
    Edit = 'Bearbeiten',
    Delete = 'Löschen',
}

export interface PatientFile {
    // FIXME Fix the subdate and subtext array --> object
    id: string;
    icon: string;
    mainDate: string;
    mainText: string;
    subDate: any[];
    subText: any[];
    fileName: string;
    fileType: PatientFileType;
    rawData?: any;
    formioData?: FormioRendererData[];
    formioForm?: any;
    i18n?: any;
    filePath: string | SafeResourceUrl;
    filterType: FilterType;
    categoryType: CategoryType;
}

export interface OrgaPatientData {
    stationId: string;
    stationName: string;
    patients: PatientListItem[];
}

//for orga
export interface OrgaPatient {
    id: string;
    ward: string;
    room: string;
    bed: string;
    firstName: string;
    lastName: string;
    fullname?: string;
    gender: string;
    dob: string;
    admissionDate: string;
    diagnosis: string[];
    medicalOrders?: MedicalOrders[];
    tasks: Task[];
    comments?: Comments[];
    diagnosticFindings?: DiagnosticFindings[];
    visitRecords: VisitRecord[];
    discharge: Discharge[];
}

export interface MedicalOrders {
    description: string;
    receiver: string;
    status?: string;
    createdDate: string;
}

export interface Comments {
    receiver: string;
    description: string;
}

//end for orga
