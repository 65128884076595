import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AuthzActions from '../actions/authz.action';
import { DataRepositoryService } from '../../services/datarepository.service';
import { AppState } from '../appstate.model';
import { Store } from '@ngrx/store';

/**
 * Effects for handling authorization tokens related operations.
 */
@Injectable()
export class AuthzEffects {
  private static readonly TAG = 'AuthzEffects';

  public constructor(
    private actions$: Actions,
    private repo: DataRepositoryService,
    private store: Store<AppState>
  ) {}

  /** Effect for loading authorization tokens. */
  public loadAuthzTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.loadAuthzTokens),
      switchMap(() => {
        return from(this.repo.getAllAuthzTokens()).pipe(
          map((e) => {
            return AuthzActions.loadAuthzTokensSuccess({ tokens: e });
          })
        );
      }),
      catchError((error) => {
        console.error('Error loading authz tokens', error);
        return of(AuthzActions.loadAuthzTokensFail({ error }));
      })
    )
  );

  /** Effect for adding or updating an authorization token. */
  addOrUpdateAuthzToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.addOrUpdateAuthzToken),
      withLatestFrom(this.store.select((state) => state.authz.tokens)),
      switchMap(([action, tokens]) => {
        this.repo.saveAuthzTokens(tokens).then();
        return of(
          AuthzActions.addOrUpdateAuthzTokenSuccess({ token: action.token })
        );
      }),
      catchError((error) => {
        console.error('Error in addOrUpdateAuthzToken', error);
        return of(AuthzActions.addOrUpdateAuthzTokenFail({ error }));
      })
    )
  );

  /** Effect for removing an authorization token. */
  removeAuthzToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.removeAuthzToken),
      withLatestFrom(this.store.select((state) => state.authz.tokens)),
      switchMap(([action, tokens]) => {
        this.repo.saveAuthzTokens(tokens).then();
        return of(
          AuthzActions.removeAuthzTokenSuccess({ token: action.token })
        );
      }),
      catchError((error) => {
        console.error('Error in removeAuthzToken', error);
        return of(
          AuthzActions.authzOperationFail({ error, operation: 'remove token' })
        );
      })
    )
  );
}
