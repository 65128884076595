import { setSettingsSuccess } from './../actions/setting.action';
import { createReducer, on } from '@ngrx/store';
import { DEFAULT_SETTINGS, Settings } from '../../models/settings.model';
import * as SettingActions from '../actions/setting.action';

export interface SettingsState {
    settings: Settings;
    loading: boolean;
    error: any;
}
export const initialState: SettingsState = {
    settings: DEFAULT_SETTINGS,
    loading: false,
    error: null,
};

export const settingsReducer = createReducer(
    initialState,
    on(SettingActions.setSettings, (state, { settings }) => ({
        ...state,
        settings,
        loading: true,
    })),
    on(SettingActions.setSettingsSuccess, (state, { settings }) => ({
        ...state,
        settings: settings,
        loading: false,
        error: null,
    })),
    on(SettingActions.setSettingsFail, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    on(SettingActions.loadSettingsSuccess, (state, settingsState) => ({
        ...state,
        settings: settingsState.settings,
        loading: false,
        error: null,
    })),
    on(SettingActions.loadSettingsFail, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    // on(SettingActions.toggleActiveAnonymous, (state) => ({
    //     ...state,
    //     settings: {
    //         ...state.settings,
    //         activeAnonymous: !state.settings.activeAnonymous,
    //     },
    // }))
);
