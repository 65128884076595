import { NetworkService } from './../../../services/mercure/network.service';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { DynamicListComponent } from '../../../components/data-interaction/dynamic-list/dynamic-list.component';
import { DayAgoPipe } from '../../../pipes/day-ago.pipe';
import { FormioModule } from '@formio/angular';
import { FormioRendererComponent } from '../../../components/data-interaction/formio-renderer/formio-renderer.component';

@Component({
    selector: 'app-test-sandbox',
    templateUrl: './test-sandbox.component.html',
    standalone: true,
    styleUrls: ['./test-sandbox.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        DynamicListComponent,
        DayAgoPipe,
        FormioModule,
        FormioRendererComponent,
    ],
})
export class TestSandboxComponent implements OnInit {
    private static readonly TAG = 'TestSandboxComponent';

    public constructor(private networkService: NetworkService) {}

    public async ngOnInit() {}
}
