import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DataRepositoryService } from '../../services/datarepository.service';
import { AppState } from '../appstate.model';
import { Store } from '@ngrx/store';
import * as DeviceActions from './../actions/device.action';

/**
 * Effects for handling device related operations.
 */
@Injectable()
export class DeviceEffects {
  private static readonly TAG = 'DeviceEffects';

  public constructor(
    private actions$: Actions,
    private repo: DataRepositoryService,
    private store: Store<AppState>
  ) {}

  /** Effect for loading device */
  public loadDeviceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceActions.loadDevice),
      switchMap(() => {
        return from(this.repo.getDevice()).pipe(
          map((device) => {
            return DeviceActions.loadDeviceSuccess({device});
          })
        );
      }),
      catchError((error) => {
        console.error('Error loading device', error);
        return of(DeviceActions.loadDeviceFail({ error }));
      })
    )
  );

  /** Effect for updating the device */
  updateDeviceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceActions.updateDevice),
      withLatestFrom(this.store.select((state) => state.device)),
      switchMap(([action, deviceState]) => {
        const device = deviceState.device;
        this.repo.saveDevice(device).then();

        return of(DeviceActions.updateDeviceIdSuccess({ device }));
      }),
      catchError((error) => {
        console.error('Error in updateDeviceId', error);
        return of(DeviceActions.updateDeviceIdFail({ error }));
      })
    )
  );

  /** Effect for removing an authorization token. */
  removeDeviceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceActions.removeDevice),
      withLatestFrom(this.store.select((state) => state.device)),
      switchMap(([action, deviceState]) => {
        const device = deviceState.device;
        this.repo.saveDevice(device).then();
        return of(DeviceActions.removeDeviceIdSuccess());
      }),
      catchError((error) => {
        console.error('Error in removeDeviceId', error);
        return of(
          DeviceActions.deviceOperationFail({
            error,
            operation: 'remove device id',
          })
        );
      })
    )
  );
}
