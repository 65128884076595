import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    MatPaginator,
    MatPaginatorIntl,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PatientListItem } from '../../../models/patient.model';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Cp2MatPaginatorIntl } from '../../../shared/Cp2MatPaginatorIntl';
import {
    MatChipSelectionChange,
    MatChipsModule,
} from '@angular/material/chips';
import { CalAgePipe } from '@lohmann-birkner/luic';
import { DayAgoPipe } from '../../../pipes/day-ago.pipe';

import { FormsModule } from '@angular/forms';
import { OrgaTabsMedicalOrderComponent } from '../orga-tabs-medical-order/orga-tabs-medical-order.component';
import { OrgaTabsTasksComponent } from '../orga-tabs-tasks/orga-tabs-tasks.component';
import { OrgaTabsCommentsComponent } from '../orga-tabs-comments/orga-tabs-comments.component';
import { OrgaTabsDiagFindingsComponent } from '../orga-tabs-diag-findings/orga-tabs-diag-findings.component';
import { HomePatientBasicInfoComponent } from '../../home-page/home-patient-basic-info/home-patient-basic-info.component';
import { HomePatientVisitComponent } from '../../home-page/home-patient-visit/home-patient-visit.component';
import { HomePatientTasksComponent } from '../../home-page/home-patient-tasks/home-patient-tasks.component';
import { OrgaTabsComponent } from '../orga-tabs/orga-tabs.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';

@Component({
    selector: 'app-workflow-tabs',
    templateUrl: './workflow-tabs.component.html',
    styleUrls: ['./workflow-tabs.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        TranslateModule,
        DatePipe,
        MatButtonModule,
        CommonModule,
        MatChipsModule,
        CalAgePipe,
        OrgaTabsMedicalOrderComponent,
        OrgaTabsTasksComponent,
        OrgaTabsCommentsComponent,
        DayAgoPipe,
        OrgaTabsDiagFindingsComponent,
        CommonModule,
        MatButtonToggleModule,
        FormsModule,
        HomePatientBasicInfoComponent,
        HomePatientVisitComponent,
        HomePatientTasksComponent,
        OrgaTabsComponent,
    ],
    providers: [{ provide: MatPaginatorIntl, useClass: Cp2MatPaginatorIntl }],
})
export class WorkflowTabsComponent implements OnInit, OnChanges {
    /**
     * A string value used to filter the table data. This value is bound to a text input for dynamic filtering.
     * @Input()
     * @public
     */
    @Input() filterValue: string = '';

    /**
     * An array of `PatientListItem` objects to be displayed in the table. This data is passed into the component
     * and used to populate the rows of the table. Since the workflow page and orga share the same data structure
     * so the interface PatientListItem is here used
     * @Input()
     * @public
     */
    @Input() workflowDataPatients: PatientListItem[] = [];

    /**
     * for the material chips to switch between 4 chips
     * @public
     */
    @Input() selectedToggle: string | null | undefined = 'tasks';

    /**
     * Emits the index of the selected icon when an icon in one of the table's rows is clicked.
     * This allows parent components to react to these selections.
     * @Output()
     * @public
     */

    @Input() viewContentI18n: FormioRendererI18n | undefined;

    @Output() selectedIcon: EventEmitter<number> = new EventEmitter<number>();

    // /**
    //  * The data source for the table component, containing an array of `PatientListItem` objects.
    //  * @type {MatTableDataSource<PatientListItem>}
    //  * @public
    //  */
    // dataSource = new MatTableDataSource<PatientListItem>([]);

    /**
     * Represents the total number of records available in the `dataSource`: pagination purposes.
     * @type {number}
     * @public
     */
    totalRecords: number = 0;

    /**
     * A reference to the `MatSort` component used for sorting the data in the table.
     * @ViewChild(MatSort)
     * @public
     */

    @ViewChild(MatSort) sort: MatSort | null = null;
    /**this is for after the view changes, the table can still sort */
    // @ViewChild(MatSort) set content(content: any) {
    //     this.sort = content;
    //     if (this.sort) {
    //         this.dataSource.sort = this.sort;
    //     }
    // }
    @ViewChild(MatTable) table: MatTable<any> | undefined;

    constructor(private router: Router, private cdr: ChangeDetectorRef) {}

    @Output() selectionChange: EventEmitter<MatChipSelectionChange> | undefined;

    /**
     * Initializes the component by setting the `dataSource` with `workflowData` and updating `totalRecords`.
     */
    ngOnInit(): void {
        // this.dataSource.data = this.workflowDataPatients as PatientListItem[];
        // this.totalRecords = this.dataSource.data.length;
    }

    /**
     * Handles the selection of an icon within a table row, navigating to the detailed view of a patient.
     * @param {number} tabIndex - The index of the selected tab in patient-details page,which contains 5 tabs to show all the data about one patient
     * @param {PatientListItem} patient - The patient data associated with the selected icon.
     */
    onIconSelected(tabIndex: number, patient: PatientListItem): void {
        this.router
            .navigateByUrl(`patient-details/${patient.id}/${tabIndex}`)
            .then();
    }

    /**
     * Applies filtering to the table data based on the updated input values.
     * @param {SimpleChanges} changes - An object of changes keys for the current data-bound input properties.
     */

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['workflowDataPatients'] || changes['filterValue']) {
            this.applyFilter();
        }
    }

    /**
     * Navigates to patient-detail page by double-click.
     * @param {any} row - The data object associated with the double-clicked table row.
     */
    onDoubleClick(row: any) {
        this.router.navigateByUrl(`patient-details/${row.id}`).then();
    }
    onChipSelectChange(event: MatChipSelectionChange) {
        this.selectionChange?.emit(event);
    }
    /**
     * Filters based on string matching in any object value within the `workflowData`.
     */
    applyFilter(): void {
        const filteredPatients = this.workflowDataPatients?.filter((item) =>
            Object.values(item).some((val) =>
                val.toString().toLowerCase().includes(this.filterValue)
            )
        );
        // this.dataSource.data = filteredPatients as PatientListItem[];
    }
    public onPatientSelected(patientId: string) {
        this.router.navigateByUrl(`patient-details/${patientId}`).then();
    }

    get isWorkflowRoute(): boolean {
        const currentUrl = this.router.url;
        return currentUrl.endsWith('/workflow');
    }
}
