<div
    class="patient-task-info"

>
    <app-dynamic-list
        class="w-full"
        [list]="latestTaskofTasks"
        [clipToWhenSmall]="2"
        [patientId]="patient.id"
        [showButton]="false"
        [isClipped]="isClipped"
        (click)="onClickOnElement(getPatientNameAndDob())"
    >
        <ng-template #itemTemplate let-item="item">
            <mat-list class="patient-task">
                <mat-icon
                    matListItemIcon
                    *ngIf="
                        item.priority === 'high' || item.priority === 'veryHigh'
                    "
                    class="task-open-icon"
                    >priority_high</mat-icon
                >

                <div class="patient-task-name table-text">
                    {{ item.task_name }}
                </div>
                <div
                    class="table-date patient-task-date"
                    [ngClass]="{
                        'color-danger': item.priority === 'veryHigh'
                    }"
                >
                    {{ item.contractor }};
                    {{
                        item.goalDateOn
                            | date : ("GENERAL.date_time_format" | translate)
                    }}
                </div>
            </mat-list>
        </ng-template>
    </app-dynamic-list>
    <!-- button for open a new task dialog-->
    <div class="patient-task-button">
        <button
            mat-icon-button
            (click)="openFormDialog('form_tasks.json', getPatientNameAndDob())"
        >
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button *ngIf="showButton" (click)="toggleClip()">
            <mat-icon *ngIf="isClipped"> keyboard_arrow_up </mat-icon>
            <mat-icon *ngIf="!isClipped"> keyboard_arrow_down </mat-icon>
        </button>
    </div>
</div>
