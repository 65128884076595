import { VisitRecord } from './../../../models/patient.model';
import { Component, Input, OnInit } from '@angular/core';
import {
    MatDialog,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';
import {
    getDummyPatientListItem,
    PatientListItem,
} from '../../../models/patient.model';
import { PatientNameAndDob } from '../../../pages/patient-details/patient-details.component';
import { DynamicListComponent } from '../../data-interaction/dynamic-list/dynamic-list.component';
import { CommonModule } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { StartPagePopupListComponent } from '../../../modals/start-page-visit-popup-list/start-page-popup-list.component';
import dayjs from 'dayjs';
import {FormioRendererI18n} from '../../data-interaction/formio-renderer/formio-renderer.component';

@Component({
    selector: 'app-home-patient-visit',
    templateUrl: './home-patient-visit.component.html',
    styleUrls: ['./home-patient-visit.component.scss'],
    imports: [
        DynamicListComponent,
        CommonModule,
        MatIcon,
        MatListModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatDialogTitle,
        MatDialogContent,
    ],
    standalone: true,
})
export class HomePatientVisitComponent implements OnInit {
    @Input() patient: PatientListItem = getDummyPatientListItem();
    @Input() viewContentI18n: FormioRendererI18n | undefined;
    //#region control dynamic list
    //show the button for the dynamic list,
    showButton: boolean = false;
    /**
     * A flag indicating whether the list is currently clipped (i.e., showing a reduced number of items).
     */
    isClipped = true;
    //#end region
    latestRecordOfRecords: VisitRecord[] = [];

    public constructor(public dialog: MatDialog) {
    }

    public getPatientNameAndDob(): PatientNameAndDob {
        return {
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
            dob: this.patient.dob,
            gender: this.patient.gender,
            ward: this.patient.ward,
            bed: this.patient.bed,
            room: this.patient.room,
        };
    }

    public ngOnInit() {
        this.patient.visitRecords = this.processVisitRecords(
            this.patient.visitRecords
        );
        this.sortVisitsByModifiedAt(this.patient.visitRecords);
        this.processVisits();
        if (this.latestRecordOfRecords.length > 3) {
            this.showButton = true;
        }
    }

    public async onClickOnElement(
        currentVisit: any,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogRef = this.dialog.open(StartPagePopupListComponent, {
            data: {
                currentVisit: currentVisit,
                data: this.patient.visitRecords,
                patient_info: patientInfo,
                viewContentI18n: this.viewContentI18n,
            },
            height: 'auto',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    /* open a new formular
    show the current time and date when user open a new formular
    */
    public async openFormDialog(
        formFileName: string,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo,
                form_data: {
                    data: [
                        {
                            referenceId: '', //TODO:assign a uuid later
                            note: '',
                            modifiedBy: '', //TODO:registered user
                            modifiedAt: this.getFormattedDate(new Date()),
                        },
                    ],
                },
            },
            height: 'auto',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
        // TODO: Do something with the results
    }

    public getFormattedDate(date: string | Date): string {
        if (!date) return '';

        // Convert the ISO date string to a formatted string
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }

    /**
     * Toggles the clipped state of the list, showing more or less items accordingly.
     */
    public toggleClip(): void {
        this.isClipped = !this.isClipped;
    }

    /* only show the lastest one according to the created date */
    private sortVisitsByModifiedAt(visitRecords: VisitRecord[]): void {
        if (visitRecords) {
            visitRecords.sort(
                (a, b) =>
                    new Date(b.modifiedAt).getTime() -
                    new Date(a.modifiedAt).getTime()
            );
        }
    }

    /* remove the :ss in the date data,because then the date can be shown in the form */
    private processVisitRecords(visitRecords: VisitRecord[]) {
        return visitRecords?.map((record) => ({
            ...record,
            modifiedAt: this.getFormattedDate(record.modifiedAt),
        }));
    }

    private processVisits(): void {
        this.latestRecordOfRecords = Object.values(
            this.patient.visitRecords
                .sort(
                    (a, b) =>
                        new Date(b.modifiedAt).getTime() -
                        new Date(a.modifiedAt).getTime()
                )
                .reduce((acc, record) => {
                    if (!acc[record.referenceId]) {
                        acc[record.referenceId] = record;
                    }
                    return acc;
                }, {} as { [key: string]: VisitRecord })
        );
        this.sortVisitsByModifiedAt(this.latestRecordOfRecords);
    }
}
