import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import * as SettingActions from '../actions/setting.action';
import {DataRepositoryService} from '../../services/datarepository.service';
import {AppState} from '../appstate.model';
import {Store} from '@ngrx/store';

/**
 * Effects for handling authorization tokens related operations.
 */
@Injectable()
export class SettingEffects {
    /** Effect for loading settings. */
    public loadSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingActions.loadSettingState),
            switchMap(() => {
                return from(this.repo.getSettings()).pipe(
                    map((settings) => {
                        return SettingActions.loadSettingsSuccess({settings});
                    })
                );
            }),
            catchError((error) => {
                return of(SettingActions.loadSettingsFail({error}));
            })
        )
    );
    /** Effect for adding or updating settings. */
    saveSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingActions.setSettings),
            withLatestFrom(this.store.select((state) => state.settings.settings)),
            switchMap(([action, settings]) => {
                this.repo.saveSettings(settings).then();
                return of(
                    SettingActions.setSettingsSuccess({settings: action.settings})
                );
            }),
            catchError((error) => {
                return of(SettingActions.setSettingsFail({error}));
            })
        )
    );

    public constructor(
        private actions$: Actions,
        private repo: DataRepositoryService,
        private store: Store<AppState>
    ) {
    }
}
