Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<p class="help-block ' +
((__t = (ctx.level)) == null ? '' : __t) +
'">' +
((__t = (ctx.message)) == null ? '' : __t) +
'</p>\n';
return __p
}