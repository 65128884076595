import { Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DbService } from './db.service';
import { Toast } from '@capacitor/toast';
import { APP_DB_NAME } from '../../shared/constants';

@Injectable()
export class InitializeAppDbService {
    isAppInit: boolean = false;
    platform!: string;

    public constructor(
        private sqliteService: SQLiteService,
        private storageService: DbService
    ) {}

    // Initializes the application's database
    public async initializeAppDb() {
        try {
            // Initialize the SQLite plugin
            await this.sqliteService.initializePlugin();
            this.platform = this.sqliteService.platform;

            if (this.platform === 'web') {
                // Initialize the web store for SQLite if on a web platform
                await this.sqliteService.initWebStore();
            }

            // Initialize the user database
            await this.storageService.initializeDatabase(APP_DB_NAME);

            // Additional database initialization logic can go here

            if (this.platform === 'web') {
                // Save the database state to the web store
                await this.sqliteService.saveToStore(APP_DB_NAME);
            }

            this.isAppInit = true;
        } catch (error) {
            console.error(`initializeAppError: ${error}`);
            // Show an error toast if initialization fails
            await Toast.show({
                text: `initializeAppError: ${error}`,
                duration: 'long',
            });
        }
    }
}
