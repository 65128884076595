<div class="page-container">
    <div class="content-container">
        <div class="center-container">
            <div>
                <img
                    alt=""
                    src="../../../../assets/icons/favicon_package_v0.16/android-chrome-192x192.png"
                />
            </div>
            <div class="center-content">
                <mat-progress-spinner
                    mode="indeterminate"
                ></mat-progress-spinner>
                <span class="waiting-label">
                    {{ "GENERAL.label_waiting" | translate }}
                </span>
            </div>
        </div>
    </div>
    <div class="footer">&copy; 2024 Lohmann & Birkner HCC GmbH</div>
</div>

<!--
<mat-card>
    <mat-card-header>
        <mat-card-title> Login</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div style="display: flex; flex-direction: column">
            <mat-form-field class="example-full-width">
                <mat-label>Benutzername</mat-label>
                <input
                    [(ngModel)]="username"
                    matInput
                    placeholder="Arzt1"
                />
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Password</mat-label>
                <input
                    [(ngModel)]="password"
                    matInput
                    placeholder="Mein Kennwort"
                    type="password"
                />
            </mat-form-field>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <button (click)="onClickOnLogin()" color="primary" mat-flat-button>
            Login
        </button>
    </mat-card-actions>
</mat-card>
-->
