<div class="patient-overview-container">
    <!-- First Column: Anamnesis to Therapy -->
    <div class="patient-details-container-col">
        <!-- Anamnesis -->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.anamnesis.title"
                        | translate
                }}
                @if (patientDetails.anamnesis.length===0){
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_anamnesis.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon></button
                >}@else {
                <button
                    mat-icon-button
                    (click)="
                        onClickOnElement(
                            lastModifiedAtPatientDetails.anamnesis,
                            'form_anamnesis.json',
                            patientNameAndDob,
                            patientDetailsI18n,
                            patientDetails.anamnesis
                        )
                    "
                >
                    <mat-icon>edit</mat-icon>
                </button>
                }
            </div>
            <app-dynamic-list
                [list]="lastModifiedAtPatientDetails.anamnesis"
                [clipToWhenSmall]="1"
                [showButton]="false"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_anamnesis.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.anamnesis
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.anamnesis }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Diagnosis -->
        <mat-list class="patient-overview-diagnoses">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.diagnoses.title"
                        | translate
                }}

                @if (patientDetails.diagnoses.length===0){
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_diagnoses.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon></button
                >}@else {
                <button
                    mat-icon-button
                    (click)="
                        onClickOnElement(
                            lastModifiedAtPatientDetails.diagnoses,
                            'form_diagnoses.json',
                            patientNameAndDob,
                            patientDetailsI18n,
                            patientDetails.diagnoses
                        )
                    "
                >
                    <mat-icon>edit</mat-icon>
                </button>
                }
            </div>

            <app-dynamic-list
                [list]="lastModifiedAtPatientDetails.diagnoses"
                [clipToWhenSmall]="1"
                [showButton]="false"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_diagnoses.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.diagnoses
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.diagnose }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>



        <!-- Interaction module -->
        <!-- <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.interactionModule.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_interactionModule.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div
                *ngFor="let interaction of patientDetails.interactionModule"
                class="patient-details-container-content"
            >
                <div class="patient-overview-item-title">
                    {{ interaction | json }}
                </div>
            </div>
        </mat-list> -->

        <!-- Therapy -->
        <mat-list *ngIf="lastModifiedAtPatientDetails.therapy">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.therapy.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_therapy.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list
                [list]="lastModifiedAtPatientDetails.therapy"
                [clipToWhenSmall]="1"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_therapy.json',
                                patientNameAndDob,
                                patientDetailsI18n,

                                patientDetails.therapy
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.title }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.created_at
                                    | date
                                        : ("GENERAL.date_time_format" | translate)
                            }}<span>{{
                                item.conducted
                                    ? "(D)"
                                    : item.registered
                                    ? "(A)"
                                    : null
                            }}</span>
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>
    </div>

    <!-- Second Column: Vistite Diagnostic and konsile-->
    <div class="patient-details-container-col">
               <!-- visite -->
        <mat-list *ngIf="lastModifiedAtPatientDetails.visitRecords">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.visit.title"
                        | translate
                }}

                @if (patientDetails.diagnoses.length===0){
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_visit.json.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon></button
                >}@else {
                <button
                    mat-icon-button
                    (click)="
                        onClickOnElement(
                            lastModifiedAtPatientDetails.visitRecords,
                            'form_visit.json',
                            patientNameAndDob,
                            patientDetailsI18n,
                            patientDetails.visitRecords
                        )
                    "
                >
                    <mat-icon>edit</mat-icon>
                </button>
                }
                <!-- <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_visit.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button> -->
            </div>
            <app-dynamic-list
                [list]="lastModifiedAtPatientDetails.visitRecords"
                [showButton]="true"
                [clipToWhenSmall]="2"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_visit.json',
                                patientNameAndDob,
                                patientDetailsI18n,

                                patientDetails.visitRecords
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.note }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>
        <!-- Diagnostic -->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.diagnostics.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_diagnostics.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list [list]="lastModifiedAtPatientDetails.diagnostics">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_diagnostics.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.diagnostics
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.title }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div></ng-template
                >
            </app-dynamic-list>
        </mat-list>
 <!-- Consultations (Konsile) -->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.consultations.title"
                        | translate
                            : { count: patientDetails.consultations.length }
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_consultations.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list
                [list]="lastModifiedAtPatientDetails.consultations"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_consultations.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.consultations
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.title }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.goalDateOn
                                    | date : ("GENERAL.date_format" | translate)
                            }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{ item.details }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>
        <!-- Pathogen -->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.pathogen.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_pathogen.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list [list]="lastModifiedAtPatientDetails.pathogen">
                <ng-template #itemTemplate let-item="item">
                    <div
                        (click)="
                            onClickOnElement(
                                item,
                                'form_pathogen.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.pathogen
                            )
                        "
                        class="patient-details-container-content"
                    >
                        <div class="patient-overview-item-title">
                            {{ item.name }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.pathogen.label_type"
                                    | translate : { type: item.type }
                            }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Antibiotics -->
        <!-- TODO -->

        <!-- Infectiology -->
        <!-- TODO -->

        <!-- Anticoagulation -->
        <!-- TODO -->


    </div>

    <!-- Third Column: Discharge to Tasks -->
    <div class="patient-details-container-col">
  <!-- Tasks -->
        <mat-list class="third-column-task">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.tasks.title"
                        | translate : { count: patientDetails.tasks.length }
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_tasks.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list [list]="lastModifiedAtPatientDetails.tasks">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_tasks.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.tasks
                            )
                        "
                    >
                        <mat-icon
                            matListItemIcon
                            *ngIf="
                                item.priority === 'high' ||
                                item.priority === 'veryHigh'
                            "
                            class="task-open-icon"
                            >priority_high</mat-icon
                        >

                        <div class="patient-overview-item-title">
                            {{ item.task_name }}
                        </div>
                        <span
                            [ngClass]="{
                                'color-danger': item.priority === 'veryHigh'
                            }"
                            matListItemLine
                            >{{ item.contractor }}
                        </span>
                        <div class="patient-overview-item-line">
                            {{
                                item.modifiedAt
                                    | date : ("GENERAL.date_time_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Discharge -->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.discharge.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_discharge.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list [list]="lastModifiedAtPatientDetails.discharge">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item,
                                'form_discharge.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                patientDetails.discharge
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.comment }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.dismissalExpectedOn
                                    | date : ("GENERAL.date_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list> <!-- Post treatment TODO-->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.postTreatment.title"
                        | translate
                            : { count: patientDetails.postTreatment.length }
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_postTreatment.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list
                [list]="lastModifiedAtPatientDetails.postTreatment"
            >
                <ng-template #itemTemplate let-item="item">
                    <div class="patient-details-container-content">
                        <div class="patient-overview-item-title">
                            {{ item.postTreatment }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>
    </div>

    <!-- Fourth Column: DRG and Discharge -->
    <div class="patient-details-container-col">

        <!-- Doctors -->
        <!-- TODO -->

        <!-- DRG -->
        <!-- <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                DRG
                <button mat-icon-button (click)="openDialog()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div
                class="patient-details-container-content"
                *ngFor="let drg of patientDetails.DRGs"
                (click)="onClickOnElement(drg, 'form_drg.json')"
            >
                <div class="patient-overview-item-title">
                    Target Discharge: {{ drg.targetDischarge }}
                </div>
                <div class="patient-overview-item-line">
                    Actual Discharge: {{ drg.actualDischarge }}
                </div>
                <div class="patient-overview-item-line">
                    Plan vs. Ziel: {{ differenceInDays }}
                </div>
            </div>
        </mat-list> -->
    </div>
</div>
