import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RecordsState} from '../reducers/records.reducer';

export const selectRecordsFeature = createFeatureSelector<RecordsState>('records');

export const selectAllRecords = createSelector(
    selectRecordsFeature,
    (state: RecordsState) => state.records
);

export const selectSelectedRecord = createSelector(
    selectRecordsFeature,
    (state: RecordsState) => state.selectedRecord
);

export const selectRecordsError = createSelector(
    selectRecordsFeature,
    (state: RecordsState) => state.error
);

export const selectLoading = createSelector(
    selectRecordsFeature,
    (state: RecordsState) => state.loading
);
