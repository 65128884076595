import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DbnameVersionService } from './dbname-version.service';
import { MigrationUpgradeStatements } from './migrations.statement';

@Injectable()
export class DbService {
    private static readonly TAG = 'DbService';

    private databaseName: string = '';
    private migrationsStatements = new MigrationUpgradeStatements();
    private upgradeMigrations;
    private loadToVersion;
    private db!: SQLiteDBConnection;

    public constructor(
        private sqliteService: SQLiteService,
        private dbVerService: DbnameVersionService
    ) {
        this.upgradeMigrations = this.migrationsStatements.upgradeMigrations;
        this.loadToVersion =
            this.upgradeMigrations[this.upgradeMigrations.length - 1].toVersion;
    }

    public async initializeDatabase(dbName: string) {
        this.databaseName = dbName;
        // create upgrade statements
        await this.sqliteService.addUpgradeStatement({
            database: this.databaseName,
            upgrade: this.upgradeMigrations,
        });
        // create and/or open the database
        this.db = await this.sqliteService.openDatabase(
            this.databaseName,
            false,
            'no-encryption',
            this.loadToVersion,
            false
        );
        this.dbVerService.set(this.databaseName, this.loadToVersion);
    }

    //#region ViewContent CRUD functions
    public async createViewContent(
        locator: string,
        content: string,
        scopes: string
    ): Promise<any> {
        const sql = `INSERT INTO view_content (locator,content,scopes) VALUES (?,?,?);`;
        const values = [locator, content, scopes];

        await this.db.run(sql, values);
    }

    public async getViewContentById(id: number): Promise<any> {
        const sql = `SELECT * FROM view_content WHERE id = ?;`;
        return await this.db.query(sql, [id]);
    }

    public async getAllViewContent(): Promise<any> {
        const sql = `SELECT * FROM view_content;`;
        return await this.db.query(sql);
    }

    public async updateViewContent(
        id: number,
        locator: string,
        content: string,
        scopes: string
    ): Promise<any> {
        const sql = `UPDATE view_content SET locator = ?, content = ?, scopes = ? WHERE id = ?;`;
        const values = [locator, content, scopes, id];
        await this.db.run(sql, values);
    }

    public async deleteViewContent(id: number): Promise<any> {
        const sql = `DELETE FROM view_content WHERE id = ?;`;
        await this.db.run(sql, [id]);
    }

    //#endregion
}
