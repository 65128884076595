Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<i ref="' +
((__t = (ctx.ref)) == null ? '' : __t) +
'" class="' +
((__t = (ctx.className)) == null ? '' : __t) +
'" style="' +
((__t = (ctx.styles)) == null ? '' : __t) +
'">' +
((__t = (ctx.content)) == null ? '' : __t) +
'</i>\n';
return __p
}