import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { navListAnimation } from '../../../shared/animations';
import { FormsModule } from '@angular/forms';

export type SidebarButton = {
    name: SidebarButtonName;
    label: string;
    link?: string;
    svg?: string;
    icon_name?: string;
};

export type SidebarButtonName =
    | 'back'
    | 'toggle_menu'
    | 'tasks'
    | 'my_lists'
    | 'global_search'
    | 'refresh_access'
    | 'message'
    | 'settings'
    | 'divider'
    | 'admin'
    | 'chat'
    | 'logout';

const slideInOut = trigger('slideInOut', [
    state('true', style({ width: '250px' })),
    state('false', style({ width: '64px' })),
    transition('false => true', animate('200ms ease-in')),
    transition('true => false', animate('200ms ease-out')),
]);

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        MatIconModule,
        MatListModule,
        MatSidenavModule,
        MatButtonModule,
        MatToolbarModule,
        MatMenuModule,
        CommonModule,
        RouterModule,
        TranslateModule,
        MatCardModule,
        MatTooltipModule,
        FormsModule,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [slideInOut, navListAnimation],
})
export class SidebarComponent {
    /**
     * Emits when a list item (a button) of the sidebar is clicked.
     */
    @Output() clickSidebarButton = new EventEmitter<SidebarButton>();
    @Input() userData: { name: string; photoLink: string } = {
        name: 'Dr. Saki Lobinger',
        photoLink: '../assets/img/placeholder.jpg',
    };
    @Input() currentHandyOrientation: string = '';

    @Input() topButtons: SidebarButton[] = [
        {
            name: 'tasks',
            label: 'COMPONENT.SIDEBAR.tasks',
            link: '/tasks',
            // svg: '../../../assets/icons/task_FILL0_wght400_GRAD0_opsz24.svg',
            icon_name: 'task',
        },
        {
            name: 'my_lists',
            label: 'COMPONENT.SIDEBAR.my_lists',
            link: '/my-lists',
            // svg: '../../../assets/icons/clinical_notes_FILL0_wght400_GRAD0_opsz24.svg',
            icon_name: 'list',
        },
        {
            name: 'chat',
            label: 'COMPONENT.SIDEBAR.chat',
            link: '/chat',
            icon_name: 'chat',
        },
    ];
    @Input() bottomButtons: SidebarButton[] = [
        // {
        //     name: 'admin',
        //     label: 'COMPONENT.SIDEBAR.admin',
        //     link: '/admin',
        //     svg: '../../../assets/icons/construct-outline.svg',
        // },
        {
            name: 'settings',
            label: 'COMPONENT.SIDEBAR.settings',
            link: '/settings',
            icon_name: 'settings',
            // svg: '../../../assets/icons/settings_FILL0_wght400_GRAD0_opsz24.svg',
        },
        {
            name: 'logout',
            label: 'COMPONENT.SIDEBAR.logout',
            link: '/logout',
            // svg: '../../../assets/icons/logout_FILL0_wght400_GRAD0_opsz24.svg',
            icon_name: 'logout',
        },
    ];

    /**
     * Flag indicating whether the sidebar is expanded or collapsed.
     */
    @Input() HandsetPortrait: boolean = false;
    @Input() isExpanded: boolean = false;

    public toggleButton: SidebarButton = {
        name: 'toggle_menu',
        label: 'COMPONENT.SIDEBAR.menu_minimize',
        icon_name: 'keyboard_double_arrow_right',
        // svg: '../../../assets/icons/keyboard_double_arrow_left_FILL0_wght400_GRAD0_opsz24.svg',
    };

    /**
     * Handles click events on sidebar buttons.
     * @param item - The sidebar button that was clicked.
     */
    onClickOnItem(item: SidebarButton): void {
        this.clickSidebarButton.emit(item);
    }

    /**
     * Generates initials from a given name.
     * @param name - The name to generate initials from.
     * @returns A string of initials.
     */
    getInitials(name: string): string {
        return name
            ? name
                  .split(' ')
                  .map((word) => word[0])
                  .join('')
                  .toUpperCase()
            : '';
    }
}
