import {Injectable} from "@angular/core";
import {HashLocationStrategy} from "@angular/common";

@Injectable()
export class ParameterPathLocationStrategy extends HashLocationStrategy {
    override prepareExternalUrl(internal: string): string {
        const qp = window.location.search;
        if (internal === "/login/redirect") {
            return internal + qp;
        }
        return "#" + internal;
    }
}
