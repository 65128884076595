import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {from, of} from "rxjs";
import {catchError, map, switchMap, withLatestFrom} from "rxjs/operators";
import * as AccessTokenActions from "../actions/access.action";
import {DataRepositoryService} from "../../services/datarepository.service";
import {AppState} from "../appstate.model";
import {Store} from "@ngrx/store";

@Injectable()
export class AccessEffects {

  constructor(
    private actions$: Actions,
    private repo: DataRepositoryService,
    private store: Store<AppState>
  ) {}

  /** Effect to load the token from local storage */
  loadUserTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessTokenActions.loadUserToken),
      switchMap(() => {
        return from(this.repo.getAccessToken()).pipe(
          map(token => {
            return token ?
            AccessTokenActions.loadUserTokenSuccess({token})
            : AccessTokenActions.loadUserTokenFail({error: 'No token found'})
          }),
          catchError(error => of(AccessTokenActions.loadUserTokenFail({ error })))
        );
      })
    )
  );

  /** Effect to store (create or update) token */
  setUserToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessTokenActions.setUserToken),
      withLatestFrom(this.store.select(state => state.access.token)),
      switchMap(([action, token]) => {
        this.repo.setAccessToken(token).then();
        return of(AccessTokenActions.setUserTokenSuccess({ token: action.token }));
      }),
      catchError(error => of(AccessTokenActions.setUserTokenFail({ error })))
    )
  );

  /** Effect to remove access token */
  removeUserToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessTokenActions.removeUserToken),
      withLatestFrom(this.store.select(state => state.access.token)),
      switchMap(() => {
        this.repo.removeAccessToken().then();
        return of(AccessTokenActions.removeUserTokenSuccess());
      }),
      catchError(error => of(AccessTokenActions.accessOperationFail({ error, operation: 'remove token' })))
    )
  );

  /** Effect set the property access_token in the user token */
  setAccessToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessTokenActions.setAccessToken),
      withLatestFrom(this.store.select(state => state.access.token)),
      switchMap(([action, token]) => {
        this.repo.setAccessToken(token).then();
        return of(AccessTokenActions.accessOperationSuccess({ payload: token, operation: 'set access token' }));
      }),
      catchError(error => of(AccessTokenActions.accessOperationFail({ error, operation: 'set access token' })))
    )
  );
}
