import { Component, OnInit, ViewChild } from '@angular/core';
import { LuicModule, ViewContent } from '@lohmann-birkner/luic';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { PatientListItem } from '../../models/patient.model';
import { TranslateModule } from '@ngx-translate/core';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { UpcommingPageComponent } from '../base/upcomming-page/upcomming-page.component';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import { NetworkStatusComponent } from '../../components/utility/network-status/network-status.component';
import { ServerStatus } from '@lohmann-birkner/luic/lib/models/server.model';
import { v4 as uuidv4 } from 'uuid';
import { interval, Subscription, takeWhile } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { Settings } from '../../models/settings.model';
import localeDe from '@angular/common/locales/de';
import { HomePatientBasicInfoComponent } from '../../components/home-page/home-patient-basic-info/home-patient-basic-info.component';
import { HomePatientTasksComponent } from '../../components/home-page/home-patient-tasks/home-patient-tasks.component';
import { MatList, MatListItem } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { HomePatientVisitComponent } from '../../components/home-page/home-patient-visit/home-patient-visit.component';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableModule } from '@angular/material/table';
import {
    MatButtonToggleChange,
    MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatExpansionModule } from '@angular/material/expansion';

import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { SortDirection } from '@angular/material/sort';
import {
    SortOption,
    SortSelectComponent,
    SortSelection,
} from '../../components/utility/sort-select/sort-select.component';
import { SortIndicatorComponent } from '../../components/utility/sort-indicator/sort-indicator.component';
import { MatTabsModule } from '@angular/material/tabs';
import { LayoutModule } from '@angular/cdk/layout';

registerLocaleData(localeDe);

@Component({
    selector: 'cp2-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    providers: [DatePipe],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ height: '0px', minHeight: '0', display: 'none' })
            ),
            state('expanded', style({ height: '*', display: 'block' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ height: '0px', minHeight: '0', display: 'none' })
            ),
            state('expanded', style({ height: '*', display: 'block' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
    imports: [
        LuicModule,
        MatCardModule,
        MatTabsModule,
        MatIconModule,
        MatTableModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatList,
        MatListItem,
        MatPaginatorModule,
        CommonModule,
        MatDividerModule,
        TranslateModule,
        UpcommingPageComponent,
        NetworkStatusComponent,
        MatSelectModule,
        MatButtonModule,
        HomePatientBasicInfoComponent,
        HomePatientTasksComponent,
        MatList,
        MatListItem,
        MatGridListModule,
        HomePatientVisitComponent,
        MatButtonToggleModule,
        MatExpansionModule,
        SortIndicatorComponent,
        SortSelectComponent,
    ],
})
export class HomeComponent implements OnInit {
    public allSubs: Subscription[] = [];
    /**translation data for the incoming data */
    public viewContentI18n: FormioRendererI18n | undefined;

    public currentToggleButton: string = 'patientList';
    public selectedList: string = 'List1';

    //#region Dashboard variables
    public viewContent$: any | undefined;
    public dashboardInfo: ViewContent | undefined;
    public servers: ServerStatus[] = [];
    public initPings: boolean = false;
    //#endregion
    panelOpenState = false;
    //#region sort variables
    public sortKey: string = 'lastName';
    public sortOrder: SortDirection = 'asc';
    public searchQuery: string = '';
    public sortOptions: SortOption[] = [
        { value: 'lastName', translateKey: 'COMPONENT.PAGE_WORKFLOW.lastName' },
        {
            value: 'admissionDate',
            translateKey: 'COMPONENT.PAGE_WORKFLOW.admissionDate',
        },
        { value: 'ward', translateKey: 'COMPONENT.PAGE_WORKFLOW.ward' },
    ];
    //#endregion

    //#region for data in Handy
    public patients: PatientListItem[] = [];
    public filteredPatients: PatientListItem[] = [];
    public patientListToShow: PatientListItem[] = [];
    public patientListPage = 0;
    public patientListPageSize = 10;
    @ViewChild(MatTable) table: MatTable<any> | undefined;
    public displayedColumns = ['patient', 'followUp', 'tasks'];

    //# region for mobile version
    public handyDisplayedColumns = ['patients'];
    columnsToDisplayWithExpand = [...this.handyDisplayedColumns, 'expand'];
    expandedElement: any;
    HandsetPortrait: boolean = false;
    HandsetLandscape: boolean = false;
    // Create a map to display breakpoint names for demonstration purposes.
    displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
    ]);
    currentHandyOrientation: string | false = '';
    public constructor(
        private api: Cp2ApiService,
        private breakpointObserver: BreakpointObserver,
        private settingsFacade: SettingsFacadeService
    ) {
        this.settingsFacade.loadSettings();
    }

    //# region end

    toggleRow(element: any) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
    }

    public async ngOnInit(): Promise<void> {
        const sub: Subscription = this.settingsFacade.settings$.subscribe(
            async (settings: Settings) => await this.refresh()
        );
        this.allSubs.push(sub);

        try {
            this.dashboardInfo = await this.api.getDashboardInfo();
        } catch (err) {
            console.error('Failed to load dashboard information:', err);
        }

        this.breakpointObserver
            .observe([
                Breakpoints.HandsetPortrait,
                Breakpoints.HandsetLandscape,
                Breakpoints.Web,
                Breakpoints.Tablet
            ])
            .subscribe((result) => {
                console.log(result);
                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        this.currentHandyOrientation =
                            this.displayNameMap.get(query) ?? '';
                        console.log(this.currentHandyOrientation);
                    }
                }
            });
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((sub) => sub.unsubscribe());
    }

    //#region Listeners
    public async onPatientPagerChange($event: PageEvent): Promise<void> {
        this.patientListPage = $event.pageIndex;
        this.patientListPageSize = $event.pageSize;
        this.searchSortAndPaginate();
    }

    public async onClickOnToggleButton($event: MatButtonToggleChange) {
        this.currentToggleButton = $event.value;

        if (this.currentToggleButton === 'dashboard' && this.dashboardInfo) {
            const serverStatusData = this.dashboardInfo['serverStatus']?.data;
            if (serverStatusData && serverStatusData.length > 0) {
                serverStatusData.forEach((s: ServerStatus) => {
                    // Initialize chart object if it's not initialized yet
                    if (!s.chart) {
                        s.chart = {
                            chartUUID: 'chart-' + uuidv4(),
                            chartItem: undefined,
                        };
                    }

                    // Ensure chartItem is initialized to undefined if it's not already set
                    if (s.chart.chartItem) {
                        s.chart.chartItem = undefined;
                    }
                });

                // Update the servers property
                this.servers = serverStatusData;

                // Start the ping and update at intervals
                await this.triggerPingAndUpdate('http://');
                const pingIntervalSub = interval(5000)
                    .pipe(takeWhile(() => !!this.dashboardInfo))
                    .subscribe(() => this.triggerPingAndUpdate('http://'));

                // Add the subscription to the list of all subscriptions
                this.allSubs.push(pingIntervalSub);
            }
        } else if (this.currentToggleButton === 'patientList') {
            this.allSubs.forEach((sub) => sub.unsubscribe());
            this.allSubs = [];
        }
    }

    public onSortChange(direction: SortSelection): void {
        this.sortKey = direction.value;
        this.sortOrder = direction.sortOrder;
        this.searchSortAndPaginate();
    }

    public onSearchFieldInput(): void {
        this.searchSortAndPaginate();
    }

    //#endregion

    //#region Functions for sort
    private sortList(patients: PatientListItem[]): PatientListItem[] {
        return patients?.sort((a, b) => {
            let compare = 0;

            switch (this.sortKey) {
                case 'lastName':
                    compare =
                        a.lastName.localeCompare(b.lastName) ||
                        a.firstName.localeCompare(b.firstName);
                    break;
                case 'admissionDate':
                    compare =
                        new Date(a.admissionDate).getTime() -
                        new Date(b.admissionDate).getTime();
                    break;
                case 'ward':
                    compare = a.ward.localeCompare(b.ward);
                    break;
                case 'bed':
                    compare = a.bed
                        .split(' ')[1]
                        .toLowerCase()
                        .localeCompare(b.bed.split(' ')[1].toLowerCase());

                    break;
                default:
                    break;
            }

            return this.sortOrder === 'asc' ? compare : -compare;
        });
    }

    private matchesPatient(patient: PatientListItem, query: string): boolean {
        // Check simple string properties
        const matchesSimpleFields = [
            patient.ward,
            patient.room,
            patient.bed,
            patient.firstName,
            patient.lastName,
            patient.gender,
            patient.dob,
            patient.admissionDate,
        ].some((field) => field.toLowerCase().includes(query));

        // Check array of strings (diagnosis)
        const matchesDiagnosis = patient.diagnosis.some((diag) =>
            diag.toLowerCase().includes(query)
        );

        // Check nested DiagnosticFindings array
        const matchesDiagnosticFindings =
            patient.diagnosticFindings?.some(
                (finding) =>
                    finding.diagnosticFindingsName
                        .toLowerCase()
                        .includes(query) ||
                    finding.catalog.toLowerCase().includes(query)
            ) ?? false;

        // Check nested Tasks array
        const matchesTasks = patient.tasks.some(
            (task) =>
                task.task_name.toLowerCase().includes(query) ||
                task.details.toLowerCase().includes(query) ||
                task.priority.toLowerCase().includes(query) ||
                task.editor.toLowerCase().includes(query) ||
                task.goalDateOn.toLowerCase().includes(query) ||
                task.contractor.toLowerCase().includes(query)
        );

        // Check nested Visits array
        const matchesVisits = patient.visitRecords.some((visitRecord) =>
            visitRecord.note.toLowerCase().includes(query)
        );

        // Check Discharge information
        const matchesDischarge =
            patient.discharge?.some(
                (discharge) =>
                    discharge.comment.toLowerCase().includes(query) ||
                    (discharge.dismissalExpectedOn
                        ? discharge.dismissalExpectedOn
                              .toLowerCase()
                              .includes(query)
                        : false)
            ) ?? false;

        return (
            matchesSimpleFields ||
            matchesDiagnosis ||
            matchesDiagnosticFindings ||
            matchesTasks ||
            matchesVisits ||
            matchesDischarge
        );
    }

    //#endregion

    /**
     * Triggers a ping to all servers and updates their chart data.
     * @param protocol The protocol to use for the ping (either "https://" or "http://").
     * @returns A Promise that resolves when all servers are pinged and updated.
     */
    private async triggerPingAndUpdate(
        protocol: 'https://' | 'http://'
    ): Promise<void> {
        const updates = this.servers.map((server) =>
            this.pingServerAndUpdate(server, protocol)
        );
        const updatedServers = await Promise.all(updates);

        if (!this.initPings) {
            this.initPings = true;
        }

        this.servers = updatedServers;

        if (this.dashboardInfo) {
            this.dashboardInfo = {
                ...this.dashboardInfo,
                serverStatus: {
                    ...this.dashboardInfo.serverStatus,
                    data: this.servers,
                },
            };
        }
    }

    /**
     * Returns true if response.status is between 200 and 299, false otherwise
     */
    private isResponseSuccessful(response: HttpResponse): boolean {
        return response.status >= 200 && response.status < 300;
    }

    /**
     * Pings a server and updates its status.
     * @param server The server to ping.
     * @param protocol The protocol to use for the ping (either "https://" or "http://").
     * @returns The updated server status after the ping.
     */
    private async pingServerAndUpdate(
        server: ServerStatus,
        protocol: 'https://' | 'http://'
    ): Promise<ServerStatus> {
        const startTime: number = Date.now();
        let isServerActive: boolean = false;

        try {
            const response = await CapacitorHttp.get({
                url: protocol + server.ip,
            });
            isServerActive = this.isResponseSuccessful(response);
        } catch (e) {
            console.error(e);
        } finally {
            const ms: number = startTime ? Date.now() - startTime : 0;
            server.active = isServerActive;
            server.lastPingMs = ms;
        }

        return server;
    }

    private async refresh(): Promise<void> {
        this.patients = await this.api.getPatients().data;
        this.viewContentI18n = await this.api.getPatients().i18n;
        this.searchSortAndPaginate();
    }

    private searchSortAndPaginate(): void {
        this.filteredPatients = !this.searchQuery.trim()
            ? this.patients
            : this.patients.filter((patient) =>
                  this.matchesPatient(patient, this.searchQuery.toLowerCase())
              );

        const sortedPatients = this.sortList(this.filteredPatients);

        // JG: Pagination deactivated on 3.06.2024
        // this.patientListToShow = sortedPatients.slice(
        //     this.patientListPageSize * this.patientListPage,
        //     this.patientListPageSize * (this.patientListPage + 1)
        // );
        this.patientListToShow = sortedPatients;
        this.table?.renderRows(); //trigger the update of the table
    }
}
