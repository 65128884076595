import { createAction, props } from '@ngrx/store';
import { Device } from '../../models/auth.model';

/**
 * Actions for managing device state in the NgRx store.
 */

/** Action to trigger the loading of deviceId. */
export const loadDevice = createAction('[Device] Load device');

/** Action dispatched when device id are successfully loaded. */
export const loadDeviceSuccess = createAction(
  '[Device] Load device Success',
  props<{ device: Device }>()
);

/** Action dispatched when there is an error loading device id. */
export const loadDeviceFail = createAction(
  '[Device] Load device Fail',
  props<{ error: any }>()
);

/** Action to update the device id. */
export const updateDevice = createAction(
  '[Device] Update device',
  props<{ device: Device }>()
);

export const updateDeviceIdSuccess = createAction(
  '[Device] Update device Success',
  props<{ device: Device }>()
);

export const updateDeviceIdFail = createAction(
  '[Device] Update deviceId Fail',
  props<{ error: any }>()
);

export const removeDevice = createAction('[Device] Remove deviceId');

export const removeDeviceIdSuccess = createAction('[Device] Remove Success');

/** Action for a generic error in some device action */
export const deviceOperationFail = createAction(
  '[Device] Operation Fail',
  props<{ error: any; operation: string }>()
);
