import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as AreaSelectors from '../../store/selectors/areas.selector';
import * as AreaActions from '../../store/actions/areas.action';
import {AppState} from '../../store/appstate.model';
import {Area, SubArea} from "../../components/patient-info/patient-files/patient-files.component";

@Injectable({
    providedIn: 'root',
})
export class AreaFacadeService {
    public areas$ = this.store.select(AreaSelectors.selectAllAreas);
    public subAreas$ = this.store.select(AreaSelectors.selectAllSubAreas);
    public areaError$ = this.store.select(AreaSelectors.selectAreaError);

    public constructor(private store: Store<AppState>) {
    }

    public loadAreas(): void {
        this.store.dispatch(AreaActions.loadAreas());
    }

    public addArea(area: Area): void {
        this.store.dispatch(AreaActions.addArea({area}));
    }

    public updateArea(area: Area): void {
        this.store.dispatch(AreaActions.updateArea({area}));
    }

    public removeArea(areaId: string): void {
        this.store.dispatch(AreaActions.removeArea({areaId}));
    }

    public loadSubAreas(): void {
        this.store.dispatch(AreaActions.loadSubAreas());
    }

    public addSubArea(subArea: SubArea): void {
        this.store.dispatch(AreaActions.addSubArea({subArea}));
    }

    public updateSubArea(subArea: SubArea): void {
        this.store.dispatch(AreaActions.updateSubArea({subArea}));
    }

    public removeSubArea(subAreaId: string): void {
        this.store.dispatch(AreaActions.removeSubArea({subAreaId}));
    }
}
