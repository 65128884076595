import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calAge',
  standalone: true
})
export class CalAgePipe implements PipeTransform {


    /**
     * Transforms a birthdate string to an age number.
     *
     * @param value - The birthdate as a string or Date object. Expected to be in a format compatible with the JavaScript Date constructor.
     * @param args - Additional arguments (unused, can be provided for future flexibility).
     * @returns The age calculated from the birthdate to the current date.
     */
    transform(value?: any, ...args: any[]): any {
        if (!value) return value;

        const birthDate = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

}
