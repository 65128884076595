import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    selectedColorSchema: string = '';
    private _theme = new BehaviorSubject('light');
    private _colorScheme = new BehaviorSubject('');
    constructor() {
    }
    theme$ = this._theme.asObservable();
    colorScheme$ = this._colorScheme.asObservable();


    changeTheme(theme: string) {
        this._theme.next(theme);
    }
    changeColorScheme(colorScheme: string) {
        this._colorScheme.next(colorScheme);
    }
}
