import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent} from "@angular/material/dialog";
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {JsonPipe, NgIf} from "@angular/common";
import {PdfViewerComponent} from "../../components/utility/pdf-viewer/pdf-viewer.component";

@Component({
    selector: 'app-fullscreen-dialog',
    templateUrl: './fullscreen-dialog.component.html',
    styleUrls: ['./fullscreen-dialog.component.scss'],
    imports: [
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        MatIconButton,
        MatIcon,
        MatMiniFabButton,
        JsonPipe,
        NgIf,
        PdfViewerComponent
    ],
    standalone: true
})
export class FullscreenDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { data: any, fullScreen: boolean }) {
        console.log(this.data)
    }

    ngOnInit() {
    }

}
