Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<div class="' +
((__t = (ctx.classes)) == null ? '' : __t) +
' ui form" ref="webform" novalidate>' +
((__t = (ctx.children)) == null ? '' : __t) +
'</div>\n';
return __p
}