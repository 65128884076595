<mat-sidenav-container [@slideInOut]="isExpanded.toString()" autosize>
    <!-- Sidenav container -->
    <mat-sidenav #sidenav mode="side" opened="isExpanded">
        <!-- navigation list with dynamic styling based on expansion state -->
        <mat-nav-list
            [@navListAnimation]="isExpanded ? 'expanded' : 'collapsed'"
            [ngStyle]="{
                'justify-content':
                    currentHandyOrientation === 'handsetPortrait' ||
                    currentHandyOrientation === 'handsetLandscape'
                        ? 'flex-start'
                        : 'justify-content'
            }"
        >
            <!-- Upper section of the sidebar -->
            <div class="sidebar-up">
                <!-- User information card -->
                <mat-card class="sidebar-up-name-wrapper">
                    <div class="avatar-wrapper">
                        <!-- User avatar with dynamic background image -->
                        <div
                            [style.background-image]="
                                'url(' + userData.photoLink + ')'
                            "
                            class="avatar"
                        >
                            {{ getInitials(userData.name) }}
                        </div>
                    </div>
                    <p
                        #tooltip="matTooltip"
                        [matTooltipPosition]="'below'"
                        class="sidebar-up-name"
                        matTooltip="{{ userData.name }}"
                    >
                        {{ userData.name }}
                    </p>
                </mat-card>

                <!-- Expand/Collapse toggle button -->
                <mat-list-item
                    *ngIf="
                        currentHandyOrientation !== 'handsetPortrait' &&
                        currentHandyOrientation !== 'handsetLandscape'
                    "
                    (click)="isExpanded = !isExpanded"
                    [ngStyle]="{
                        textAlign: !isExpanded ? 'center' : 'unset',
                        width: !isExpanded
                            ? 'calc(100% + 1rem)'
                            : 'calc(100% + 2rem)',
                        marginLeft: !isExpanded ? '-.5rem' : '-1rem',
                        paddingLeft: !isExpanded ? '0' : '1rem'
                    }"
                >
                    <mat-icon
                        [ngClass]="{ rotated: !isExpanded }"
                        aria-label="toggle_menu"
                        class="toggle_menu"
                        mat-list-icon
                    >
                        {{ toggleButton.icon_name }}
                    </mat-icon>
                    <span
                        *ngIf="isExpanded"
                        [ngStyle]="{
                            marginLeft: !isExpanded ? '0' : '.33rem'
                        }"
                        class="menu-item"
                        translate="{{ toggleButton.label | translate }}"
                    ></span>
                </mat-list-item>
                <!-- List of top buttons -->
                <mat-list-item
                    (click)="onClickOnItem(button)"
                    *ngFor="let button of topButtons"
                    [ngStyle]="{
                        textAlign: !isExpanded ? 'center' : 'unset',
                        width: !isExpanded
                            ? 'calc(100% + 1rem)'
                            : 'calc(100% + 2rem)',
                        marginLeft: !isExpanded ? '-.5rem' : '-1rem',
                        paddingLeft: !isExpanded ? '0' : '1rem'
                    }"
                >
                    <mat-icon
                        [attr.aria-label]="button.name"
                        [ngClass]="{ rotated: !isExpanded }"
                        class="{{ button.name }}"
                        mat-list-icon
                    >
                        <img
                            *ngIf="!button.icon_name"
                            [src]="button.svg"
                            alt="{{ button.name }}"
                        />
                        {{ button.svg ? "" : button.icon_name }}
                    </mat-icon>
                    <span
                        *ngIf="isExpanded"
                        [ngStyle]="{
                            marginLeft: !isExpanded ? '0' : '.33rem'
                        }"
                        class="menu-item"
                        translate="{{ button.label | translate }}"
                    >
                        {{ button.label | translate }}
                    </span>
                </mat-list-item>
            </div>

            <!-- Bottom section of the sidebar -->
            <div class="sidebar-down">
                <!-- List of bottom buttons -->
                <mat-list-item
                    (click)="onClickOnItem(item)"
                    *ngFor="let item of bottomButtons"
                    [ngStyle]="{
                        textAlign: !isExpanded ? 'center' : 'unset',
                        width: !isExpanded
                            ? 'calc(100% + 1rem)'
                            : 'calc(100% + 2rem)',
                        marginLeft: !isExpanded ? '-.5rem' : '-1rem',
                        paddingLeft: !isExpanded ? '0' : '1rem'
                    }"
                >
                    <mat-icon [attr.aria-label]="item.name" mat-list-icon>
                        <img
                            *ngIf="!item.icon_name"
                            [src]="item.svg"
                            alt="{{ item.name }}"
                        />
                        {{ item.svg ? "" : item.icon_name }}
                    </mat-icon>
                    <span
                        *ngIf="isExpanded"
                        [ngStyle]="{
                            marginLeft: !isExpanded ? '0' : '.33rem'
                        }"
                        class="menu-item"
                        >{{ item.label | translate }}</span
                    >
                </mat-list-item>
            </div>
        </mat-nav-list>
    </mat-sidenav>
</mat-sidenav-container>
