import { Component, Input, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { SortDirection } from '@angular/material/sort';

@Component({
    selector: 'app-sort-indicator',
    standalone: true,
    imports: [MatIcon],
    templateUrl: './sort-indicator.component.html',
    styleUrls: ['./sort-indicator.component.scss'],
})
export class SortIndicatorComponent {
    public sortDirection = input<SortDirection>();
}
