import { createReducer, on } from '@ngrx/store';
import * as DeviceActions from '../actions/device.action';
import { Device } from '../../models/auth.model';

/**
 * Reducer for handling changes to the device registration data.
 */

/** Initial state of the authz feature. */
export interface DeviceState {
  device: Device;
  loading: boolean;
  error: any;
}

export const initialState: DeviceState = {
  device: { deviceId: '' },
  loading: false,
  error: null,
};

/** Reducer function for the authz feature. */
export const deviceReducer = createReducer(
  initialState,
  on(DeviceActions.loadDevice, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(DeviceActions.loadDeviceSuccess, (state, { device }) => ({
    ...state,
    device,
    loading: false,
  })),
  on(DeviceActions.loadDeviceFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(DeviceActions.updateDevice, (state, { device }) => {
    return {
      ...state,
      device,
      loading: true,
    };
  }),
  on(DeviceActions.removeDevice, (state) => {
    return {
      ...state,
      deviceId: '',
      loading: true,
    };
  })
);
