import { createAction, props } from '@ngrx/store';
import { AuthorizationToken } from '../../models/auth.model';
/**
 * Actions for managing authorization tokens in the NgRx store.
 */

/** Action to trigger the loading of authorization tokens. */
export const loadAuthzTokens = createAction('[Authz] Load Tokens');

/** Action dispatched when tokens are successfully loaded. */
export const loadAuthzTokensSuccess = createAction(
  '[Authz] Load Tokens Success',
  props<{ tokens: AuthorizationToken[] }>()
);

/** Action dispatched when there is an error loading tokens. */
export const loadAuthzTokensFail = createAction(
  '[Authz] Load Tokens Fail',
  props<{ error: any }>()
);

/** Action to add or update an authorization token. */
export const addOrUpdateAuthzToken = createAction(
  '[Authz] Add or Update Token',
  props<{ token: AuthorizationToken }>()
);

export const addOrUpdateAuthzTokenSuccess = createAction(
  '[Authz] Add or Update Token Success',
  props<{ token: AuthorizationToken }>()
);

export const addOrUpdateAuthzTokenFail = createAction(
  '[Authz] Add or Update Token Fail',
  props<{ error: any }>()
);

export const removeAuthzTokenSuccess = createAction(
  '[Authz] Remove Token Success',
  props<{ token: AuthorizationToken }>()
);

/** Action to remove an authorization token. */
export const removeAuthzToken = createAction(
  '[Authz] Remove Token',
  props<{ token: AuthorizationToken }>()
);

/** Action for a generic error in some authz action */
export const authzOperationFail = createAction(
  '[Authz] Operation Fail',
  props<{ error: any, operation: string }>()
);
