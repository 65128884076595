Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<div ref="' +
((__t = (ctx.nestedKey)) == null ? '' : __t) +
'">\n  ' +
((__t = (ctx.children)) == null ? '' : __t) +
'\n</div>\n';
return __p
}