Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<div class="builder-components drag-container formio-builder-' +
((__t = (ctx.type)) == null ? '' : __t) +
'" ref="' +
((__t = (ctx.key)) == null ? '' : __t) +
'-container">\n  ' +
((__t = (ctx.html)) == null ? '' : __t) +
'\n</div>\n';
return __p
}