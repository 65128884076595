import {animate, state, style, transition, trigger} from "@angular/animations";

export const inOutExpandX = trigger("inOutExpandX", [
    transition(":enter", [
        style({width: "0", opacity: "0"}),
        animate("200ms ease-out", style({width: "*", opacity: "1"})),
    ]),
    transition(":leave", [animate("200ms ease-in", style({width: "0", opacity: "0"}))]),
]);

export const inOutExpandY = trigger("inOutExpandY", [
    transition(":enter", [
        style({height: "0", opacity: "0"}),
        animate("200ms ease-out", style({height: "*", opacity: "1"})),
    ]),
    transition(":leave", [animate("200ms ease-in", style({height: "0", opacity: "0"}))]),
]);

export const fadeInFromRight = trigger("fadeInFromRight", [
    transition(":enter", [
        style({opacity: 0, transform: "translateX(100%)"}),
        animate("200ms ease-in", style({opacity: 1, transform: "translateX(0)"})),
    ]),
]);

export const fadeInFromLeft = trigger("fadeInFromLeft", [
    transition(":enter", [
        style({opacity: 0, transform: "translateX(-100%)"}),
        animate("200ms ease-in", style({opacity: 1, transform: "translateX(0)"})),
    ]),
]);

export const fadeInFromTop = trigger("fadeInFromTop", [
    transition(":enter", [
        style({opacity: 0, transform: "translateY(-100%)"}),
        animate("200ms ease-in", style({opacity: 1, transform: "translateY(0)"})),
    ]),
]);

export const inOutExpandXY = trigger("inOutExpandXY", [
    transition(":enter", [
        style({height: "0", width: "0", opacity: "0"}),
        animate("200ms ease-out", style({height: "*", width: "*", opacity: "1"})),
    ]),
    transition(":leave", [animate("200ms ease-in", style({height: "0", with: "0", opacity: "0"}))]),
]);

export const navListAnimation = trigger("navListAnimation", [
    state("expanded", style({maxWidth: "100%", padding: "0 1rem"})),
    state("collapsed", style({maxWidth: "5rem", padding: "0 0.5rem"})),
    transition("expanded <=> collapsed", animate("200ms ease-in-out"))
]);
