<div class="scrollable-table-father">
    <table mat-table [dataSource]="orgaPatients">
        <!-- Basic Info Column -->
        <ng-container matColumnDef="basicInfo">
            <th mat-header-cell *matHeaderCellDef>
                {{ "COMPONENT.PAGE_PATIENT_LIST.patients" | translate }}
            </th>
            <td mat-cell *matCellDef="let patient">
                <app-home-patient-basic-info
                    [patient]="patient"
                    class="orga-container-basicInfo"
                ></app-home-patient-basic-info>
            </td>
        </ng-container>

        <!-- Visit Column -->
        <ng-container matColumnDef="visit">
            <th mat-header-cell *matHeaderCellDef>
                {{ "COMPONENT.PAGE_ORGA.table_header_visit" | translate }}
            </th>
            <td mat-cell *matCellDef="let patient">
                <app-home-patient-visit
                    [patient]="patient"
                    [viewContentI18n]="viewContentI18n"
                    class="orga-container-visit"
                ></app-home-patient-visit>
            </td>
        </ng-container>

        <!-- Tasks Column -->
        <ng-container matColumnDef="tasks">
            <th mat-header-cell *matHeaderCellDef>
                {{ "COMPONENT.PAGE_ORGA.table_header_tasks" | translate }}
            </th>
            <td mat-cell *matCellDef="let patient">
                <app-home-patient-tasks
                [viewContentI18n]="viewContentI18n"
                    [patient]="patient"
                    class="orga-container-tasks"
                ></app-home-patient-tasks>
            </td>
        </ng-container>

        <!-- Header Row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <!-- Data Rows -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>

<!-- <mat-paginator
    class="mat-elevation-z8"
    [length]="orgaPatients.length"
    [pageIndex]="page"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="onPagerChange($event)"
></mat-paginator> -->
