import {
    Component,
    EventEmitter,
    Output,
    ViewEncapsulation,
    effect,
    input,
    signal
} from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { SortDirection } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { SortIndicatorComponent } from '../sort-indicator/sort-indicator.component';

export interface SortOption {
    value: string;
    translateKey: string;
}

export interface SortSelection {
    value: SortOption['value'];
    sortOrder: SortDirection;
}

@Component({
    selector: 'app-sort-select',
    standalone: true,
    templateUrl: './sort-select.component.html',
    styleUrls: ['./sort-select.component.scss'],
    imports: [
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        TranslateModule,
        SortIndicatorComponent,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SortSelectComponent {
    constructor() {
        effect(() => {
            console.log("SortSelectComponent initialized", this.sortSelection());
        })
    }

    @Output()
    public directionChange = new EventEmitter<SortSelection>();

    public options = input.required<SortOption[]>();

    public initalSortDirection = input<SortDirection>('asc');

    public sortSelection = signal<SortSelection>({
        value: 'name',
        sortOrder: this.initalSortDirection(),
    });

    public selectionChange(event: MatSelectChange) {
        this.sortSelection.set({
            value: event.value,
            sortOrder: this.initalSortDirection(),
        });
        this.directionChange.emit(this.sortSelection());
    }

    public onSelectionChange(event: MatOptionSelectionChange) {
        if (event.isUserInput && this.sortSelection().value === event.source.value) {
            this.sortSelection.update((value => ({
                ...value,
                sortOrder: value.sortOrder === 'asc' ? 'desc' : 'asc',
            })));
            this.directionChange.emit(this.sortSelection());
        }
    }

    public get displayText() {
        const option = this.options().find((option) => option.value === this.sortSelection().value);
        return option ? option.translateKey : '';
    }
}
