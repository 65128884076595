import {NavController, Platform} from "@ionic/angular";
import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCardModule} from "@angular/material/card";
import {LuicModule} from "@lohmann-birkner/luic";
import {FormsModule} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AccessFacadeService} from "../../../services/facades/access-facade.service";

/**
 * Component for the login page.
 */
@Component({
    selector: "app-login-page",
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        LuicModule,
        FormsModule,
        MatProgressSpinner,
        TranslateModule,
    ],
    templateUrl: "./login-page.component.html",
    styleUrl: "./login-page.component.scss",
})
export class LoginPageComponent {
    hide = true;
    public username = "";
    public password = "";
    protected readonly window = window;

    public constructor(
        private accessFacade: AccessFacadeService,
        private authService: AuthService,
        private navController: NavController,
        private platform: Platform,
        private snackbar: MatSnackBar
    ) {
        this.authService.initAuth().then();

        if (this.platform.is("electron")) {
            window.addEventListener("message", (event) => {
                if (event.data.type === "popupClosed") {
                    const popupUrl = event.data.url;
                    this.authService.initializeLoginCallback(popupUrl).then();
                }
            });
        }
    }

    public async onClickOnLogin(): Promise<void> {
        const validUsers = [
            {username: "Arzt1", password: "ArztArzt1"},
            {username: "Arzt2", password: "ArztArzt2"},
            {username: "Arzt3", password: "ArztArzt3"},
        ];

        const user = validUsers.find((e) => e.username === this.username);

        if (!user) {
            this.snackbar.open(
                "Kein Benutzer mit dem gegebenen Benutzername gefunden",
                undefined,
                {duration: 1500}
            );
            return;
        }

        if (user.password !== this.password) {
            this.snackbar.open(
                "Das eingegebenes Kennwort ist nicht gültig",
                undefined,
                {duration: 1500}
            );
            return;
        }

        this.accessFacade.setUserToken({
            scope: "",
            access_token: "THISISADUMMYTOKEN",
            refresh_token: "THISISADUMMYTOKEN",
            expires_in: "3 months",
            expiry_date: 1746541005
        });
        this.navController.navigateRoot("/");
    }
}
