import {Component, Input, OnInit} from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {getDummyPatientData, PatientData, VisitRecord,} from '../../../models/patient.model';
import {CommonModule} from '@angular/common';
import dayjs from 'dayjs';
import {MatExpansionModule} from '@angular/material/expansion';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {MatIconModule} from '@angular/material/icon';
import {
    DialogPatientOverviewComponent
} from '../../../modals/dialog-patient-overview/dialog-patient-overview.component';
import {MatDialog} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormModalComponent} from '../../../modals/form-modal/form-modal.component';
import {firstValueFrom} from 'rxjs';
import {TranslateModule} from '@ngx-translate/core';
import {DynamicListComponent} from '../../data-interaction/dynamic-list/dynamic-list.component';
import {PatientNameAndDob} from '../../../pages/patient-details/patient-details.component';
import {FormioRendererI18n} from '../../data-interaction/formio-renderer/formio-renderer.component';

dayjs.extend(customParseFormat);

export interface DialogData {
    dateAndTime: string;
    catalog: string;
    content: string;
}

interface DataEntry {
    referenceId: string;
    modifiedAt: string;

    [key: string]: any;
}

interface GroupedElements {
    latest: DataEntry;
    history: DataEntry[];
    historyVisible?: boolean;
}

@Component({
    selector: "app-patient-overview",
    templateUrl: "./patient-overview.component.html",
    styleUrls: ["./patient-overview.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        DialogPatientOverviewComponent,
        MatButtonModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatListModule,
        TranslateModule,
        DynamicListComponent,
    ],
})
export class PatientOverviewComponent implements OnInit {
    @Input() patientDetails: PatientData = getDummyPatientData();
    @Input() patientDetailsI18n: FormioRendererI18n | undefined;
    lastModifiedAtPatientDetails: PatientData = getDummyPatientData();
    @Input() patientNameAndDob: PatientNameAndDob = {
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        room: "",
        ward: "",
        bed: "",
    };

    public differenceInDays: number | undefined | string;

    public constructor(public dialog: MatDialog) {
    }


    public ngOnInit() {
        if (this.patientDetails?.DRGs[0]?.plannedDischarge) {
            const plannedDischargeDate = dayjs(
                this.patientDetails.DRGs[0].plannedDischarge,
                "DD.MM.YYYY"
            );
            const targetDischargeDate = dayjs(
                this.patientDetails.DRGs[0].targetDischarge,
                "DD.MM.YYYY"
            );

            this.differenceInDays = plannedDischargeDate.diff(
                targetDischargeDate,
                "day"
            );
        }
        this.patientDetails.visitRecords = this.processVisitRecords(
            this.patientDetails.visitRecords
        );
        this.lastModifiedAtPatientDetails = this.processAllData(
            this.patientDetails
        );
    }

    public getLatestEntries(dataArray: DataEntry[]): DataEntry[] {
        const groupedEntries: { [referenceId: string]: GroupedElements } = {};

        dataArray.forEach((entry) => {
            const refId = entry.referenceId;
            if (!groupedEntries[refId]) {
                groupedEntries[refId] = {
                    latest: entry,
                    history: [],
                    historyVisible: false,
                };
            } else {
                if (
                    new Date(entry.modifiedAt).getTime() >
                    new Date(groupedEntries[refId].latest.modifiedAt).getTime()
                ) {
                    groupedEntries[refId].latest = entry;
                }
            }
            groupedEntries[refId].history.push(entry);
        });

        return Object.values(groupedEntries).map((group) => group.latest);
    }

    /**get the lastest entry of the entries with the same refId*/
    public processAllData(data: any): PatientData {
        const result: any = {};

        for (const key in data) {
            if (Array.isArray(data[key])) {
                result[key] = this.getLatestEntries(data[key]);
            } else {
                result[key] = data[key];
            }
        }

        return result;
    }


    //#region Listeners

    public async openFormDialog(
        formFileName: string,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo,
            },
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
        // TODO: Do something with the results
    }

    /**for the data with only on entry, like anamnese,diagnose,
     * following the data property contains all the data, and in theory, the referenceIds are always the same.
     * for the multipledata like therapy, diagnostic, they can have more than one entries, and each entry have one id
     * the following property data only contains one entry, allData contains all the data, the whole array.
     *
     */
    public async onClickOnElement(
        data: any[],
        formFileName: string,
        patientInfo?: PatientNameAndDob,
        viewContentI18n?: FormioRendererI18n,
        allData?: any[]
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_data: {data},
                form_file_name: formFileName,
                patient_info: patientInfo,
                viewContentI18n,
                allData,
            },
            height: 'auto',
            maxHeight: 'calc(100vh - 16px)',
            width: '813px',
            panelClass: 'patient-overview-dialog-container',
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
        /* do something for the revised input */
    }

    //#endregion

    public checkOverflow(element: HTMLDivElement) {
        return (
            element.offsetHeight < element.scrollHeight &&
            !(
                Math.abs(
                    element.scrollHeight -
                    element.clientHeight -
                    element.scrollTop
                ) < 1
            )
        );
    }

    public getFormattedDate(date: string | Date): string {
        if (!date) return '';

        // Convert the ISO date string to a formatted string
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }

    /* remove the :ss in the date data,because then the date can be shown in the form */
    private processVisitRecords(visitRecords: VisitRecord[]) {
        return visitRecords?.map((record) => ({
            ...record,
            modifiedAt: this.getFormattedDate(record.modifiedAt),
        }));
    }
}
