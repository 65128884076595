<!-- Patient Information Container -->

<div
    class="lbFlex lbCol patient-basic-info"
    *ngIf="patient"
    (click)="onPatientSelected()"
>
    <!-- Patient Name and Details -->
    <div matLine>
        <span class="patient-basic-info-name table-name"
            >{{ patient.lastName }}, {{ patient.firstName }},</span
        >

        <span class="table-text"> {{ "GENERAL." + patient.gender | translate }},
        {{ patient.dob | date : ("GENERAL.date_format" | translate) }}
        ({{ patient.dob | calAge }}) </span>
    </div>
    <!-- Station,Room, Bed, Admission date and discharge Date -->
    <div>
        <span class=" table-text">
            <span class="patient-basic-info-station-room">
            {{ patient.ward }},
            {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
            {{ patient.room.split(" ")[1]
            }}{{ patient.bed.split(" ")[1] }}</span>
        A:
        {{
            patient.admissionDate.split("T")[0]
                | date : ("GENERAL.date_format" | translate)
        }}&nbsp;{{ patient.admissionDate.split("T")[1].slice(0, 5) }}&nbsp;({{
            patient.admissionDate | dayAgo
        }}),
        {{ "COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date" | translate }}:
        {{ patient.discharge?.[0]?.dismissalExpectedOn
         | date : ("GENERAL.date_format" | translate) }}
        ({{ patient.discharge?.[0]?.dismissalExpectedOn|dayAgo }})</span
        >
    </div>
    <!-- Patient Diagnosis -->
     @if (!disableDiagnosis) {
         <div class="table-subtext">
             <ng-container
                 *ngFor="let diagnosis of patient.diagnosis; last as isLast"
             >
                 {{ diagnosis }}{{ isLast ? "" : "," }}
             </ng-container>
         </div>
     }
</div>
