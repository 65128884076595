import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {Preferences} from "@capacitor/preferences";
import {LS_ACCESS_TOKEN} from "../shared/constants";

export const authGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);

    let token = null;

    try {
        token = JSON.parse(
            (await Preferences.get({ key: LS_ACCESS_TOKEN })).value ?? ''
        );
    } catch (e) {
        console.error(e);
    }

    if (token?.access_token) {
        // Token exists, allow navigation
        return true;
    } else {
        // No token, redirect to login
        return router.navigateByUrl('/login');
    }
};
