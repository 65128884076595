<div class="patient-visit-info h-full">
    <app-dynamic-list
        class="w-full"
        [list]="latestRecordOfRecords"
        [clipToWhenSmall]="2"
        [showButton]="false"
        [isClipped]="isClipped"
    >
        <ng-template #itemTemplate let-item="item">
            <mat-list
                (click)="onClickOnElement(item, getPatientNameAndDob())"
                class="h-full"
            >
                <div class="patient-visit-name table-text">
                    {{ item.note }}
                </div>

                <div class="table-date patient-visit-date">
                    {{
                        item.modifiedAt
                            | date : ("GENERAL.date_time_format" | translate)
                    }}
                </div>
            </mat-list>
        </ng-template>
    </app-dynamic-list>
    <!-- button for open a new task dialog-->
    <div class="patient-visit-info-button">
        <button
            mat-icon-button
            (click)="openFormDialog('form_visit.json', getPatientNameAndDob())"
        >
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button *ngIf="showButton" (click)="toggleClip()">
            <mat-icon *ngIf="isClipped"> keyboard_arrow_up </mat-icon>
            <mat-icon *ngIf="!isClipped"> keyboard_arrow_down </mat-icon>
        </button>
    </div>
</div>
