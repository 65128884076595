import {createAction, props} from "@ngrx/store";
import {MercureStatus} from "../../models/mercure.model";

export const loadMercureState = createAction("[Mercure] Load ServerPingStatus");

export const setServerPingStatus = createAction(
    "[Mercure] Set ServerPingStatus",
    props<{ serverPingStatus: MercureStatus }>()
);

export const setServerPingStatusSuccess = createAction(
    "[Mercure] Set ServerPingStatus Successful",
    props<{ serverPingStatus: MercureStatus }>()
);

export const setServerPingStatusFail = createAction(
    "[Mercure] Set ServerPingStatus Fail",
    props<{ error: any }>()
);

export const loadServerPingStatusSuccess = createAction(
    "[Mercure] Load ServerPingStatus Successful",
    props<{ serverPingStatus: MercureStatus }>()
);

export const loadServerPingStatusFail = createAction(
    "[Mercure] Load ServerPingStatus Fail",
    props<{ error: any }>()
);
