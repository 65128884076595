Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '';
__p += '<div class="formio builder ui grid formbuilder">\n  <div class="four wide column formcomponents">\n    ' +
((__t = (ctx.sidebar)) == null ? '' : __t) +
'\n  </div>\n  <div class="twelve wide column formarea" ref="form">\n    ' +
((__t = (ctx.form)) == null ? '' : __t) +
'\n  </div>\n</div>\n';
return __p
}