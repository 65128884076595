<div *ngIf="!online" color="danger">
    <span class="material-icons-outlined"> wifi_off </span>
</div>
<div class="net-quality-online-container" *ngIf="online">
    <div [ngStyle]="{ height: pingPercent + '%', background: getColorForPercent(pingPercent) }">
        {{ pingPercent | number : "0.0-0" }}%
    </div>

    <div [ngStyle]="{ height: speedPercent + '%', background: getColorForPercent(speedPercent) }">
        {{ speedPercent | number : "0.0-0" }}%
    </div>
</div>
