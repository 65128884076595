import { SidebarButton } from './components/navigation/sidebar/sidebar.component';
import { UserToken } from './models/auth.model';
import {
    Component,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavController, Platform } from '@ionic/angular';
import { AccessFacadeService } from './services/facades/access-facade.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { SettingsFacadeService } from './services/facades/settings-facade.service';
import { Settings } from './models/settings.model';
import { ThemeService } from './services/theme.service';
import { DOCUMENT } from '@angular/common';
import { decodeJwt } from 'jose';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private static readonly TAG = 'AppComponent';
    public accessToken$ = this.accessFacade.userTokens$;
    public accessToken: UserToken | null = null;
    public username: string = '';
    public globalEventbusListener: Subscription = Subscription.EMPTY;
    public serverConnectionStatus = new BehaviorSubject<boolean>(false);
    public tmpServerConnectionStatus: boolean = false;
    public serverPingStatus = new BehaviorSubject<boolean>(false);
    HandsetPortrait: boolean = false;
    showSidebar: boolean = false;
    private allSubs: Subscription[] = [];
    private selectedColorSchema: string = '';
    // Create a map to display breakpoint names for demonstration purposes.
    displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
    ]);
    currentHandyOrientation: string = '';

    public get currentUrl() {
        return this.router.url;
    }

    public constructor(
        private authService: AuthService,
        private accessFacade: AccessFacadeService,
        private navController: NavController,
        private platform: Platform,
        private router: Router,
        private settings: SettingsFacadeService,
        private translate: TranslateService,
        private zone: NgZone,
        private themeService: ThemeService,
        private breakpointObserver: BreakpointObserver,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.setupDeepLinks();
        this.setupSettings();

        this.themeService.theme$.subscribe((theme) => {
            if (theme === 'light') {
                this.renderer.addClass(this.document.body, 'cp2-theme');
                this.renderer.removeClass(this.document.body, 'cp2-dark-theme');
            }
            if (theme === 'dark') {
                this.renderer.addClass(this.document.body, 'cp2-dark-theme');
                this.renderer.removeClass(this.document.body, 'cp2-theme');
            }
        });

        this.breakpointObserver
            .observe([
                Breakpoints.HandsetPortrait,
                Breakpoints.HandsetLandscape,
                Breakpoints.Web,
                Breakpoints.Tablet,
            ])
            .subscribe((result) => {
                console.log(result);
                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        this.currentHandyOrientation =
                            this.displayNameMap.get(query) ?? '';
                        console.log(this.currentHandyOrientation);
                    }
                }
                if (
                    this.currentHandyOrientation === 'handsetPortrait' ||
                    this.currentHandyOrientation === 'handsetLandscape'
                ) {
                    console.log(this.currentHandyOrientation);
                    this.showSidebar = false;
                } else {
                    this.showSidebar = true;
                }
            });
    }

    /**
     * Handles initialization on component initialization.
     */
    async ngOnInit(): Promise<void> {
        // Subscribe to access token changes
        const subscription = this.accessToken$.subscribe((t) => {
            debugger
            if (t.token) {
                this.username = decodeJwt(t.token?.access_token)[
                    'name'
                ] as string;
                this.accessToken = t.token;
            }
        });

        this.allSubs.push(subscription);

        // Load initial data
        this.platform.ready().then(() => {
            this.accessFacade.loadData();
        });

        // Try to refresh the access token every 5 minutes
        setInterval(async () => {
            if (this.accessToken?.refresh_token) {
                const refreshedToken = await this.authService.refreshAuth(
                    this.accessToken?.refresh_token
                );
                this.accessFacade.setAccessToken(refreshedToken);
            }
        }, 5 * 60 * 1000);
    }

    /**
     * Cleans up resources on component destruction.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public onClickShowSidebar() {
        this.showSidebar = !this.showSidebar;
    }

    //#region Listeners

    async handleNavigation(link: string): Promise<void> {
        await this.router.navigateByUrl(link);
    }

    /**
     * Handles sidebar button clicks.
     * @param sidebarButton The sidebar button clicked.
     */
    async handleSidebarButton(sidebarButton: SidebarButton): Promise<void> {
        switch (sidebarButton.name) {
            case 'logout':
                this.accessFacade.removeUserToken();
                // TODO: Delete existing token
                // TODO: Show alert before anything
                if (window.confirm('Are you sure you want to logout?')) {
                    // Perform logout process here
                    // For example:
                    // logout();
                    await this.navController.navigateRoot('/login');
                }
                break;

            case 'settings':
                await this.navController.navigateRoot('/settings');
                break;

            case 'admin':
                await this.navController.navigateRoot('/admin');
                break;
            case 'refresh_access':
                if (this.accessToken?.refresh_token) {
                    const at = await this.authService.refreshAuth(
                        this.accessToken?.refresh_token
                    );
                    this.accessFacade.setAccessToken(at);
                }
                break;

            case 'back':
                this.navController.back();

                break;
            default:
                // await this.navController.navigateForward('/test-sandbox');
                console.log(
                    AppComponent.TAG,
                    'sidebar button clicked: ',
                    sidebarButton
                );
        }
    }

    private setupDeepLinks(): void {
        App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
            await this.zone.run(async (appUrlOpen) => {
                const slug = event.url.split('app.cpad2.de').pop();
                if (slug) {
                    if (slug.includes('/login/redirect?')) {
                        await this.authService.initializeLoginCallback(
                            window.location.origin + slug
                        );
                    }
                }
            });
        });
    }

    private setupSettings() {
        this.settings.loadSettings();

        const s = this.settings.settings$.subscribe((s: Settings) => {
            // Apply here the saved settings to the app
            this.translate.use(s.language);
            this.selectedColorSchema = s.colorScheme;
            this.getColorScheme(this.selectedColorSchema);
        });
        this.allSubs.push(s);
    }

    private getColorScheme(colorScheme: string) {
        if (colorScheme === 'rostock') {
            this.renderer.removeClass(this.document.body, 'cp2-theme');
            // Add the new color scheme class
            this.renderer.addClass(this.document.body, 'cp2-rostock-theme');
        } else {
            this.renderer.addClass(this.document.body, 'cp2-theme');

            // Add the new color scheme class
            this.renderer.removeClass(this.document.body, `cp2-rostock-theme`);
        }
    }
}
