import { Component, ElementRef, OnInit } from '@angular/core';
import { OrgaPatientData, PatientListItem } from '../../models/patient.model';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WorkflowTabsComponent } from '../../components/tabs/workflow-tabs/workflow-tabs.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { OrgaTabsComponent } from '../../components/tabs/orga-tabs/orga-tabs.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { SortSelectComponent, SortSelection } from "../../components/utility/sort-select/sort-select.component";
import { SortDirection } from '@angular/material/sort';

@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        CommonModule,
        MatTabsModule,
        TranslateModule,
        WorkflowTabsComponent,
        FormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        OrgaTabsComponent,
        MatSelectModule,
        SortSelectComponent
    ]
})
export class OrganizationComponent implements OnInit {
    public OrgaPatientData: OrgaPatientData[] = [];
    public orgaPatients: PatientListItem[] = [];
    public searchQuery: string = '';
    public sortKey: string = 'lastName';
    public sortOrder: SortDirection = 'asc';
    public filteredOrgaPatients: PatientListItem[] = [];
    public filteredOrgaPatientListItem: OrgaPatientData[] = [];
    public viewContentI18n: FormioRendererI18n | undefined;

    public sortOptions = [
        { value: 'lastName', translateKey: 'COMPONENT.PAGE_WORKFLOW.lastName' },
        { value: 'admissionDate', translateKey: 'COMPONENT.PAGE_WORKFLOW.admissionDate' },
    ];

    selectedTabIndex: number = 0;

    public constructor(private api: Cp2ApiService) {}

    ngOnInit() {
        this.OrgaPatientData = this.api
            .getOrgaPatients()
            .data.orgaData.map((item: any) => {
                return {
                    ...item,
                    patients: this.sortList(item.patients),
                };
            });
        this.filteredOrgaPatientListItem = this.OrgaPatientData;
        this.filteredOrgaPatients = this.OrgaPatientData.flatMap(
            (item) => item.patients
        );

        this.viewContentI18n = this.api.getOrgaPatients().i18n;
    }

    public onSortChange(event: SortSelection): void {
        this.sortKey = event.value;
        this.sortOrder = event.sortOrder;

        this.sortList(this.orgaPatients);
        this.filteredOrgaPatientListItem = this.filteredOrgaPatientListItem.map(
            (item) => ({
                ...item,
                patients: this.sortList(item.patients),
            })
        );
    }

    public updateFilteredPatients() {
        this.filteredOrgaPatients = this.getFilteredPatients(
            this.OrgaPatientData.flatMap((item) => item.patients)
        );
        this.filteredOrgaPatientListItem = this.OrgaPatientData.map((item) => {
            return {
                ...item,
                patients: this.getFilteredPatients(item.patients),
            };
        });
    }
    //#endregion

    private sortList(patients: PatientListItem[]): PatientListItem[] {
        return patients?.sort((a, b) => {
            let compare = 0;

            switch (this.sortKey) {
                case 'lastName':
                    compare =
                        a.lastName.localeCompare(b.lastName) ||
                        a.firstName.localeCompare(b.firstName);
                    break;
                case 'admissionDate':
                    compare =
                        new Date(a.admissionDate).getTime() -
                        new Date(b.admissionDate).getTime();
                    break;
                // case 'ward':
                //     compare = a.ward.localeCompare(b.ward);
                //     break;
                case 'bed':
                    compare = a.bed
                        .split(' ')[1]
                        .toLowerCase()
                        .localeCompare(b.bed.split(' ')[1].toLowerCase());
                    break;
                default:
                    break;
            }

            return this.sortOrder === 'asc' ? compare : -compare;
        });
    }

    /* area for search */
    getFilteredPatients(patients: PatientListItem[]): PatientListItem[] {
        if (!this.searchQuery.trim()) return patients; // If no search query, return all patients from the station

        return patients.filter((patient) =>
            this.matchesPatient(patient, this.searchQuery.toLowerCase())
        );
    }

    private matchesPatient(patient: PatientListItem, query: string): boolean {
        // Check simple string properties
        const matchesSimpleFields = [
            patient.ward,
            patient.room,
            patient.bed,
            patient.firstName,
            patient.lastName,
            patient.gender,
            patient.dob,
            patient.admissionDate,
        ].some((field) => field.toLowerCase().includes(query));

        // Check array of strings (diagnosis)
        const matchesDiagnosis = patient.diagnosis.some((diag) =>
            diag.toLowerCase().includes(query)
        );

        // Check nested DiagnosticFindings array
        const matchesDiagnosticFindings =
            patient.diagnosticFindings?.some(
                (finding) =>
                    finding.diagnosticFindingsName
                        .toLowerCase()
                        .includes(query) ||
                    finding.catalog.toLowerCase().includes(query)
            ) ?? false;

        // Check nested Tasks array
        const matchesTasks = patient.tasks.some(
            (task) =>
                task.task_name.toLowerCase().includes(query) ||
                task.details.toLowerCase().includes(query) ||
                task.priority.toLowerCase().includes(query) ||
                task.editor.toLowerCase().includes(query) ||
                task.goalDateOn.toLowerCase().includes(query) ||
                task.contractor.toLowerCase().includes(query)
        );

        // Check nested Visits array
        const matchesVisits = patient.visitRecords.some((visitRecord) =>
            visitRecord.note.toLowerCase().includes(query)
        );

        // Check Discharge information
        const matchesDischarge =
            patient.discharge?.some(
                (discharge) =>
                    discharge.comment.toLowerCase().includes(query) ||
                    (discharge.dismissalExpectedOn
                        ? discharge.dismissalExpectedOn
                              .toLowerCase()
                              .includes(query)
                        : false)
            ) ?? false;

        return (
            matchesSimpleFields ||
            matchesDiagnosis ||
            matchesDiagnosticFindings ||
            matchesTasks ||
            matchesVisits ||
            matchesDischarge
        );
    }
    //#endregion
}
