<!-- Patient details card with tabs -->
<!-- Tab group -->
<div class="orga-sort-search">
    <!-- Sort select -->
    <app-sort-select
        [options]="sortOptions"
        (directionChange)="onSortChange($event)"
        class="patient-list-sort"
    />

    <!-- Search field -->
    <mat-form-field class="orga-search">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>{{
            "COMPONENT.PAGE_PATIENT_LIST.placeholder_search_in_list" | translate
        }}</mat-label>
        <input
            matInput
            [(ngModel)]="searchQuery"
            (ngModelChange)="updateFilteredPatients()"
            type="search"
        />
    </mat-form-field>
</div>

<mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
    class="orga-container"
    [(selectedIndex)]="selectedTabIndex"
>
    <mat-tab *ngFor="let station of filteredOrgaPatientListItem; let i = index">
        <ng-template mat-tab-label
            ><mat-icon *ngIf="selectedTabIndex === i">done</mat-icon>
            {{ station.stationName }}
        </ng-template>
        <app-orga-tabs
            [viewContentI18n]="viewContentI18n"
            [orgaPatients]="station.patients"
        ></app-orga-tabs>
    </mat-tab>
</mat-tab-group>
