import { FormioModule } from '@formio/angular';
import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { CommonModule, JsonPipe } from '@angular/common';
import {
    FormioRendererComponent,
    FormioRendererData,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { PatientNameAndDob } from '../../pages/patient-details/patient-details.component';
import { CalAgePipe } from '../../pipes/cal-age.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChangingHistoryComponent } from '../../components/data-interaction/changing-history/changing-history.component';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';

export interface FormModalResult {
    role: 'cancel' | 'save';
    data: any;
}

interface GroupedElements {
    latest: any;
    history: any[];
    historyVisible?: boolean;
}

@Component({
    selector: 'app-form-modal',
    standalone: true,
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
    imports: [
        FormioModule,
        MatCardModule,
        JsonPipe,
        FormioRendererComponent,
        CommonModule,
        CalAgePipe,
        FormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatListModule,
        ChangingHistoryComponent,
        ScrollingModule,
        MatBadgeModule,
    ],
})
export class FormModalComponent<T> implements OnInit, AfterContentInit {
    private static readonly TAG = 'FormModalComponent';

    public form: any;
    public formData: any;
    public renderOptions: any = {};
    /**the name of the form */
    public form_file_name: string = '';
    /**the title of the dialog */
    public formTitle: string = '';
    /**translation data for the formular */
    public i18n: FormioRendererI18n | undefined;

    /**translation for the key of the incoming data
     * this variable also work as a flag to control when to show the whole container of changing history
     */
    public viewContentI18n: FormioRendererI18n;
    public formioRendererData: FormioRendererData[] = [];
    public patientInfo: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        room: '',
        ward: '',
        bed: '',
    };
    showHistory: boolean = false;
    numberOfHistories: number = 0;
    groupedElements: { [referenceId: string]: GroupedElements } = {};
    groupedElementsHistory: any[] | undefined;
    allData: any[] = [];
    public showHistoryDetailIndex: number | null = null;

    public constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            form_file_name: string;
            form_data?: any;
            patient_info: PatientNameAndDob;
            viewContentI18n: FormioRendererI18n;
            allData: any[];
        },
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.viewContentI18n = data.viewContentI18n;
        this.formData = data.form_data;
        this.patientInfo = data.patient_info;
        this.allData = data.allData;
        this.form_file_name = data.form_file_name;
        this.formTitle = this.getFormName(data.form_file_name);
        this.numberOfHistories = this.allData?.length;

        if (
            (data.form_file_name === 'form_anamnesis.json' ||
                data.form_file_name === 'form_diagnoses.json' ||
                data.form_file_name === 'form_visit.json') &&
            this.allData
        ) {
            this.processData(data.allData);
        } else if (this.allData) {
            //this is for multiple entry,to find the current one
            this.processData(
                this.allData.filter(
                    (el) => el.referenceId === data.form_data.data.referenceId
                )
            );
        } else if (data.form_data) {
            /**open a new formular, and show the current time if necessary*/
            this.processData(data.form_data.data);
        }
    }

    toggleHistory() {
        this.showHistory = !this.showHistory;
    }

    toggleHistoryDetail(index: number): void {
        if (this.showHistoryDetailIndex === index) {
            this.showHistoryDetailIndex = null;
        } else {
            this.showHistoryDetailIndex = index;
        }
    }

    public onClose(): void {
        this.dialogRef.close(); // Optionally pass a result here if needed
    }

    public ngOnInit() {
        this.loadJSON().then();
    }

    public ngAfterContentInit(): void {
        this.setLanguage();
    }

    //#region Listeners
    public onClickOnSubmit($event: any) {
        // Intercept the submit event and dismiss the dialog gracefully
        if ($event) {
            this.dialogRef.close({ role: 'save', data: $event });
        } else {
            this.dialogRef.close({ role: 'cancel', data: $event });
        }
    }

    public onFormButtonClick($event: any) {
        if ($event) {
            this.dialogRef.close({ role: 'save', data: $event });
        } else {
            this.dialogRef.close({ role: 'cancel', data: $event });
        }
    }

    public onFormChange($event: any) {}

    private processData(dataArray: any[]) {
        dataArray.forEach((el: any) => {
            const refId = el.referenceId;
            if (!this.groupedElements[refId]) {
                this.groupedElements[refId] = {
                    latest: el,
                    history: [],
                    historyVisible: false,
                };
            }
            this.groupedElements[refId].history.push(el);
            this.groupedElements[refId].history.sort(
                (a, b) =>
                    new Date(b.modifiedAt).getTime() -
                    new Date(a.modifiedAt).getTime()
            );
        });

        this.groupedElementsHistory = this.buildGroupedElementsHistory();
        const groupedElementsKeys = Object.keys(this.groupedElements);
        for (const groupedElementsKey of groupedElementsKeys) {
            const groupedElement = this.groupedElements[groupedElementsKey];
            groupedElement.latest = this.groupedElementsHistory[0];
            const keys = Object.keys(groupedElement.latest);
            for (const key of keys) {
                const value = groupedElement.latest[key];
                this.formioRendererData.push({ key, value });
            }
        }
    }

    private buildGroupedElementsHistory(): any[] {
        const historyArray: any[] = [];
        for (const key in this.groupedElements) {
            if (this.groupedElements.hasOwnProperty(key)) {
                historyArray.push(...this.groupedElements[key].history);
            }
        }
        return historyArray;
    }

    //#endregion

    private async loadJSON(): Promise<void> {
        const content = await (
            await fetch('assets/forms/' + this.data.form_file_name)
        ).json();
        this.form = content.form;
        this.renderOptions.i18n = content.i18n;
        this.i18n = content.i18n;
    }

    private setLanguage(lang?: string) {
        if (!lang) {
            lang = this.translate.currentLang;
        }
        this.renderOptions.language = lang;
    }

    private getFormName(formFilename: string) {
        let parts = formFilename.split('_');

        let desiredPart = parts[1];

        return desiredPart.split('.')[0];
    }
}
