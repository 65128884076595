import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import {
    DashboardItem,
    LuicModule,
    MainLayout,
    ViewContent,
} from '@lohmann-birkner/luic';
import {
    CdkDrag,
    CdkDragDrop,
    DragDropModule,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Preferences } from '@capacitor/preferences';
import { LS_DASHBOARD } from '../../../shared/constants';

@Component({
    selector: 'app-network-status',
    templateUrl: './network-status.component.html',
    standalone: true,
    imports: [
        MatIcon,
        MatTooltip,
        AsyncPipe,
        LuicModule,
        CommonModule,
        CdkDrag,
        DragDropModule,
    ],
    styleUrls: ['./network-status.component.scss'],
})
export class NetworkStatusComponent {
    // networkStatus$: Observable<boolean>;
    // serverConnectionStatus$: Observable<boolean>;
    // serverPingStatus$: Observable<boolean>;

    // networkTooltipText$: Observable<string>;
    // serverConnectionTooltipText$: Observable<string>;
    public rawDashboard: DashboardItem[] = [];
    /** Structured layout of dashboard items for rendering. */
    public mappedDashboard: MainLayout = {} as MainLayout;
    /** Identifiers for the columns to which items can be dragged. */
    public connectedColumns: string[] = [];
    @Input() viewContent: ViewContent | undefined;

    public constructor() {
        this.loadDashboardDataFromLocalStorage().subscribe(
            (data: DashboardItem[]) => {
                this.rawDashboard = data;
                this.organizeDashboardLayout();
                this.connectedColumns = Object.keys(this.mappedDashboard);
            }
        );
        //TODO:for the 3 icons
        // this.networkStatus$ = this.networkService.getNetworkStatus();
        // this.serverConnectionStatus$ =
        //     this.networkService.getServerConnectionStatus();
        // this.serverPingStatus$ = this.networkService.getServerPingStatus();
        // // Tooltips sind auch reaktive Observables, die den aktuellen Zustand beschreiben
        // this.networkTooltipText$ = this.networkStatus$.pipe(
        //     map((status) => `Netzwerkstatus: ${status ? 'Online' : 'Offline'}`)
        // );
        // this.serverConnectionTooltipText$ = this.serverConnectionStatus$.pipe(
        //     map(
        //         (status) =>
        //             `Serververbindung: ${
        //                 status ? 'Verfügbar' : 'Nicht erreichbar'
        //             }`
        //     )
        // );
        // this.serverPingTooltipText$ = this.serverPingStatus$.pipe(
        //     map(
        //         (status) =>
        //             `Server Ping: ${
        //                 status ? 'Erfolgreich' : 'Zeitüberschreitung'
        //             }`
        //     )
        // );
    }

    /**
     * Handles the drop event for draggable dashboard items, updating their position.
     * @param event The drag-and-drop event containing the dragged item and its target position.
     * @param targetColumnIndex The index of the target column for the dropped item.
     */
    public async onItemDrop(
        event: CdkDragDrop<DashboardItem[]>,
        targetColumnIndex: number
    ): Promise<void> {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        // Update positions and save the new layout
        this.updateItemPositions(event, targetColumnIndex);
        await this.saveDashboardLayout();
    }

    /**
     * Loads the dashboard configuration from local storage or initializes with default data.
     * @returns An Observable that emits the loaded or default dashboard configuration.
     */
    private loadDashboardDataFromLocalStorage(): Observable<DashboardItem[]> {
        return from(Preferences.get({ key: LS_DASHBOARD })).pipe(
            switchMap(({ value }) => {
                if (value) {
                    return of(JSON.parse(value));
                } else {
                    return this.getDefaultDashboardData().pipe(
                        switchMap((defaultData: DashboardItem[]) =>
                            from(this.saveDashboardLayout(defaultData)).pipe(
                                switchMap(() => of(defaultData))
                            )
                        )
                    );
                }
            }),
            catchError((error) => {
                console.error('Error reading from preferences', error);
                return this.getDefaultDashboardData();
            })
        );
    }

    /**
     * Organizes and sorts dashboard items into a structured layout for rendering.
     */
    private organizeDashboardLayout(): void {
        this.rawDashboard.forEach((item: DashboardItem): void => {
            const { column } = item.position;
            if (!this.mappedDashboard[column]) {
                this.mappedDashboard[column] = [];
            }
            this.mappedDashboard[column].push(item);
        });

        // Sort items in each column by row
        Object.keys(this.mappedDashboard).forEach((key: string): void => {
            this.mappedDashboard[key].sort(
                (a: DashboardItem, b: DashboardItem) =>
                    a.position.row - b.position.row
            );
        });
    }

    /**
     * Saves the current dashboard layout to local storage.
     * @param data The dashboard layout data to save. If not provided, the current rawDashboard is used.
     * @returns A promise that resolves when the layout has been saved.
     */
    // TODO: Deine Bibliothek speichert ihren Zustand automatisch? Ich sehe hier viele Nachteile: Du benötigst dann eine bestimmte Abhängigkeit, die vom Verbraucher geerbt wird. Deine Bibliothek verwendet ihren eigenen Schlüssel in den Einstellungen, was zu Konflikten mit dem Verbraucher führen kann. Im Allgemeinen sollte deine Bibliothek nur ein Dienstleister für den Verbraucher sein, und dann sollte der Verbraucher entscheiden, wo und wie der Zustand gespeichert werden soll (vielleicht in der API, vielleicht in der Datenbank, vielleicht gar nicht).
    // TODO: Bitte entferne die Abhängigkeit von Capacitor Preferences und mache deine Bibliothek nicht so eigenständig. Nicht nur an dieser Stelle, sondern allgemein.
    private async saveDashboardLayout(
        data: DashboardItem[] = this.rawDashboard
    ): Promise<void> {
        try {
            await Preferences.set({
                key: LS_DASHBOARD,
                value: JSON.stringify(data),
            });
        } catch (error) {
            console.error('Error saving dashboard layout', error);
        }
    }

    /**
     * Provides a set of default dashboard items for initial display or fallback.
     * @returns An Observable emitting an array of default DashboardItem configurations.
     */
    private getDefaultDashboardData(): Observable<DashboardItem[]> {
        return of([
            {
                component: 'LuicDeviceInformationComponent',
                position: {
                    row: 1,
                    column: 0,
                },
            },
            {
                component: 'LuicMetaInformationComponent',
                position: {
                    row: 1,
                    column: 1,
                },
            },
            {
                component: 'LuicReleaseStateComponent',
                position: {
                    row: 0,
                    column: 2,
                },
            },
            {
                component: 'LuicServerStateComponent',
                position: {
                    row: 0,
                    column: 0,
                },
            },
            {
                component: 'LuicTokenInformationComponent',
                position: {
                    row: 1,
                    column: 2,
                },
            },
        ]);
    }

    /**
     * Updates the position metadata for all items in a column after a drag-and-drop operation.
     * @param event The drag-and-drop event.
     * @param targetColumnIndex The target column index where the item was dropped.
     */
    private updateItemPositions(
        event: CdkDragDrop<DashboardItem[]>,
        targetColumnIndex: number
    ): void {
        const updatePositions = (
            items: DashboardItem[],
            columnIndex: number
        ): void => {
            items.forEach((item: DashboardItem, index: number): void => {
                item.position.row = index;
                item.position.column = columnIndex;
            });
        };

        updatePositions(event.container.data, targetColumnIndex);

        if (event.previousContainer !== event.container) {
            const previousColumnIndex = this.connectedColumns.indexOf(
                event.previousContainer.id
            );
            updatePositions(event.previousContainer.data, previousColumnIndex);
        }
    }
}
