import {createAction, props} from '@ngrx/store';
import {Area, SubArea} from "../../components/patient-info/patient-files/patient-files.component";

export const loadAreas = createAction('[Areas] Load Areas');
export const loadAreasSuccess = createAction('[Areas] Load Areas Success', props<{ areas: Area[] }>());
export const loadAreasFailure = createAction('[Areas] Load Areas Failure', props<{ error: any }>());

export const addArea = createAction('[Areas] Add Area', props<{ area: Area }>());
export const addAreaSuccess = createAction('[Areas] Add Area Success', props<{ area: Area }>());
export const addAreaFailure = createAction('[Areas] Add Area Failure', props<{ error: any }>());

export const updateArea = createAction('[Areas] Update Area', props<{ area: Area }>());
export const updateAreaSuccess = createAction('[Areas] Update Area Success', props<{ area: Area }>());
export const updateAreaFailure = createAction('[Areas] Update Area Failure', props<{ error: any }>());

export const removeArea = createAction('[Areas] Remove Area', props<{ areaId: string }>());
export const removeAreaSuccess = createAction('[Areas] Remove Area Success', props<{ areaId: string }>());
export const removeAreaFailure = createAction('[Areas] Remove Area Failure', props<{ error: any }>());

export const loadSubAreas = createAction('[SubAreas] Load SubAreas');
export const loadSubAreasSuccess = createAction('[SubAreas] Load SubAreas Success', props<{ subAreas: SubArea[] }>());
export const loadSubAreasFailure = createAction('[SubAreas] Load SubAreas Failure', props<{ error: any }>());

export const addSubArea = createAction('[SubAreas] Add SubArea', props<{ subArea: SubArea }>());
export const addSubAreaSuccess = createAction('[SubAreas] Add SubArea Success', props<{ subArea: SubArea }>());
export const addSubAreaFailure = createAction('[SubAreas] Add SubArea Failure', props<{ error: any }>());

export const updateSubArea = createAction('[SubAreas] Update SubArea', props<{ subArea: SubArea }>());
export const updateSubAreaSuccess = createAction('[SubAreas] Update SubArea Success', props<{ subArea: SubArea }>());
export const updateSubAreaFailure = createAction('[SubAreas] Update SubArea Failure', props<{ error: any }>());

export const removeSubArea = createAction('[SubAreas] Remove SubArea', props<{ subAreaId: string }>());
export const removeSubAreaSuccess = createAction('[SubAreas] Remove SubArea Success', props<{ subAreaId: string }>());
export const removeSubAreaFailure = createAction('[SubAreas] Remove SubArea Failure', props<{ error: any }>());
