import {Component} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-error',
    standalone: true,
    imports: [MatIconModule, TranslateModule],
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss',
})
export class ErrorComponent {
    public imgSrc: string = 'assets/img/lost_nurse_w.png';

    constructor() {
        this.imgSrc =
            Math.random() > 0.5
                ? 'assets/img/lost_nurse_w.png'
                : 'assets/img/lost_nurse_m.png';
    }
}
