import {createReducer, on} from '@ngrx/store';
import * as AreaActions from '../actions/areas.action';
import {Area, SubArea} from "../../components/patient-info/patient-files/patient-files.component";

export interface AreaState {
    areas: Area[];
    subAreas: SubArea[];
    error: any;
    loading: boolean;
}

export const initialState: AreaState = {
    areas: [],
    subAreas: [],
    error: null,
    loading: false,
};

export const areaReducer = createReducer(
    initialState,
    on(AreaActions.loadAreas, state => ({
        ...state,
        loading: true,
        error: null
    })),
    on(AreaActions.loadAreasSuccess, (state, {areas}) => ({
        ...state,
        areas,
        loading: false
    })),
    on(AreaActions.loadAreasFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.addAreaSuccess, (state, {area}) => ({
        ...state,
        areas: [...state.areas, area],
        loading: false
    })),
    on(AreaActions.addAreaFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.updateAreaSuccess, (state, {area}) => ({
        ...state,
        areas: state.areas.map(a => a.id === area.id ? area : a),
        loading: false
    })),
    on(AreaActions.updateAreaFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.removeAreaSuccess, (state, {areaId}) => ({
        ...state,
        areas: state.areas.filter(area => area.id !== areaId),
        loading: false
    })),
    on(AreaActions.removeAreaFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.loadSubAreas, state => ({
        ...state,
        loading: true,
        error: null
    })),
    on(AreaActions.loadSubAreasSuccess, (state, {subAreas}) => ({
        ...state,
        subAreas,
        loading: false
    })),
    on(AreaActions.loadSubAreasFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.addSubAreaSuccess, (state, {subArea}) => ({
        ...state,
        subAreas: [...state.subAreas, subArea],
        loading: false
    })),
    on(AreaActions.addSubAreaFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.updateSubAreaSuccess, (state, {subArea}) => ({
        ...state,
        subAreas: state.subAreas.map(sa => sa.id === subArea.id ? subArea : sa),
        loading: false
    })),
    on(AreaActions.updateSubAreaFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    })),
    on(AreaActions.removeSubAreaSuccess, (state, {subAreaId}) => ({
        ...state,
        subAreas: state.subAreas.filter(subArea => subArea.id !== subAreaId),
        loading: false
    })),
    on(AreaActions.removeSubAreaFailure, (state, {error}) => ({
        ...state,
        error,
        loading: false
    }))
);
