<mat-card class="dialog-patient-container">
    <mat-dialog-content>
        <h4 mat-dialog-title>{{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.add" | translate }}</h4>
        <p>{{ "COMPONENT.DIALOG_PATIENT_OVERVIEW.add_entries_to_the_patient" | translate }}</p>
        <div class="dialog-patient-firstline">
            <luic-datetimepicker-input
                [lbLabel]="'COMPONENT.DIALOG_PATIENT_OVERVIEW.date_and_time' | translate"
                [lbPlaceholder]="'COMPONENT.DIALOG_PATIENT_OVERVIEW.date_and_time' | translate"
                matInput
            ></luic-datetimepicker-input>
            <luic-input-field
                [lbLabel]="'COMPONENT.DIALOG_PATIENT_OVERVIEW.catalog_add' | translate"
                [lbPlaceholder]="'COMPONENT.DIALOG_PATIENT_OVERVIEW.catalog_add' | translate"
                matInput
            ></luic-input-field>
        </div>

        <luic-input-field
            [lbLabel]="'COMPONENT.DIALOG_PATIENT_OVERVIEW.what_do_you_want_add' | translate"
            [lbPlaceholder]="'COMPONENT.DIALOG_PATIENT_OVERVIEW.what_do_you_want_add' | translate"
            matInput
            class="dialog-patient-secondline"
            [(ngModel)]="dateAndTime"
        ></luic-input-field>
    </mat-dialog-content>

    <mat-dialog-actions class="dialog-patient-actions">
        <button
            class="dialog-patient-actions-button"
            mat-raised-button
            (click)="onNoClick()"
        >
            {{ "GENERAL.button_text_save" | translate }}
        </button>
        <button
            class="dialog-patient-actions-button"
            mat-raised-button
            (click)="onConfirmCilck()"
        >
            {{ "GENERAL.button_text_cancel" | translate }}
        </button>
    </mat-dialog-actions>
</mat-card>
