import { createReducer, on } from '@ngrx/store';
import { AuthorizationToken } from '../../models/auth.model';
import * as AuthzActions from '../actions/authz.action';

/**
 * Reducer for handling changes to the authorization tokens state.
 */

/** Initial state of the authz feature. */
export interface AuthzState {
  tokens: AuthorizationToken[];
  loading: boolean;
  error: any;
}

export const initialState: AuthzState = {
  tokens: [],
  loading: false,
  error: null,
};

/** Reducer function for the authz feature. */
export const authzReducer = createReducer(
  initialState,
  on(AuthzActions.loadAuthzTokens, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AuthzActions.loadAuthzTokensSuccess, (state, { tokens }) => ({
    ...state,
    tokens,
    loading: false,
  })),
  on(AuthzActions.loadAuthzTokensFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(AuthzActions.addOrUpdateAuthzToken, (state, { token }) => {
    const t = state.tokens.filter((e) => e.locator !== token.locator);
    t.push(token);

    return {
      ...state,
      tokens: t,
      loading: true,
    };
  }),
  on(AuthzActions.removeAuthzToken, (state, { token }) => {
    return {
      ...state,
      tokens: state.tokens.filter((e) => e.locator !== token.locator),
      loading: true,
    };
  })
);
