import { CommonModule } from '@angular/common';
import { NetworkService } from './../../services/mercure/network.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription, switchMap, timer } from 'rxjs';

@Component({
    selector: 'app-network-quality',
    templateUrl: './network-quality.component.html',
    styleUrls: ['./network-quality.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class NetworkQualityComponent implements OnInit, OnDestroy {
    public online = true;


    public get pingPercent(): number {
        if (this.pingTime < 70) return 100;
        return 100 * (70 / this.pingTime)
    }
    public get speedPercent(): number {
        if (this.netSpeed > 1000) return 100;
        return 100 * (this.netSpeed / 1000);
    }

    private pingTime = -1;
    private netSpeed = -1;
    private allSubs: Subscription[] = [];

    public connectionQuality: number = 0;

    public constructor(private networkService: NetworkService) {}

    public ngOnInit(): void {
        this.allSubs.push(
            this.networkService.networkOnline$
                .pipe(
                    switchMap((o: boolean) => {
                        if (o) {
                            return of(o);
                        } else {
                            return timer(10000).pipe(switchMap(() => of(o)));
                        }
                    })
                )
                .subscribe((o: boolean) => (this.online = o))
        );

        this.allSubs.push(
            this.networkService.pingToApi$.subscribe(
                (p) => (this.pingTime = p.milliseconds)
            )
        );
        this.allSubs.push(
            this.networkService.pingPayloadToApi$.subscribe(
                (p) => (this.netSpeed = p.connectionSpeedKbS)
            )
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public getColorForPercent(percent: number): string {
        if (percent > 80) return 'green';
        if (percent > 60) return 'yellow'
        if (percent > 40) return 'orange'
        return 'red';
    }
}
