<div class="w-full p-2 bg-white flex-none shadow">
    <div class="flex justify-end gap-2">
        <button (click)="togglePaintingTool()"
                [ngClass]="{'!text-[var(--primary-color)]': isPaintingToolOpened.isPaintingToolDialogOpened}"
                mat-icon-button
                matTooltip="Dokument bearbeiten">
            <mat-icon *ngIf="!isPaintingToolOpened.isPaintingToolDialogOpened">draw</mat-icon>
            <mat-icon *ngIf="isPaintingToolOpened.isPaintingToolDialogOpened">gesture</mat-icon>
        </button>
        <button (click)="toggleFullscreen()"
                [ngClass]="{'!text-[var(--primary-color)]': isFullscreenForImageEnabled.fullScreen}"
                mat-icon-button
                matTooltip="Vollbild anzeigen">
            <mat-icon *ngIf="!isFullscreenForImageEnabled.fullScreen">fullscreen</mat-icon>
            <mat-icon *ngIf="isFullscreenForImageEnabled.fullScreen">close_fullscreen</mat-icon>
        </button>
        <button (click)="toggleMetaDataView()" [ngClass]="{'!text-[var(--primary-color)]': isMetadataViewOpened}"
                mat-icon-button
                matTooltip="Metadaten anzeigen">
            <mat-icon *ngIf="!isMetadataViewOpened">arrow_back</mat-icon>
            <mat-icon *ngIf="isMetadataViewOpened">arrow_forward</mat-icon>
        </button>
        <!--<button>
            <luic-painting-tool
                *ngIf="(getImageFormat(selectedPatientRecord?.documentPath) | async) as imageFormat"
                [imageFormat]="imageFormat"></luic-painting-tool>
        </button>-->
    </div>
</div>
