import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {from, of} from "rxjs";
import {catchError, map, switchMap, withLatestFrom} from "rxjs/operators";
import * as MercureActions from "../actions/mercure.action";
import {DataRepositoryService} from "../../services/datarepository.service";
import {AppState} from "../appstate.model";
import {Store} from "@ngrx/store";

/**
 * Effects for handling authorization tokens related operations.
 */
@Injectable()
export class MercureEffects {
    /** Effect for loading settings. */
    public loadMercure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MercureActions.loadMercureState),
            switchMap(() => {
                return from(this.repo.getMercure()).pipe(
                    map((serverPingStatus) => {
                        return MercureActions.setServerPingStatusSuccess({serverPingStatus});
                    })
                );
            }),
            catchError((error) => {
                return of(MercureActions.setServerPingStatusFail({error}));
            })
        )
    );
    /** Effect for adding or updating settings. */
    saveMercure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MercureActions.setServerPingStatus),
            withLatestFrom(this.store.select((state) => state.mercure.mercure)),
            switchMap(([action, mercure]) => {
                this.repo.saveMercure(mercure).then();
                return of(
                    MercureActions.setServerPingStatusSuccess({serverPingStatus: action.serverPingStatus})
                );
            }),
            catchError((error) => {
                return of(MercureActions.setServerPingStatusFail({error}));
            })
        )
    );

    public constructor(
        private actions$: Actions,
        private repo: DataRepositoryService,
        private store: Store<AppState>
    ) {
    }
}
