import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AreaState} from '../reducers/areas.reducer';

export const selectAreaFeature = createFeatureSelector<AreaState>('areas');

export const selectAllAreas = createSelector(
    selectAreaFeature,
    (state: AreaState) => state.areas
);

export const selectAllSubAreas = createSelector(
    selectAreaFeature,
    (state: AreaState) => state.subAreas
);

export const selectAreaError = createSelector(
    selectAreaFeature,
    (state: AreaState) => state.error
);
